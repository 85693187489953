import React from "react";
// import PropTypes from 'prop-types';
import { Row, Col, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { getHabitsDays } from "../habitsUtils";
import {
  getMinutesFromSeconds,
  getNumberOfHoursMinutesString,
} from "app/appUtils";

const { Text } = Typography;

const getBlockColor = (signalValue = "") => {
  if (!signalValue) {
    return "";
  }

  if (signalValue === "GOOD") {
    return "#03c971";
  }

  if (signalValue === "GREAT") {
    return "#00A75D";
  }

  if (signalValue === "POOR") {
    return "#e3e3e3";
  }
};

const AtomicHabitRow = ({ data = {} }) => {
  const days = getHabitsDays(data?.daily_summary);

  return (
    <Row align="middle">
      <Col span={8} className="flex-display align-items-center">
        <Text type="secondary" style={{ fontSize: "10px" }}>
          {data?.info?.label}
        </Text>
        <Tooltip title={data?.info?.desc} className="font-size-12">
          <InfoCircleOutlined className="ml-4" />
        </Tooltip>
      </Col>

      {days?.map((day, idx) => {
        const isOffDay = day?.day_type === "DO" || day?.day_type === "NWD";

        return (
          <Col
            span={2}
            style={{
              backgroundColor: isOffDay ? "#e3e3e3" : undefined,
              height: isOffDay ? "20px" : undefined,
              display: isOffDay ? "flex" : undefined,
              alignItems: isOffDay ? "center" : undefined,
              justifyContent: isOffDay ? "center" : undefined,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={day?.signal_label}>
                {day?.day_type === "ND" ? (
                  <span
                    style={{
                      display: "block",
                      height: "10px",
                      width: "10px",
                      backgroundColor: null,
                      border: "1px solid #e3e3e3",
                    }}
                  />
                ) : (
                  <>
                    {day?.signal_type === "BLOCK" ? (
                      <span
                        style={{
                          display: "block",
                          height: "10px",
                          width: "10px",
                          backgroundColor: getBlockColor(day?.signal_value),
                          border: "1px solid #e3e3e3",
                        }}
                      />
                    ) : (
                      <Text style={{ fontSize: "10px" }}>
                        {getNumberOfHoursMinutesString(
                          getMinutesFromSeconds(day?.signal_value) || 0
                        )}
                      </Text>
                    )}
                  </>
                )}
              </Tooltip>
            </div>
          </Col>
        );
      })}
      <Col span={2}>
        <div className="flex-display justify-content-center">
          <Tooltip title={data?.period_summary?.signal_value}>
            <span
              style={{
                display: "block",
                height: "12px",
                width: "14px",
                backgroundColor: getBlockColor(
                  data?.period_summary?.signal_value
                ),
              }}
            />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

AtomicHabitRow.propTypes = {};

export default AtomicHabitRow;
