import axios from "axios";
//import moment from "moment";
// import momentTimeZone from "moment-timezone";
//import { dateTimeFormat } from "app/appConstants";

const getAlertsApi = async () => {
  const { data } = await axios.get("/api/v1/alerts/");
  return data;
};

const getDayPlanApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v1/daily_plan/${queryKey[1]}`);
  return data;
};

const getOtherCalendarEventsApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v1/calendar/${queryKey[1]}`);
  return data;
};

const updateCalendarEventApi = async ({ calendarEvent = {} }) => {
  const { data } = await axios.post(`/api/v2/calendar_events`, {
    calendar_event: calendarEvent,
  });

  return data;
};

const saveTimeSlotApi = async ({ time_slot }) => {
  const { data } = await axios.post(`/api/v1/time_slot`, {
    time_slot,
  });

  return data;
};

const deleteTimeSlotApi = async ({ dayPlanId, timeSlotId }) => {
  const { data } = await axios.delete(
    `/api/v1/daily_plan/${dayPlanId}/time_slot/${timeSlotId}`
  );

  return data;
};

const getTrackingDataApi = async ({ queryKey: [_, dayPlanId, timeSlotId] }) => {
  const { data } = await axios.get(
    `api/v1/raw_data_points?daily_plan_id=${dayPlanId}&time_slot_id=${timeSlotId}`
  );
  return data;
};

const getTimeSlotSummaryApi = async ({
  queryKey: [_, dayPlanId, timeSlotId],
}) => {
  const { data } = await axios.get(
    `/api/v1/daily_plan/${dayPlanId}/time_slot/${timeSlotId}/summary`
  );
  return data;
};

export {
  getAlertsApi,
  getDayPlanApi,
  saveTimeSlotApi,
  deleteTimeSlotApi,
  getOtherCalendarEventsApi,
  getTrackingDataApi,
  getTimeSlotSummaryApi,
  updateCalendarEventApi,
};
