import axios from "axios";

const getTodosApi = async () => {
  const { data } = await axios.get(`/api/v1/todos`);
  return data;
};

const saveTodoApi = async ({ todo, time_spent_secs }) => {
  const { data } = await axios.post(`/api/v1/todos`, {
    task: todo,
    time_spent_secs,
  });

  return data;
};

const clearDoneTodosApi = async () => {
  const { data } = await axios.put(`/api/v1/todos/clear_completed`);
  return data;
};

const deleteTodoApi = async ({ todoId }) => {
  const { data } = await axios.delete(`/api/v1/todos/${todoId}`);

  return data;
};

const deleteFutureTodosApi = async ({ todoId }) => {
  const { data } = await axios.delete(`/api/v1/todos/future_also/${todoId}`);

  return data;
};

const reorderTodoApi = async ({ todoOrderMap }) => {
  const { data } = await axios.post(`/api/v1/todos/reorder`, {
    reorder: {
      ...todoOrderMap,
    },
  });

  return data;
};

const upsertDayTrackApi = async ({ todoId, date, seconds }) => {
  const { data } = await axios.post(`/api/v1/todos/upsert_day_track`, {
    todo_id: todoId,
    date,
    seconds,
  });

  return data;
};

const bulkMoveTodosDueDateApi = async ({ todoIds, dueDate }) => {
  const { data } = await axios.post(`/api/v1/todos/move_due_date`, {
    todo_ids: todoIds,
    due_date: dueDate,
  });

  return data;
};

const getDayViewApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v2/day_view/${queryKey[1]}`);
  return data;
};

const saveDayPlanApi = async ({ date, planned }) => {
  const { data } = await axios.post(`/api/v2/day_view/daily_plan`, {
    daily_plan: { date, planned },
  });

  return data;
};

export {
  getTodosApi,
  saveTodoApi,
  deleteTodoApi,
  deleteFutureTodosApi,
  clearDoneTodosApi,
  reorderTodoApi,
  upsertDayTrackApi,
  bulkMoveTodosDueDateApi,
  getDayViewApi,
  saveDayPlanApi,
};
