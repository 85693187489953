import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { Row, Typography, Empty } from "antd";
import { CloseOutlined, CalendarOutlined } from "@ant-design/icons";

import { BoxLoader } from "common/components";
import { useGetTodos } from "common/hooks";
import { useIsUserRegisteredState, useIsAppExpandedState } from "store";
import ReviewStaleAssists from "./components/ReviewStaleAssists";
import RescheduleTodoAssist from "./components/RescheduleTodoAssist";
import SetPriorityAssist from "./components/SetPriorityAssist";
import {
  getIsElectron,
  errorNotification,
  getMainContainerStyling,
} from "app/appUtils";
import { getAssistsApi } from "app/assists/assistsApi";
import { getTodoCategoriesApi } from "app/settings/categories/todoCategoriesApis";
import assistsIcon from "assets/assists_icon.png";
import { ReactComponent as PriorityIcon } from "assets/priority_icon.svg";

import styles from "./Assists.module.css";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const { Text, Title } = Typography;

const getFirstAssistCategory = (
  rescheduleAssists = [],
  priorityAssists = [],
  staleTodoAssists = []
) => {
  if (rescheduleAssists?.length > 0) {
    return "reschedule";
  }

  if (priorityAssists?.length > 0) {
    return "priority";
  }

  if (staleTodoAssists?.length > 0) {
    return "staleTodos";
  }

  return "";
};

const Assists = (props) => {
  const history = useHistory();

  const [allTodos, setAllTodos] = useState([]);
  const [isUserRegistered] = useIsUserRegisteredState();
  const [isAppExpanded] = useIsAppExpandedState();

  const {
    data: assists = [],
    isLoading: isAssistsLoading,
    refetch,
  } = useQuery("getAssists", getAssistsApi);

  const { refetch: refetchTodos } = useGetTodos({
    onSuccess: (data) => {
      const completeTodoSet = [];
      data.forEach((category) => {
        if (category && category.active_todos) {
          category.active_todos.forEach((todo) => {
            completeTodoSet.push(todo);
          });
        }
      });

      setAllTodos(completeTodoSet);
    },
  });

  const { data: categories, isLoading: isLoadingCategories } = useQuery(
    "getTodoCategories",
    getTodoCategoriesApi,
    {
      enabled: isUserRegistered,
      onSuccess: () => {},
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const isElectron = getIsElectron();

  const isWebPage = !isChromeExtension && !isElectron;

  const handleNavigateHome = () => {
    history.push("/");
  };

  const unclearedAssists =
    assists?.filter((assist) => !Boolean(assist?.cleared)) || [];

  const staleTodoAssists =
    unclearedAssists?.filter(
      (assist) => assist?.type === "ReviewStaleAssist"
    ) || [];

  const rescheduleAssists =
    unclearedAssists?.filter(
      (assist) => assist?.type === "RescheduleTodosAssist"
    ) || [];

  const priorityAssists =
    unclearedAssists?.filter(
      (assist) => assist?.type === "SetPriorityAssist"
    ) || [];

  const areAssistsEmpty = unclearedAssists?.length === 0;

  const firstTodoCategory = getFirstAssistCategory(
    rescheduleAssists,
    priorityAssists,
    staleTodoAssists
  );

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  return (
    <div
      id="main-menu-content"
      className={`flex-display ${isWebPage && "web-main-content-styles"} `}
    >
      <div className="ml-8 mr-8 mb-8 pl-16 pr-16" style={mainContainerStyles}>
        <Row className="mt-8" align="middle" justify="space-between">
          <Row align="middle">
            <img
              className={styles.assistsIcon}
              src={assistsIcon}
              alt="assists"
            />
            <Title className="ml-4" level={4}>
              Assists
            </Title>
          </Row>
          <CloseOutlined
            className="cursor-pointer"
            onClick={handleNavigateHome}
          />
        </Row>
        <Text>
          THY observes your Todos and identifies quick actions you can take
          improve your overall productivity.
        </Text>
        {isAssistsLoading || isLoadingCategories ? (
          <BoxLoader />
        ) : (
          <div>
            {areAssistsEmpty ? (
              <Empty description="No Assists" />
            ) : (
              <div>
                {rescheduleAssists?.length > 0 && (
                  <RescheduleTodoAssist
                    categories={categories}
                    firstTodoCategory={firstTodoCategory}
                    allTodos={allTodos}
                    data={rescheduleAssists[0] || {}}
                    icon={
                      <CalendarOutlined className="text-primary font-size-22" />
                    }
                    refetch={refetch}
                    refetchTodos={refetchTodos}
                  />
                )}

                {priorityAssists?.length > 0 && (
                  <SetPriorityAssist
                    allTodos={allTodos}
                    firstTodoCategory={firstTodoCategory}
                    data={priorityAssists[0] || {}}
                    icon={<PriorityIcon />}
                    refetch={refetch}
                  />
                )}

                {staleTodoAssists?.length > 0 && (
                  <ReviewStaleAssists
                    firstTodoCategory={firstTodoCategory}
                    data={staleTodoAssists}
                    allTodos={allTodos}
                    refetchTodos={refetchTodos}
                    refetch={refetch}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Assists.propTypes = {};

export default Assists;
