import React from "react";
import { useCurrentUser, useIsUserSigningOut, useIsFetchingUser } from "store";
import { Redirect, useLocation } from "react-router-dom";

import { PageSpinner } from "common/components";

const AuthGuard = ({ children }) => {
  const [isFetchingUser] = useIsFetchingUser();
  const [currentUser] = useCurrentUser();
  const [isUserSigningOut] = useIsUserSigningOut();
  const location = useLocation();

  if (isUserSigningOut) {
    return <PageSpinner />;
  }

  if (currentUser || isFetchingUser) {
    return children;
  }

  return (
    <Redirect to={`/auth?from_page=${location?.pathname}${location?.search}`} />
  );
};

export default AuthGuard;
