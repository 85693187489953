import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as TodoCheckedIcon } from "assets/todoCheckedIcon.svg";
import { ReactComponent as TodoUncheckedIcon } from "assets/todoUncheckedIcon.svg";
import styles from "./TodoTick.module.css";

const TodoTick = ({ className = "", checked = false }) => {
  return (
    <button
      type="button"
      role="checkbox"
      aria-checked="false"
      aria-label="Mark todo as complete"
      className={`${styles.todo_checkbox}`}
    >
      {checked ? (
        <div
          className={`${className} ${styles.todo_checkbox__circle} ${styles.checked_div_bg}`}
        >
          <TodoCheckedIcon className={styles.checked_opacity} />
        </div>
      ) : (
        <div
          className={`${className} ${styles.todo_checkbox__circle} ${styles.unchecked_div_bg}`}
        >
          <TodoUncheckedIcon className={styles.not_checked_opacity} />
        </div>
      )}
    </button>
  );
};
TodoTick.propTypes = {
  checked: PropTypes.bool,
};

export default TodoTick;
