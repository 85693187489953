import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";

import TodoTick from "../todo-tick/TodoTick";
import { getDoneTodoMsg } from "app/appUtils";

const TodoStatusRadio = ({
  disabled = false,
  todo,
  saveTodo = () => {},
  setDoneTodoMsg = () => {},
  setIsJustCrossed = () => {},
}) => {
  const [isTodoChecked, setIsTodoChecked] = useState(todo.completed_at);

  useEffect(() => {
    setIsTodoChecked(todo.completed_at);
  }, [todo.completed_at]);

  const handleCheckTodo = () => {
    if (disabled) {
      return;
    }

    setIsTodoChecked(!isTodoChecked);
    if (Boolean(todo?.completed_at)) {
      saveTodo({
        todo: { ...todo, completed_at: null },
        extraParams: { shouldRefetchDailyStats: true },
      });
    } else {
      setIsJustCrossed(true);
      let updatedTodo = { ...todo, completed_at: new Date() };
      delete updatedTodo["time_spent_seconds"];
      setTimeout(() => {
        saveTodo({
          todo: updatedTodo,
          extraParams: { shouldRefetchDailyStats: true },
        });
        setIsJustCrossed(false);
      }, 500);
      setDoneTodoMsg(getDoneTodoMsg());
      setTimeout(() => {
        setDoneTodoMsg("");
      }, 7000);
    }
  };

  return (
    <div>
      {isTodoChecked ? (
        <div onClick={handleCheckTodo}>
          <TodoTick
            className={disabled && "cursor-not-allowed"}
            checked={true}
          />
        </div>
      ) : (
        <div onClick={handleCheckTodo}>
          <TodoTick
            className={disabled && "cursor-not-allowed"}
            checked={false}
          />
        </div>
      )}
    </div>
  );
};

TodoStatusRadio.propTypes = {};

export default TodoStatusRadio;
