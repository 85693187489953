import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { Typography, Row } from "antd";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";

import { useAppDate } from "store";
import { dateFormat, shortDateFormatWithWeekDay } from "app/appConstants";

const { Text } = Typography;

const DueDateTabs = ({
  dueDateFilter = "today",
  tomorrowTabDate = "",
  isAfter4PM = false,
  currentDate = "",
  todosCount = {},
  setMeetingsDate = () => {},
  setDueDateFilter = () => {},
  setTomorrowTabDate = () => {},
}) => {
  const [appDate] = useAppDate();

  useEffect(() => {
    setMeetingsDate(dueDateFilter === "today" ? currentDate : tomorrowTabDate);
  }, [currentDate, tomorrowTabDate, dueDateFilter, setMeetingsDate]);

  const handleDueDateFilter = (filter = "") => {
    setDueDateFilter(filter);
    if (filter === "today") {
      setMeetingsDate(moment()?.format(dateFormat));
    } else if (filter === "tomorrow") {
      setMeetingsDate(moment()?.add(1, "day")?.format(dateFormat));
    } else {
      setMeetingsDate(null);
    }
  };

  const handleDecreaseDate = () => {
    setTomorrowTabDate((date) =>
      moment(date, dateFormat)?.subtract(1, "day")?.format(dateFormat)
    );
    handleDueDateFilter("tomorrow");
  };

  const handleIncreaseDate = () => {
    setTomorrowTabDate((date) =>
      moment(date, dateFormat)?.add(1, "day")?.format(dateFormat)
    );
    handleDueDateFilter("tomorrow");
  };

  const isDateTomorrow = moment(tomorrowTabDate, dateFormat)?.isSame(
    moment(appDate, dateFormat)?.add(1, "day")
  );

  const todayTodosCount = todosCount?.todayTodosCount;
  const allTodosCount = todosCount?.allTodosCount;
  const tomorrowTodosCount = todosCount?.tomorrowTodosCount;

  return (
    <div className="mt-8 mb-8">
      <Row>
        <Text
          className={`cursor-pointer font-size-13 ${
            dueDateFilter === "today" ? "text-decoration-underline" : ""
          }`}
          strong={dueDateFilter === "today"}
          onClick={() => {
            handleDueDateFilter("today");
          }}
        >{`Today${todayTodosCount > 0 ? ` (${todayTodosCount})` : ""}`}</Text>
        <span
          className="ml-4"
          style={{
            height: "12px",
            width: "1px",
            marginTop: "3px",
            backgroundColor: "black",
          }}
        ></span>
        <div className="inline-flex-display align-items-center">
          {!isDateTomorrow && <CaretLeftFilled onClick={handleDecreaseDate} />}
          <Text
            className={` ${
              isDateTomorrow && "ml-4"
            } cursor-pointer font-size-13 ${
              dueDateFilter === "tomorrow" ? "text-decoration-underline" : ""
            } `}
            strong={dueDateFilter === "tomorrow"}
            onClick={() => {
              handleDueDateFilter("tomorrow");
            }}
          >
            <Text>
              {moment(tomorrowTabDate, dateFormat)?.isSame(
                moment(appDate, dateFormat)?.add(1, "day")
              )
                ? "Tomorrow"
                : moment(tomorrowTabDate, dateFormat)?.format(
                    shortDateFormatWithWeekDay
                  )}
            </Text>
            <Text>
              {isAfter4PM
                ? ` (${tomorrowTodosCount}${isAfter4PM ? " Planned" : ""})`
                : tomorrowTodosCount > 0
                ? ` (${tomorrowTodosCount})`
                : ""}
            </Text>
          </Text>
          <CaretRightFilled onClick={handleIncreaseDate} />
        </div>

        {allTodosCount > 0 && (
          <span
            className="ml-4"
            style={{
              height: "12px",
              width: "1px",
              marginTop: "3px",
              backgroundColor: "black",
            }}
          ></span>
        )}
        {allTodosCount > 0 && (
          <Text
            className={`ml-8 cursor-pointer font-size-13 ${
              dueDateFilter === "upcoming" ? "text-decoration-underline" : ""
            }`}
            strong={dueDateFilter === "upcoming"}
            onClick={() => {
              handleDueDateFilter("upcoming");
            }}
          >{`All Todos${allTodosCount > 0 ? ` (${allTodosCount})` : ""}`}</Text>
        )}
      </Row>
    </div>
  );
};

DueDateTabs.propTypes = {};

export default DueDateTabs;
