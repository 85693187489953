import React from "react";
import { Collapse, Row, Typography } from "antd";

import AtomicHabitHeader from "./AtomicHabitHeader";
import AtomicHabitRow from "./AtomicHabitRow";
import Suggestions from "./Suggestions";
import { colorCodes, customPanelStyle } from "../habitsConstants";
import { getAtomicHabit, getSuggestions } from "../habitsUtils";

const { Panel } = Collapse;
const { Text } = Typography;

const OrganisationHabit = ({ key = "", data = {}, ...props }) => {
  const suggestions = getSuggestions(data?.atomic_habits);

  return (
    <Panel
      showArrow={false}
      key={key}
      header={
        <Row justify="space-between">
          <Text strong className="font-size-16">
            Staying Organised
          </Text>
          <div>
            <Text style={{ color: colorCodes[data?.grade] }}>
              {data?.grade === "NO DATA" ? "COLLECTING DATA" : data?.grade}
            </Text>
            {data?.grade === "GREAT" && (
              <span className="ml-4" role="img" aria-label="thumbs-up">
                👍
              </span>
            )}
          </div>
        </Row>
      }
      style={customPanelStyle}
      {...props}
    >
      <div>
        <AtomicHabitHeader data={data?.atomic_habits[0]} />
        <AtomicHabitRow
          data={getAtomicHabit("TodosPendingAh", data?.atomic_habits)}
        />
        <AtomicHabitRow
          data={getAtomicHabit("TodosPrePlannedAh", data?.atomic_habits)}
        />
      </div>

      <Suggestions suggestions={suggestions} />
    </Panel>
  );
};

OrganisationHabit.propTypes = {};

export default OrganisationHabit;
