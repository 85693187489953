import moment from "moment";
import { dateTimeFormat, dateTimeFormatZ } from "app/appConstants";

/* Extracts Google Calendar Events */
export const getOtherCalendarEvents = (
  otherCalendarEventsData = [],
  currentDate = ""
) => {
  let otherEvents = [];
  otherEvents =
    otherCalendarEventsData?.map((otherEvent, index) => ({
      ...otherEvent,
      eventId: otherEvent?.id,
      id: `other-calendar-${index}`,
      isFromOtherCalendar: true,
      start: moment(otherEvent?.from_time_utc, dateTimeFormat)?.toDate(),
      end: moment(otherEvent?.to_time_utc, dateTimeFormat)?.toDate(),
      resourceId: 1,
    })) || [];

  return otherEvents;
};

/* Merges and sorts all the TimeSlots for a given day and canonicalizes them */
export const getAllTimeSlots = (
  currentDate = "",
  otherCalendarEventsData = []
) => {
  const tz = moment.tz.guess();

  const otherCalendarEvents =
    getOtherCalendarEvents(otherCalendarEventsData, currentDate) || [];

  const allTimeSlots =
    otherCalendarEvents?.map((calTimeSlot) => ({
      type: "Google Calendar",
      title: calTimeSlot.title,
      startTime: moment(calTimeSlot.from_time_utc, dateTimeFormatZ)
        ?.tz(tz)
        ?.format("HH:mm"),
      endTime: moment(calTimeSlot.to_time_utc, dateTimeFormatZ)
        ?.tz(tz)
        ?.format("HH:mm"),
      record: calTimeSlot,
    })) || [];

  return allTimeSlots.sort((a, b) => {
    return moment(a.startTime, "HH:mm") - moment(b.startTime, "HH:mm");
  });
};

export const getAllFutureTimeSlots = (allTimeSlots = []) => {
  const currentTime = moment().subtract(15, "minutes");
  return allTimeSlots.filter((timeSlot) =>
    currentTime?.isBefore(
      moment(timeSlot.record?.from_time_utc, dateTimeFormatZ)
    )
  );
};
