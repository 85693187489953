import moment from "moment";
import { dateFormat } from "app/appConstants";

export const saveTimeSlotOnSuccess = ({
  data,
  variables,
  queryClient,
  setSuccessMessage = () => {},
  onEndDeepWork = () => {},
}) => {
  queryClient.invalidateQueries(["getDayView", moment().format(dateFormat)]);
  queryClient.invalidateQueries("getTodos");
  queryClient.invalidateQueries("getDayView");
  if (variables?.extraParams?.shouldShowSuccessNotification) {
    setSuccessMessage("Updated time block");
  }
  if (variables?.extraParams?.endDW) {
    console.log("inside");
    onEndDeepWork();
  }
};
