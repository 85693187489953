import React, { useState, useRef, useEffect, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import { Input, Button, Row, Select, DatePicker, Typography } from "antd";
import {
  DeleteOutlined,
  AimOutlined,
  RetweetOutlined,
} from "@ant-design/icons";

import { ButtonWithSpinner } from "common/components";
import { useAppDate, useOnboardedState } from "store";
import TodoTypeCheckbox from "app/home/todos/categories-list/todo/todo-input/todo-type-checkbox/TodoTypeCheckbox";
import TodoDueDatePanel from "app/home/todos/categories-list/todo/todo-input/todo-due-date-panel/TodoDueDatePanel";
import DeleteTodoModal from "./delete-todo-model/DeleteTodoModal";
import { useSaveTodo } from "common/hooks";
import { TodosContext } from "app/home/context";
import {
  dateFormat,
  shortDateFormatWithWeekDay,
  dateTimeFormatZ,
  dateTimeOnly,
} from "app/appConstants";
import { ReactComponent as TimerIcon } from "assets/timer.svg";
import {
  getTodoFields,
  getRecurringFields,
  getCategoryStyle,
} from "app/home/todos/todoUtils";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import { saveTodoOnSucess } from "app/home/todos/categories-list/todo/TodoSuccessHandlers";
import { createDWNowOnSuccess } from "app/home/todos/TodosSuccessHandlers";
import {
  errorNotificationWithString,
  getSelectedTodoCategory,
  errorNotification,
} from "app/appUtils";
import { getHasLearningWords, handleAddTempTodo } from "./utils";

import styles from "./TodoInput.module.css";

const { Text } = Typography;
const { Option } = Select;

const TodoInput = ({
  loader = false,
  from = "",
  className = "",
  timeSpentToday = 0,
  dueDateFilter = "",
  currentTodoCategoryId = 0,
  showDeleteIcon = false,
  isUserTyping = false,
  isGoal = false,
  goalId = null,
  categories = [],
  todo = {},
  setIsUserTyping = () => {},
  setIsEdit = () => {},
  handleIsDWSessionTrackerVisible = () => {},
  refetchTodos = () => {},
  onMenuCancel = null,
}) => {
  const titleInputRef = useRef(null);
  const isRepeatCheckedRef = useRef(Boolean(todo?.recurring));
  const queryClient = useQueryClient();

  // Global State
  const [appDate] = useAppDate();
  const history = useHistory();
  const [onboarded] = useOnboardedState();

  // Derived Values
  const isFromHome = from === "home";
  const isFromEdit = from === "edit";
  const isFromQuickAdd = from === "quick-add";
  const isFromExecution = from === "execution";
  const isFromArchive = from === "archive";
  const isFromAddGoal = from === "add-goal";

  // Context
  const { categoriesWithTodos, setCategoriesWithTodos } =
    useContext(TodosContext);

  // Component State
  const [showAddToDoForm, setShowAddToDoForm] = useState(false);
  const [isTimeSpentNaN, setIsTimeSpentNaN] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModelOpen] = useState(false);

  const [todoFields, setTodoFields] = useState(
    getTodoFields({
      from,
      dueDateFilter,
      isGoal,
      goalId,
      appDate,
      currentTodoCategoryId,
      todo,
    })
  );
  const [recurring, setRecurring] = useState(getRecurringFields(todo));

  // Effects
  useEffect(() => {
    if (
      (isFromHome || isFromQuickAdd) &&
      dueDateFilter === "today" &&
      !todoFields?.due_date
    ) {
      setTodoFields((todoFields) => ({ ...todoFields, due_date: appDate }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDate]);

  useEffect(() => {
    const sortedData = categories?.sort((a, b) => a?.order - b?.order);
    const selectedCategoryId = todoFields?.todo_category_id;
    if (!selectedCategoryId && sortedData?.length > 0) {
      setTodoFields((todoFields) => ({
        ...todoFields,
        todo_category_id: sortedData[0]?.id,
      }));
    }
  }, [categories, todoFields?.todo_category_id]);

  // to add focus to input
  useEffect(() => {
    if (titleInputRef.current && showAddToDoForm) {
      titleInputRef.current.focus();
    }
  }, [titleInputRef, showAddToDoForm, loader]);

  // Event Handlers
  const resetValues = () => {
    if (isFromEdit) {
      setIsEdit(false);
    } else {
      setTodoFields((todoFields) => ({ ...todoFields, title: "" }));
      setTodoFields((todoFields) => ({ ...todoFields, description: "" }));
    }
    if (isUserTyping) {
      setIsUserTyping(false);
    }
    setTodoFields((todoFields) => ({ ...todoFields, time_spent: null }));
    if (isFromQuickAdd) {
      onMenuCancel();
    }
  };

  const { mutate: createDWNowTimeSlot } = useMutation(saveTimeSlotApi, {
    onSuccess: (data) => {
      createDWNowOnSuccess({
        onboarded,
        data,
        queryClient,
      });
    },
    onError: (err) => {},
  });

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      saveTodoOnSucess({
        queryClient,
        data,
        variables,
        handleCreateDWNow,
      });
      if (isFromAddGoal || isFromExecution) {
        // Close todo input after adding todo
        onMenuCancel();
      }
    },
    onError: (err) => {
      refetchTodos();
      errorNotification(err);
    },
  });

  const handleCreateDWNow = (event, extraParams) => {
    if (event?.detail > 1) {
      return;
    }

    const todoTitle = extraParams?.todoTitle || "";
    const todoDesc = extraParams?.todoDesc || "";
    const todoId = extraParams?.todoId || "";

    createDWNowTimeSlot({
      time_slot: {
        title: todoTitle,
        quick_plan: todoDesc,
        from_time: moment()?.utc()?.format(dateTimeFormatZ),
        to_time: moment()?.add(30, "minutes")?.utc()?.format(dateTimeFormatZ),
        todo_id: todoId,
        todo_ids: [todoId],
        is_active: true,
      },
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: moment()?.add(30, "minutes").format(dateTimeOnly),
      });
    }
  };

  const handleTodoTitle = (e) => {
    if (!e.target.value.includes("\n")) {
      setTodoFields((todoFields) => ({ ...todoFields, title: e.target.value }));
      setIsUserTyping(true);

      const hasLearningWords = getHasLearningWords(e.target.value);
      if (hasLearningWords) {
        setTodoFields((todoFields) => ({
          ...todoFields,
          new_learnings: hasLearningWords,
        }));
      }
    }
  };

  const handleTodoDescription = (e) => {
    setTodoFields((todoFields) => ({
      ...todoFields,
      description: e?.target?.value || "",
    }));
  };

  const handleChangeSelectedCategory = (value) => {
    if (!value) {
      const unCategorized = categories?.filter(
        (category) => category?.name?.toLowerCase() === "uncategorized"
      );
      setTodoFields((todoFields) => ({
        ...todoFields,
        todo_category_id: unCategorized[0]?.id,
      }));
    } else {
      setTodoFields((todoFields) => ({
        ...todoFields,
        todo_category_id: value,
      }));
    }
  };

  const handleKeyDownTitle = (e) => {
    // KeyCode of enter is 13
    if (e.keyCode === 13) {
      handleSaveTodo();
    }
    // KeyCode of esc is 27
    else if (e.keyCode === 27) {
      handleCancelInputTodo();
      if (titleInputRef.current) {
        titleInputRef.current.focus();
      }
    }
  };

  const handleKeyDownDesc = (e) => {
    // KeyCode of esc is 27
    if (e.keyCode === 27) {
      handleCancelInputTodo();
    }
  };

  const handleCancelInputTodo = () => {
    if (isFromEdit) {
      setIsEdit(false);
      return;
    } else {
      setIsUserTyping(false);
    }
    setShowAddToDoForm(false);
    resetValues();
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  };

  const handleSaveTodo = () => {
    const recurringReq = recurring?.recurring
      ? {
          title: todoFields?.title, // Why do we need title
          new_learnings: todoFields?.new_learnings,
          id: todo?.recurring_todo_id,
          periodicity: recurring?.periodicity,
          ends_on: recurring?.ends === "never" ? null : recurring?.onMonth, // is this correct? also, month is just 2023-08 not 2023-08-15
          config: {
            weekdays: recurring?.selectedDays,
            month_days: recurring?.monthlyDays?.split(", "), //Is this correct?
          },
        }
      : null;

    if (
      todoFields?.title &&
      todoFields?.title.trim() !== "" &&
      todoFields?.todo_category_id !== 0
    ) {
      // Add a temp value in context until the apis are successfull

      handleAddTempTodo({
        todo,
        todoFields,
        categoriesWithTodos,
        setCategoriesWithTodos,
      });

      resetValues();
      if (todo?.id) {
        saveTodo({
          todo: {
            ...todo,
            ...todoFields,
            recurring: recurringReq,
          },
          time_spent_secs: todoFields?.time_spent * 60,
        });
      } else {
        isRepeatCheckedRef.current = false;
        const timeSpentSecs = !Boolean(todoFields?.time_spent)
          ? undefined
          : todoFields?.time_spent * 60;

        saveTodo({
          todo: {
            ...todoFields,
            recurring: recurringReq,
          },
          time_spent_secs: timeSpentSecs,
        });

        setTimeout(() => {
          if (titleInputRef.current) {
            titleInputRef.current.focus();
          }
        }, 1000);
        setRecurring({});
        setTodoFields((todoFields) => ({
          ...todoFields,
          new_learnings: false,
        }));
      }
    } else {
      errorNotificationWithString("Todo cant be empty");
    }
  };

  const handleEditAndCreateDWNow = () => {
    saveTodo({
      todo: {
        ...todo,
        ...todoFields,
      },
      time_spent_secs: todoFields?.time_spent * 60,
      extraParams: { resetValues, createDWNow: true },
    });
  };

  const handleTimeInput = (e) => {
    const val = e?.target?.value;
    if (isNaN(val)) {
      setIsTimeSpentNaN(true);
    } else {
      setTodoFields((todoFields) => ({ ...todoFields, time_spent: val }));
      setIsTimeSpentNaN(false);
    }
  };

  const handlePriority = (e) => {
    setTodoFields((todoFields) => ({
      ...todoFields,
      priority: e?.target?.checked,
    }));
  };

  const handleNewLearnings = (e) => {
    setTodoFields((todoFields) => ({
      ...todoFields,
      new_learnings: e?.target?.checked,
    }));
  };

  const handleDueDateChange = (date) => {
    if (isDatePickerOpen) {
      setIsDatePickerOpen(false);
    }
    if (date) {
      setTodoFields((todoFields) => ({
        ...todoFields,
        due_date: date?.format(dateFormat),
      }));
    } else {
      setTodoFields((todoFields) => ({
        ...todoFields,
        due_date: null,
      }));
    }
  };

  const handleDueDateClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDueDateBlur = () => {
    if (!Boolean(isRepeatCheckedRef?.current)) {
      setIsDatePickerOpen(false);
    }
  };

  const handleCovertToGoal = () => {
    history.push(
      `/convert-to-goal?name=${todoFields?.title}&target_date=${moment()
        ?.add(1, "month")
        ?.format(dateFormat)}`
    );
  };

  const handleDeleteModelChange = () => {
    setIsDeleteModelOpen(!isDeleteModalOpen);
  };

  // Component Logic
  const selectedCategory = getSelectedTodoCategory(
    categories,
    todoFields?.todo_category_id
  );

  const dueDateSelectStyles = {
    color: "#003399",
    marginLeft: isGoal ? "0px" : undefined,
  };

  const isUnCategorized =
    selectedCategory?.name?.toLowerCase() === "uncategorized";

  const filteredCategories = categories
    ?.filter((category) => category?.name?.toLowerCase() !== "uncategorized")
    ?.sort((a, b) => a?.order - b?.order);

  const showContents = isFromHome ? isUserTyping : true;

  const showTimerIcon =
    ((isFromHome && isUserTyping) ||
      isFromEdit ||
      isFromExecution ||
      isFromQuickAdd) &&
    !isFromArchive;

  const isLoading = loader;

  const baseClassName =
    isFromEdit || isFromQuickAdd || isFromExecution || isFromAddGoal
      ? styles.inputDiv
      : `${styles.inputDivV2} ${
          isUserTyping && styles.inputDivV2Typing
        } ${className}`;

  // JSX
  return (
    <Row>
      {isDeleteModalOpen && (
        <DeleteTodoModal
          todo={todo}
          visible={isDeleteModalOpen}
          refetchTodos={refetchTodos}
          onChange={handleDeleteModelChange}
        />
      )}
      <div className={baseClassName}>
        <div className="flex-display">
          <Input.TextArea
            disabled={isLoading}
            autoSize={{ minRows: 1 }}
            className={`${styles.mainEditText} input-textarea-resize-none`}
            placeholder="Add your Todo or Idea..."
            value={todoFields?.title}
            onChange={handleTodoTitle}
            onKeyDown={handleKeyDownTitle}
            bordered={false}
            ref={titleInputRef}
          />
          {showTimerIcon && (
            <TimerIcon
              className="ml-8 cursor-pointer"
              style={{ fontSize: "16px" }}
              onClick={handleEditAndCreateDWNow}
            />
          )}
        </div>
        {showContents && (
          <Input.TextArea
            disabled={isLoading}
            autoSize={{ minRows: 3 }}
            className={`${styles.descriptionEditText} input-textarea-resize-none`}
            placeholder="Notes"
            value={todoFields?.description}
            onKeyDown={handleKeyDownDesc}
            onChange={handleTodoDescription}
            bordered={false}
          />
        )}
        {showContents && (
          <div className="mb-8 flex-display align-items-center justify-content-space-between">
            <div
              className={`flex-display align-items-center ${
                isFromEdit && "justify-content-space-between width-100-percent"
              }`}
            >
              {!isGoal ? (
                <Select
                  allowClear={true}
                  style={{ width: "100px" }}
                  className="todo-categories-select"
                  size="small"
                  value={isUnCategorized ? null : selectedCategory?.id}
                  onChange={handleChangeSelectedCategory}
                >
                  {filteredCategories?.map((todoCategory) => {
                    return (
                      <Option
                        key={todoCategory?.id}
                        className="flex-display align-items-center"
                        value={todoCategory?.id}
                      >
                        {todoCategory?.type === "TodoCategory" ? (
                          <span
                            className="mr-4"
                            style={getCategoryStyle(todoCategory)}
                          />
                        ) : (
                          <AimOutlined className={styles.aimIcon} />
                        )}

                        <Text>{todoCategory?.name}</Text>
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <div style={{ width: "100px" }} />
              )}
              <div className="ml-4">
                {Boolean(isRepeatCheckedRef?.current) && (
                  <RetweetOutlined
                    className={`font-size-18 ${styles.repeatIcon}`}
                  />
                )}
                <DatePicker
                  placeholder="Schedule"
                  format={shortDateFormatWithWeekDay}
                  className={styles.generalInputDiv}
                  style={dueDateSelectStyles}
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                  showToday={false}
                  allowClear={true}
                  open={isDatePickerOpen}
                  value={
                    todoFields?.due_date
                      ? moment(todoFields?.due_date, dateFormat)
                      : ""
                  }
                  onClick={handleDueDateClick}
                  onBlur={handleDueDateBlur}
                  onChange={handleDueDateChange}
                  panelRender={(panelNode) => (
                    <TodoDueDatePanel
                      todo={todo}
                      isRepeatCheckedRef={isRepeatCheckedRef}
                      panelNode={panelNode}
                      setRecurring={setRecurring}
                      setIsDatePickerOpen={setIsDatePickerOpen}
                    />
                  )}
                />
              </div>

              {!isFromHome && (
                <>
                  {isFromEdit ? (
                    <Text
                      className={"cursor-pointer"}
                      type="secondary"
                      onClick={handleIsDWSessionTrackerVisible}
                    >
                      {`${timeSpentToday}m`}
                    </Text>
                  ) : (
                    <div>
                      <div className="flex-display align-items-center">
                        <Input
                          value={todoFields?.time_spent}
                          className={`ml-4 todo-input-time-input ${styles.timeInput}`}
                          size="small"
                          onChange={handleTimeInput}
                        />
                        <Text type="secondary" className="ml-2">
                          m
                        </Text>
                      </div>
                      {isTimeSpentNaN && <Text type="danger">Invalid</Text>}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {showContents && (
          <TodoTypeCheckbox
            isPriority={todoFields?.priority}
            newLearnings={todoFields?.new_learnings}
            handlePriority={handlePriority}
            handleNewLearnings={handleNewLearnings}
          />
        )}
      </div>

      {showContents && (
        <div className="justify-content-space-between width-100-percent flex-display align-items-center">
          {isFromEdit || showDeleteIcon ? (
            <Row className="mt-12">
              <Button
                type="danger"
                size="small"
                onClick={handleDeleteModelChange}
                ghost
              >
                <DeleteOutlined className={styles.deleteIcon} />
              </Button>
              {!isGoal && (
                <Button
                  className="ml-8"
                  size="small"
                  onClick={handleCovertToGoal}
                >
                  <AimOutlined />
                </Button>
              )}
            </Row>
          ) : (
            <div />
          )}
          <div>
            <Button
              type="primary"
              size="small"
              className="mt-12 ml-12"
              onClick={onMenuCancel ? onMenuCancel : handleCancelInputTodo}
              ghost
            >
              Cancel
            </Button>
            <ButtonWithSpinner
              type="primary"
              size="small"
              className="mt-12 ml-8"
              onClick={handleSaveTodo}
              isSpinning={isSavingTodo}
            >
              {isFromEdit ? "Update Todo" : "Add Todo"}
            </ButtonWithSpinner>
          </div>
        </div>
      )}
    </Row>
  );
};

TodoInput.propTypes = {};

export default TodoInput;
