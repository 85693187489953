import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

import { timeZonesList } from "app/appConstants";

const { Option } = Select;

const TimeZoneSelect = ({
  className = "",
  disabled = false,
  value,
  onChange = () => {},
}) => {
  return (
    <Select
      showSearch={true}
      className={className}
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children?.toLowerCase().includes(input?.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {timeZonesList?.map(({ name, timezone }) => (
        <Option key={name} value={timezone}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

TimeZoneSelect.propTypes = {
  onChange: PropTypes.func,
};

export default TimeZoneSelect;
