import React from "react";
import { Typography, Button, Tooltip } from "antd";
import { PushpinOutlined } from "@ant-design/icons";

import { useUserSettings } from "store";
import { updateIsPinned } from "app/appConstants";
import {
  formattedTime,
  sendIPCUpdateEvent,
  handleOpenLink,
  getIsElectron,
} from "app/appUtils";

import { ReactComponent as OpenSlackLinkIcon } from "assets/slack_integration_todo_icon.svg";
import { ReactComponent as OpenJIRALinkIcon } from "assets/jira_integration_todo_icon.svg";

import styles from "../ExecuteDeepWork.module.css";

const { Title, Text } = Typography;

const ExecutionHeader = ({
  isPinned = false,
  selectedEvent = {},
  setIsPinned = () => {},
}) => {
  const isElectron = getIsElectron();

  const [settings] = useUserSettings();

  const handlePin = () => {
    if (isElectron) {
      setIsPinned(!isPinned);
      sendIPCUpdateEvent(updateIsPinned);
    }
  };

  const integrationLink = selectedEvent?.todos[0]?.integration_todo_url || "";

  const timeZone = settings?.timezone;

  const isSlackTodo =
    selectedEvent?.todos[0]?.integration_todo_url?.includes("slack");

  const title =
    selectedEvent?.title?.length > 105 ? selectedEvent?.title : null;

  const shorTitle =
    selectedEvent?.title?.length > 105
      ? `${selectedEvent?.title?.slice(0, 105)}...`
      : selectedEvent?.title;

  return (
    <div>
      <div className="mt-8 mb-8 flex-display align-items-flex-start width-100-percent">
        <div className="ml-16 mr-8" style={{ flexGrow: "2" }}>
          <Tooltip title={title}>
            <Title className={`text-primary ${styles.primaryText}`} level={3}>
              {shorTitle}
            </Title>
          </Tooltip>
          <Text>{`${formattedTime(
            selectedEvent?.from_time_utc,
            timeZone
          )} - ${formattedTime(selectedEvent?.to_time_utc, timeZone)}`}</Text>
        </div>

        <Button
          className={`mr-8 ${styles.pinButton} cursor-pointer`}
          type={isPinned ? "primary" : "default"}
          onClick={handlePin}
        >
          <PushpinOutlined
            className={styles.pinIcon}
            style={{
              color: isPinned ? "white" : "rgb(64, 115, 255)",
            }}
          />
        </Button>
      </div>
      {integrationLink && (
        <Button
          className="mt-8 ml-16"
          type="primary"
          size="small"
          ghost={true}
          onClick={handleOpenLink}
        >
          Open in{" "}
          {isSlackTodo ? (
            <>
              <OpenSlackLinkIcon
                style={{
                  height: "16px",
                  width: "16px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  cursor: "pointer",
                  marginBottom: "-4px",
                }}
              />
              Slack
            </>
          ) : (
            <>
              <OpenJIRALinkIcon
                style={{
                  height: "16px",
                  width: "16px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  cursor: "pointer",
                  marginBottom: "-4px",
                }}
              />
              JIRA
            </>
          )}
        </Button>
      )}
    </div>
  );
};

ExecutionHeader.propTypes = {};

export default ExecutionHeader;
