import React from "react";
import { useQuery, useMutation } from "react-query";
import { Typography, Switch, Form } from "antd";

import { BoxLoader, ButtonWithSpinner } from "common/components";
import { getSettingsApi, saveSettingsApi } from "app/settings/settingsApi";
import { updateMenu } from "app/appConstants";
import {
  successNotification,
  errorNotification,
  sendIPCUpdateEvent,
} from "app/appUtils";

const { Title, Text } = Typography;

const Billing = (props) => {
  const { data, isLoading, isFetching } = useQuery(
    "getSettings",
    getSettingsApi
  );

  const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation(
    saveSettingsApi,
    {
      onSuccess: (data) => {
        successNotification("Saved settings");
        sendIPCUpdateEvent(updateMenu);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const onFinish = (values) => {
    saveSettings({ setting: { ...values } });
  };

  return (
    <div>
      <Title level={3}>Enable/Disable Features</Title>
      {isLoading || isFetching ? (
        <BoxLoader />
      ) : (
        <Form
          name="settings"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            send_weekly_emails: data?.send_weekly_emails,
            habits: data?.habits,
          }}
          onFinish={onFinish}
          onFinishFailed={console.log}
          autoComplete="off"
        >
          <div className="mt-8">
            <div>
              <Text className="mr-8 inline-flex-display min-width-150-px">
                Weekly Summary Emails
              </Text>
              <Form.Item
                name="send_weekly_emails"
                valuePropName="checked"
                label=""
                noStyle
              >
                <Switch />
              </Form.Item>
            </div>
          </div>
          <div className="mt-8">
            <div>
              <Text className="mr-8 inline-flex-display min-width-150-px">
                Habits & Assists
              </Text>
              <Form.Item name="habits" valuePropName="checked" label="" noStyle>
                <Switch />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <ButtonWithSpinner
              isSpinning={isSavingSettings}
              disabled={isSavingSettings}
              type="primary"
              htmlType="submit"
            >
              Submit
            </ButtonWithSpinner>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

Billing.propTypes = {};

export default Billing;
