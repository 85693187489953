import React, { useState } from "react";
import { Typography } from "antd";

import Todo from "./todo/Todo";
import TodoCategoryTitle from "./CategoryTitle";
import {
  getRemainingTodosCount,
  getTotalTimeSpentInCategory,
  getFilteredCategoryTodos,
} from "../todoUtils";

const { Text } = Typography;

const Category = ({
  loader = false,
  isSavingTodo = false,
  dueDateFilter = "",
  tomorrowTabDate = "",
  category = {},
  categories = [],
  dailyCategorySummary = {},
  searchStr = "",
  appDate = "",
  saveTodo = () => {},
  refetchTodos = () => {},
  handleCreateDWNow = () => {},
}) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const shouldShowTime = dueDateFilter === "today";

  const remainingPan = getRemainingTodosCount({
    category,
    dueDateFilter,
    searchStr,
    appDate,
    tomorrowTabDate,
    unfinished: true,
  });

  if (
    remainingPan === 0 &&
    getFilteredCategoryTodos({
      category,
      searchStr,
      dueDateFilter,
      appDate,
      tomorrowTabDate,
    })?.length === 0
  ) {
    return null;
  }

  const timeSpentToday = getTotalTimeSpentInCategory(category?.todos || []);

  return (
    <div className="mt-8">
      <TodoCategoryTitle
        category={category}
        remainingPan={remainingPan}
        timeSpentToday={timeSpentToday}
        shouldShowTime={shouldShowTime}
        dueDateFilter={dueDateFilter}
        dailyCategorySummary={dailyCategorySummary}
        saveTodo={saveTodo}
        isSavingTodo={isSavingTodo}
        categories={categories}
      />
      {getFilteredCategoryTodos({
        category,
        searchStr,
        dueDateFilter,
        appDate,
        tomorrowTabDate,
      })?.map((todo, index) => {
        if (!showAll && index >= 5) {
          return null;
        }

        return (
          <Todo
            key={todo?.id}
            loader={loader}
            dueDateFilter={dueDateFilter}
            shouldShowTime={shouldShowTime}
            todo={todo}
            categories={categories}
            refetchTodos={refetchTodos}
            handleCreateDWNow={handleCreateDWNow}
          />
        );
      })}

      <div className="flex-display justify-content-center">
        {getFilteredCategoryTodos({
          category,
          dueDateFilter,
          appDate,
          searchStr,
          tomorrowTabDate,
        })?.length > 5 ? (
          <Text
            className="cursor-pointer text-primary"
            strong={true}
            onClick={handleShowAll}
          >
            {showAll ? "See less" : "See all"}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

Category.propTypes = {};

export default Category;
