import React from "react";
// import PropTypes from 'prop-types';
import { Row, Col, Typography } from "antd";

import { InlineSpinner } from "common/components";

const { Text, Link } = Typography;

const AssistHeader = ({
  isResolved = false,
  isExpanded = false,
  shouldShowInfo = true,
  isClearingAssist = false,
  data = {},
  icon = null,
  text = null,
  onClick = () => {},
  clearAssist = () => {},
}) => {
  const info = JSON.parse(data?.info);

  const handleClearAssist = () => {
    clearAssist({ assistId: data?.id });
  };

  return (
    <div>
      <Row className="width-100-percent mt-8 cursor-pointer" onClick={onClick}>
        <Col
          span={3}
          className="flex-display justify-content-center align-items-center"
        >
          {icon}
        </Col>
        <Col span={17}>
          {text ? (
            text
          ) : (
            <>
              <Text className="block-display">{info?.title}</Text>
              <Text type="secondary" className="font-size-12">
                {info?.ts_str}
              </Text>
            </>
          )}
        </Col>
        <Col
          span={4}
          className="flex-display flex-direction-column align-items-flex-end"
        >
          <Link className="mr-8" onClick={handleClearAssist}>
            Clear
          </Link>
          {isClearingAssist && <InlineSpinner className="ml-4" fontSize={16} />}
        </Col>
      </Row>
      {!isResolved && isExpanded && shouldShowInfo && (
        <Text className="block-display font-size-13">{info?.text}</Text>
      )}
    </div>
  );
};

AssistHeader.propTypes = {};

export default AssistHeader;
