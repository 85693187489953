const firebaseApiMock = () => {
  return {
    kind: "identitytoolkit#GetAccountInfoResponse",
    users: [
      {
        localId: "rPuSOsn63YOOF9YGvviwTn38Ikn1",
        email: "revanth@beautifulcode.in",
        displayName: "Revanth Boyapally",
        photoUrl:
          "https://lh3.googleusercontent.com/a-/AOh14GgbMgSaZW5tA3Tq-eXPN4ifZzOBf2xrouIX5aSZ",
        emailVerified: true,
        providerUserInfo: [
          {
            providerId: "google.com",
            displayName: "Revanth Boyapally",
            photoUrl:
              "https://lh3.googleusercontent.com/a-/AOh14GgbMgSaZW5tA3Tq-eXPN4ifZzOBf2xrouIX5aSZ",
            federatedId: "113782542757407756511",
            email: "revanth@beautifulcode.in",
            rawId: "113782542757407756511",
          },
        ],
        validSince: "1599560735",
        lastLoginAt: "1599564519428",
        createdAt: "1599560735626",
        lastRefreshAt: "2020-09-09T05:27:18.277Z",
      },
    ],
  };
};

export { firebaseApiMock };
