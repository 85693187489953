import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import moment from "moment";
import { Row, DatePicker, Typography, Button, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { EllipseText, InlineSpinner } from "common/components";
import { useAppDate } from "store";
import { useSaveTodo } from "common/hooks";
import { resolveAssistsApi } from "app/assists/assistsApi";
import AssistHeader from "./AssistHeader";
import DoneState from "./DoneState";
import ResolvedView from "./ResolvedView";
import { dateFormat } from "app/appConstants";
import { errorNotification } from "app/appUtils";
import { clearAssistApi } from "app/assists/assistsApi";
import { getCurrentTodo, getIsTodoInFuture } from "../assistsUtils";

import styles from "app/assists/Assists.module.css";

const { Text } = Typography;

const getValue = (changedTodos, todoId) => {
  let changedTodo =
    changedTodos?.filter(([changedTodoId, val]) => {
      return changedTodoId === todoId;
    }) || [];
  if (changedTodo?.length > 0) {
    changedTodo = changedTodo[0];
  }

  return changedTodo[1];
};

const getNextWorkingDay = (appDate) => {
  if (moment(appDate, dateFormat).day() === 5) {
    return moment(appDate, dateFormat)?.weekday(8)?.format(dateFormat);
  } else if (moment(appDate, dateFormat).day() === 6) {
    return moment(appDate, dateFormat).weekday(8).format(dateFormat);
  } else {
    return moment(appDate, dateFormat).add("days", 1).format(dateFormat);
  }
};

const getFutureTodosCount = (todos, allTodos, appDate) => {
  let currentNonFutureTodos = [];
  todos.forEach(([todoId]) => {
    let currentTodo =
      allTodos?.filter(
        (todo) => todo?.id === todoId && !getIsTodoInFuture(todo, appDate)
      ) || [];
    if (currentTodo?.length > 0) {
      currentNonFutureTodos.push(currentTodo[0]);
    }
  });

  return currentNonFutureTodos?.length;
};

const RescheduleTodo = ({
  todoId,
  todo = {},
  todoText,
  value = null,
  currentTodosCount = 0,
  setCurrentTodosCount = () => {},
  setChangedTodos = () => {},
  handleRescheduleTodos = () => {},
  refetch = () => {},
  refetchTodos = () => {},
}) => {
  const [isRescheduled, setIsRescheduled] = useState(false);

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: () => {
      setIsRescheduled(true);
      refetchTodos();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleChange = (value) => {
    setChangedTodos((changedTodos = []) => {
      const updatedTodos = changedTodos?.map(([tId, val]) => {
        if (tId === todoId) {
          return [todoId, value?.format(dateFormat)];
        } else {
          return [tId, val];
        }
      });
      return updatedTodos;
    });
  };

  const handleReschedule = () => {
    if (currentTodosCount === 1) {
      handleRescheduleTodos();
    } else {
      saveTodo({
        todo: {
          ...todo,
          due_date: value,
        },
      });
    }
  };

  if (isRescheduled) {
    return null;
  }

  return (
    <Row className="width-100-percent mt-4" justify="space-between">
      <Tooltip title={"abcd"}>
        <EllipseText text={todoText} characterLimit={14} tooltipOnMore={true} />
      </Tooltip>
      <div className="flex-display align-items-center">
        <DatePicker
          className="width-105-px"
          size="small"
          disabledDate={(current) =>
            current && current < moment()?.endOf("day")
          }
          value={value ? moment(value, dateFormat) : null}
          onChange={handleChange}
        />
        <div onClick={handleReschedule}>
          {isSavingTodo ? (
            <InlineSpinner className="ml-4 mr-4" fontSize={18} />
          ) : (
            <Button
              className={styles.rescheduleBtn}
              size="small"
              type="primary"
              ghost={true}
            >
              <ArrowRightOutlined className="height-14-px" />
            </Button>
          )}
        </div>
      </div>
    </Row>
  );
};

const RescheduleTodoAssist = ({
  data = {},
  allTodos = [],
  firstTodoCategory = "",
  icon = null,
  refetch = () => {},
  refetchTodos = () => {},
}) => {
  const [appDate] = useAppDate();

  const info = JSON.parse(data?.info);

  const todos = info?.todos || [];

  const isResolved = Boolean(data?.resolved_at);

  const [changedTodos, setChangedTodos] = useState([]);
  const [isStateExpanded, setIsStateExpanded] = useState(
    firstTodoCategory === "reschedule"
  );
  const [isAssistDone, setIsAssistDone] = useState(false);
  const [currentTodosCount, setCurrentTodosCount] = useState(0);

  useEffect(() => {
    setCurrentTodosCount(getFutureTodosCount(todos || [], allTodos, appDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todos, allTodos]);

  useEffect(() => {
    const defaultTodos = [];

    const nextWorkingDay = getNextWorkingDay(appDate);

    todos.forEach((todo) => {
      defaultTodos.push([todo[0], nextWorkingDay]);
    });

    setChangedTodos(defaultTodos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDate]);

  const {
    mutate: resolveAssists,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(resolveAssistsApi, {
    onSuccess: () => {
      refetch();
      setIsAssistDone(true);
      clearAssist({ assistId: data?.id });
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const { mutate: clearAssist, isLoading: isClearingAssist } = useMutation(
    clearAssistApi,
    {
      onSuccess: () => {
        setTimeout(() => {
          refetch();
          setIsAssistDone(false);
        }, 3000);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleRescheduleTodos = () => {
    resolveAssists({
      assistId: data?.id,
      cta: "reschedule_todos",
      todo_ids: changedTodos,
    });
  };

  const handleExpand = () => {
    setIsStateExpanded(!isStateExpanded);
  };

  return (
    <div className={styles.assist}>
      <AssistHeader
        isResolved={isResolved}
        isExpanded={isStateExpanded}
        shouldShowInfo={!isAssistDone}
        isClearingAssist={isClearingAssist}
        data={data}
        icon={icon}
        onClick={handleExpand}
        refetch={refetch}
        clearAssist={clearAssist}
      />
      {isStateExpanded && (
        <>
          {isAssistDone ? (
            <DoneState />
          ) : isResolved ? (
            <ResolvedView />
          ) : (
            <div className="mt-8 ml-8">
              <Row justify="space-between">
                <div className="width-200-px" />
                <Text className="mr-4" italic={true}>
                  Do Date
                </Text>
              </Row>
              {todos?.map(([todoId, todoText]) => {
                const currentTodo = getCurrentTodo(todoId, allTodos);

                if (
                  getIsTodoInFuture(currentTodo, appDate) ||
                  Boolean(currentTodo?.completed_at) ||
                  !currentTodo
                ) {
                  return null;
                }

                return (
                  <RescheduleTodo
                    todoId={todoId}
                    todo={currentTodo}
                    todoText={todoText}
                    currentTodosCount={currentTodosCount}
                    setCurrentTodosCount={setCurrentTodosCount}
                    value={getValue(changedTodos, todoId)}
                    setChangedTodos={setChangedTodos}
                    refetch={refetch}
                    refetchTodos={refetchTodos}
                    handleRescheduleTodos={handleRescheduleTodos}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

RescheduleTodoAssist.propTypes = {};

export default RescheduleTodoAssist;
