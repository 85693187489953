import React from "react";
// import PropTypes from "prop-types";
import { Typography, Row, Switch } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import styles from "./RecurringTodoFooter.module.css";

const { Text } = Typography;

const RecurringTodoFooter = ({
  isChecked = false,
  isRepeatCheckedRef = null,
  setIsChecked = () => {},
  setView = () => {},
}) => {
  const handleView = (newView = "") => {
    setView(newView);
  };

  const handleChange = (checked, e) => {
    setIsChecked(checked);
    isRepeatCheckedRef.current = checked;
    if (checked) {
      handleView("schedule");
    } else {
      handleView("calendar");
    }
  };

  return (
    <div>
      <Row className="width-100-percent" justify="space-between" align="middle">
        <Row align="middle">
          <Text>Repeat Todo</Text>
          <RetweetOutlined
            className={`ml-4 font-size-18 ${styles.repeatIcon}`}
          />
        </Row>

        <Switch checked={isChecked} onChange={handleChange} />
      </Row>
    </div>
  );
};

RecurringTodoFooter.propTypes = {};

export default RecurringTodoFooter;
