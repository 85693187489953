import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Row, Button } from "antd";
import moment from "moment";

import ScheduleTodo from "./schedule-todo/ScheduleTodo";
import RecurringTodoFooter from "./recurring-todo-footer/RecurringTodoFooter";

const TodoDueDatePanel = ({
  todo = {},
  panelNode = null,
  isRepeatCheckedRef = null,
  setRecurring = () => {},
  setIsDatePickerOpen = () => {},
}) => {
  const [view, setView] = useState("calendar");

  // Schedule Related State
  const [isChecked, setIsChecked] = useState(Boolean(todo?.recurring));
  const [periodicity, setPeriodicity] = useState(
    todo?.recurring?.periodicity || "weekly"
  );
  const [ends, setEnds] = useState(
    Boolean(todo?.recurring?.ends_on) ? "on" : "never" || "never"
  );
  const [onMonth, setOnMonth] = useState(
    todo?.recurring?.ends_on && todo?.recurring?.ends_on !== null
      ? moment(todo?.recurring?.ends_on, "YYYY-MM-DD")
      : null
  );
  const [selectedDays, setSelectedDays] = useState(
    todo?.recurring?.config?.weekdays || ["Monday"]
  );
  const [monthlyDays, setMonthlyDays] = useState(
    todo?.recurring?.config?.month_days?.join(", ") || ""
  );

  const handleView = (newView) => {
    setView(newView);
  };

  const handleCancel = () => {
    setIsDatePickerOpen(false);

    //Set default values
    setIsChecked(Boolean(todo?.recurring));
    setPeriodicity(todo?.recurring?.periodicity || "weekly");
    setEnds(Boolean(todo?.recurring?.ends_on) ? "on" : "never" || "never");
    setOnMonth(
      todo?.recurring?.ends_on && todo?.recurring?.ends_on !== null
        ? moment(todo?.recurring?.ends_on, "YYYY-MM-DD")
        : null
    );
    setSelectedDays(todo?.recurring?.config?.weekdays || ["Monday"]);
    setMonthlyDays(todo?.recurring?.config?.month_days?.join(", ") || "");
  };

  const handleSave = () => {
    setRecurring({
      recurring: isChecked,
      periodicity,
      selectedDays,
      monthlyDays,
      ends,
      onMonth,
    });
    setIsDatePickerOpen(false);
  };

  return (
    <div>
      <>{panelNode}</>
      <div className="m-8">
        <RecurringTodoFooter
          view={view}
          isRepeatCheckedRef={isRepeatCheckedRef}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          periodicity={periodicity}
          ends={ends}
          selectedDays={selectedDays}
          monthlyDays={monthlyDays}
          setEnds={setEnds}
          setPeriodicity={setPeriodicity}
          onMonth={onMonth}
          setOnMonth={setOnMonth}
          setSelectedDays={setSelectedDays}
          setView={handleView}
          setMonthlyDays={setMonthlyDays}
          setIsDatePickerOpen={setIsDatePickerOpen}
        />
      </div>
      {isChecked && (
        <>
          <ScheduleTodo
            periodicity={periodicity}
            ends={ends}
            selectedDays={selectedDays}
            monthlyDays={monthlyDays}
            onMonth={onMonth}
            setEnds={setEnds}
            setPeriodicity={setPeriodicity}
            setOnMonth={setOnMonth}
            setSelectedDays={setSelectedDays}
            setMonthlyDays={setMonthlyDays}
            setIsDatePickerOpen={setIsDatePickerOpen}
          />
          <Row className="m-8" justify="end">
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="ml-8"
              size="small"
              type="primary"
              ghost={true}
              onClick={handleSave}
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </div>
  );
};

TodoDueDatePanel.propTypes = {};

export default TodoDueDatePanel;
