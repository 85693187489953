import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  PageHeader,
  Typography,
  Divider,
  Row,
  Col,
  Checkbox,
  Select,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoxLoader, ButtonWithSpinner } from "common/components";
import {
  useIsUserRegisteredState,
  useSelectedIntegrationState,
  useOnboardedState,
} from "store";
import { updateMenuTodos } from "app/appConstants";
import {
  getIntegrationDetailsApi,
  configureIntegrationApi,
  syncIntegrationApi,
  deleteIntegrationApi,
  updateIntegrationApi,
} from "app/settings/integrations/integrationsApi";
import { getTodoCategoriesApi } from "app/settings/categories/todoCategoriesApis";
import {
  formattedDateTime,
  successNotification,
  errorNotification,
  errorNotificationWithString,
  sendIPCUpdateEvent,
  getIsElectron,
} from "app/appUtils";
import { benefits, typeMap } from "./common/constants";

import { ReactComponent as JIRAIcon } from "assets/jira-icon.svg";
import { ReactComponent as GoogleIcon } from "assets/google-logo.svg";
import { ReactComponent as SlackIcon } from "assets/slack-logo.svg";

import styles from "../Settings.module.css";

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const iconMap = {
  JiraCloudIntegration: <JIRAIcon className="mr-8" />,
  GoogleIntegration: <GoogleIcon className="mr-8" />,
  SlackIntegration: <SlackIcon className="mr-8" />,
};

const getProjectOptions = (projects = []) => {
  return projects?.map((project) => ({
    label: project?.name,
    value: project?.name,
  }));
};

const BenefitsCard = ({ name }) => {
  const type = typeMap[name] || "";
  const benefitsArray = benefits[type?.toLowerCase()] || [];

  return (
    <>
      <Title level={4}>Benefits of Integration</Title>
      {benefitsArray?.map((benefit, index) => (
        <Text className="block-display">
          {index + 1} . {benefit}
        </Text>
      ))}
    </>
  );
};

const getTodoCategoryValue = (todoCategoryId, todoCategories) => {
  const todoCategory =
    todoCategories?.filter((category) => category?.id === todoCategoryId)[0] ||
    null;

  if (todoCategory?.name === "Uncategorized") {
    return null;
  }

  return todoCategory?.id || null;
};

const IntegrationDetails = ({
  name = "",
  isAStaticIntegration = false,
  integrationId = 0,
  handleSelectIntegration = () => {},
  refetchIndexList = () => {},
}) => {
  const isElectron = getIsElectron();
  const [onboarded] = useOnboardedState();
  const queryClient = useQueryClient();
  const [, setSelectedIntegration] = useSelectedIntegrationState(null);

  const [isUserRegistered] = useIsUserRegisteredState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [userSelectedStatuses, setUserSelectedStatuses] = useState([]);
  const [doneTodo, setDoneTodo] = useState(null);

  useEffect(() => {
    queryClient.invalidateQueries("getTodos");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["getIntegrationDetailsApi", integrationId],
    getIntegrationDetailsApi,
    {
      onSuccess: (data) => {
        setCheckedValues(data?.config?.user_selected_projects);
        setUserSelectedStatuses(data?.config?.user_selected_statuses);
        setDoneTodo(data?.config?.user_selected_done_status);
        sendIPCUpdateEvent(updateMenuTodos, { refreshInit: true });
      },
    }
  );

  const { data: todoCategories, isLoading: isLoadingCategories } = useQuery(
    "getTodoCategories",
    getTodoCategoriesApi,
    {
      enabled: isUserRegistered,
      onSuccess: (data) => {},
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const { mutate: updateIntegration, isLoading: isUpdateIntegrationLoading } =
    useMutation(updateIntegrationApi, {
      onSuccess: () => {
        successNotification("Updated Integration");
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const { mutate: configureIntegration, isLoading: isConfiguringIntegration } =
    useMutation(configureIntegrationApi, {
      onSuccess: () => {
        successNotification("Successfully integrated");
        refetch();
        refetchIndexList();
        sendIPCUpdateEvent(updateMenuTodos, { refreshInit: true });

        if (!onboarded) {
          queryClient.invalidateQueries("me");
        }
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const { mutate: syncIntegration, isLoading: isSyncingIntegration } =
    useMutation(syncIntegrationApi, {
      onSuccess: () => {
        successNotification("Successfully synced integration");
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const { mutate: deleteIntegration, isLoading: isDeletingIntegration } =
    useMutation(deleteIntegrationApi, {
      onSuccess: () => {
        successNotification("Successfully deleted integration");
        refetchIndexList();
        setSelectedIntegration(null);
        sendIPCUpdateEvent(updateMenuTodos, { refreshInit: true });
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const loader =
    isLoading ||
    isFetching ||
    isLoadingCategories ||
    isUpdateIntegrationLoading;

  if (loader) {
    return <BoxLoader />;
  }

  const handleConfigure = () => {
    if (checkedValues?.length === 0 || userSelectedStatuses?.length === 0) {
      errorNotificationWithString("Values can't be empty");
      return;
    }

    configureIntegration({
      id: data?.id,
      type: name,
      user_selected_projects: checkedValues,
      user_selected_statuses: userSelectedStatuses,
      user_selected_done_status: doneTodo,
    });
  };

  const handleSyncIntegration = () => {
    syncIntegration({ id: data?.id });
  };

  const handleDeleteIntegration = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete the integration.`,
      onOk() {
        deleteIntegration({ id: data?.id });
      },
      okText: "Delete",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleBack = () => {
    if (isAStaticIntegration) {
      refetchIndexList();
    }
    handleSelectIntegration(null);
  };

  const handleChangeSelectedCategory = (val) => {
    updateIntegration({ id: data?.id, todo_category_id: val });
  };

  const isConnected = data?.aasm_state === "connected";
  const isErroring = data?.aasm_state === "erroring";

  const filteredCategories = todoCategories
    ?.filter((category) => category?.name?.toLowerCase() !== "uncategorized")
    ?.sort((a, b) => a?.order - b?.order);

  return (
    <div className={isElectron ? styles.integrationDetails : ""}>
      <PageHeader
        className="site-page-header"
        onBack={handleBack}
        title={<Title level={4}>{`Integrations / ${typeMap[name]}`}</Title>}
        subTitle=""
      />

      <Divider />
      <Row align="middle" gutter={[16, 16]}>
        <Col span={9}>
          <Row align="middle">
            <Col>{iconMap[name]}</Col>
            <Col>
              <Text>{typeMap[name]}</Text>
            </Col>
          </Row>
          <Text className="block-display">{data?.key}</Text>
        </Col>
        <Col span={6}>
          <Text className="block-display" type="secondary">
            Status
          </Text>
          {isConnected && (
            <span
              style={{
                display: "inline-block",
                height: "10px",
                width: "10px",
                backgroundColor: "#45A914",
                borderRadius: "50%",
                marginRight: "4px",
              }}
            />
          )}
          <Text>
            {isErroring
              ? "Erroring"
              : isConnected
              ? "Connected"
              : "Initialization in Progress..."}
          </Text>
        </Col>
        <Col span={9}>
          {isConnected && (
            <>
              <ButtonWithSpinner
                type="primary"
                spinnerColor={"#003399"}
                ghost={true}
                isSpinning={isSyncingIntegration}
                onClick={handleSyncIntegration}
              >
                Manual Sync
              </ButtonWithSpinner>
            </>
          )}
        </Col>
      </Row>
      <div className="mt-8">
        <Text>{`Last synced at: ${formattedDateTime(
          data?.last_synced_utc
        )}`}</Text>
      </div>

      <div className="my-8">
        <Text>
          Select a todo category to assign todos from this integration:{" "}
        </Text>
        <Select
          allowClear={true}
          style={{ width: "100px" }}
          className="todo-categories-select ml-8"
          size="small"
          value={getTodoCategoryValue(data?.todo_category?.id, todoCategories)}
          onChange={handleChangeSelectedCategory}
        >
          {filteredCategories?.map((todoCategory) => {
            return (
              <Option key={todoCategory?.id} value={todoCategory?.id}>
                <span
                  className="mr-4"
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: todoCategory?.color_formula,
                    display: "inline-block",
                    borderRadius: "50%",
                  }}
                />

                <Text>{todoCategory?.name}</Text>
              </Option>
            );
          })}
        </Select>
      </div>

      <Divider />
      {isAStaticIntegration && (
        <div className="mb-16">
          <BenefitsCard name={name} />
        </div>
      )}
      <div>
        {!isAStaticIntegration ? (
          <div>
            <Title level={4}>Configure your integration</Title>
            <Text className="block-display">
              Select projects you want to sync:{" "}
            </Text>
            <div>
              <Checkbox.Group value={checkedValues} onChange={setCheckedValues}>
                {getProjectOptions(data?.config?.details?.projects || [])?.map(
                  (option) => (
                    <div>
                      <Checkbox value={option?.value}>{option?.label}</Checkbox>
                    </div>
                  )
                )}
              </Checkbox.Group>
            </div>
            <div>
              <Text className="block-display mt-16">
                Select statuses of issues you want to see as Todos:
              </Text>
              <Checkbox.Group
                value={userSelectedStatuses}
                onChange={setUserSelectedStatuses}
              >
                {data?.config?.details?.issue_statuses?.map((issueColumn) => (
                  <div>
                    <Checkbox value={issueColumn}>{issueColumn}</Checkbox>
                  </div>
                ))}
              </Checkbox.Group>
            </div>
            <Row align="middle" className="mt-16">
              <Text>Set checked Todo status to:</Text>
              <Select
                allowClear={true}
                className="min-width-250-px"
                value={doneTodo}
                onChange={setDoneTodo}
              >
                {data?.config?.details?.issue_statuses?.map((issueColumn) => (
                  <Option value={issueColumn}>{issueColumn}</Option>
                ))}
              </Select>
            </Row>
            <Text className="block-display" italic={true}>
              Leave the value empty if you dont want to automatically set the
              status
            </Text>
          </div>
        ) : (
          <></>
        )}

        <div className="mt-16">
          {data?.aasm_state === "initialized" ? (
            <>
              {!isAStaticIntegration && (
                <div>
                  <ButtonWithSpinner
                    className="width-250-px"
                    type="primary"
                    ghost={true}
                    spinnerColor={"#003399"}
                    isSpinning={isConfiguringIntegration}
                    disabled={isConfiguringIntegration}
                    onClick={handleConfigure}
                  >
                    Configure
                  </ButtonWithSpinner>
                </div>
              )}
            </>
          ) : (
            <Row className="flex-direction-column width-250-px">
              {!isAStaticIntegration && (
                <ButtonWithSpinner
                  type="primary"
                  ghost={true}
                  spinnerColor={"#003399"}
                  isSpinning={isConfiguringIntegration}
                  disabled={isConfiguringIntegration}
                  onClick={handleConfigure}
                >
                  Re Configure
                </ButtonWithSpinner>
              )}
            </Row>
          )}

          <ButtonWithSpinner
            danger={true}
            className="mt-8 width-250-px"
            ghost={true}
            spinnerColor={"#ff4d4f"}
            isSpinning={isDeletingIntegration}
            disabled={isDeletingIntegration}
            onClick={handleDeleteIntegration}
          >
            Delete Integration
          </ButtonWithSpinner>
        </div>
      </div>
    </div>
  );
};

IntegrationDetails.propTypes = {
  integrationId: PropTypes.number,
};

export default IntegrationDetails;
