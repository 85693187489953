import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const ElectronIntegrationsRedirectButton = ({
  redirectCode = "",
  redirectType = "",
}) => {
  const handleIntegrationNavigateToDW = () => {
    localStorage.setItem("isIntegrationSuccessFullState", false);
    localStorage.setItem("isFromElectron", false);
    localStorage.setItem("JIRAIntegrationId", false);
  };

  return (
    <div className="mt-16">
      <Button
        className="mt-8"
        href={`dw-app://integrations/open-from-electron?redirectType=${redirectType}&redirectCode=${redirectCode}`}
        onClick={handleIntegrationNavigateToDW}
        size="large"
        type="primary"
        ghost={true}
      >
        Open deepwork.easy App
      </Button>
    </div>
  );
};

ElectronIntegrationsRedirectButton.propTypes = {
  redirectParam: PropTypes.string,
};

export default ElectronIntegrationsRedirectButton;
