import moment from "moment";

export const finerAdjustEndTime = (start, end) => {
  const startMoment = new moment(start)?.utc();

  let newEnd = new moment(end)?.utc();

  const startMomentTime = startMoment?.format("hh:mm:ss a") || "";
  const newEndMomentTime = newEnd?.format("hh:mm:ss a") || "";

  if (startMomentTime === newEndMomentTime) {
    newEnd = moment(newEnd).add(2, "minutes");
  }

  return newEnd;
};
