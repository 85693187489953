import axios from "axios";

const getHabitMetricsApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v1/habits/${queryKey[1]}`);
  return data;
};

const getProductivityIndexApi = async () => {
  const { data } = await axios.get("/api/v1/daily_points");
  return data;
};

const getDailyProgressPointsApi = async () => {
  const { data } = await axios.get("/api/v1/daily_points/progress");
  return data;
};

const getUserHabitsApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v1/habits/${queryKey[1]}`);
  return data;
};

export {
  getHabitMetricsApi,
  getProductivityIndexApi,
  getDailyProgressPointsApi,
  getUserHabitsApi,
};
