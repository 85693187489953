import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Row, Typography, Divider, Input, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { userFeedbackApi } from "common/apis/appApi";
import { successNotification, errorNotification } from "app/appUtils";

const { Title, Text } = Typography;

const FeedbackForm = (props) => {
  const [feedback, setFeedback] = useState("");

  const history = useHistory();

  const { mutate: userFeedback } = useMutation(userFeedbackApi, {
    onSuccess: () => {
      setFeedback("");
      successNotification("Thanks for your inputs!!");
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleFeedbackChange = (e) => {
    setFeedback(e?.target?.value || "");
  };

  const handleSubmit = () => {
    userFeedback({ feedback });
  };

  const handleNavigateMenu = () => {
    history.push("/");
  };

  return (
    <div>
      <Row className="mt-8 ml-8 mb-8" justify="space-between" align="middle">
        <Title level={4}>Feedback & Requests</Title>
        <CloseOutlined
          className="cursor-pointer mr-8"
          onClick={handleNavigateMenu}
        />
      </Row>
      <Divider className="my-4" />
      <div className="m-16">
        <Text className="block-display mb-24">
          Tell us the good, bad and ugly so that we can make deepwork.easy
          better for you
        </Text>
        <Input.TextArea
          className="block-display font-size-14"
          placeholder="Your feature requests, bugs etc"
          autoSize={{ minRows: 5, maxRows: 15 }}
          value={feedback}
          onChange={handleFeedbackChange}
        />
        <Button className="mt-16" onClick={handleSubmit}>
          Submit Feedback
        </Button>
      </div>
    </div>
  );
};

FeedbackForm.propTypes = {
  widthExpandedType: PropTypes.string,
  handleShrinkWindow: PropTypes.func,
};

export default FeedbackForm;
