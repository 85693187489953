import React from "react";
// import PropTypes from 'prop-types';

import { useIsAppExpandedState } from "store";
import FeedbackForm from "./components/FeedbackForm";

import { getMainContainerStyling, getIsElectron } from "app/appUtils";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const Feedback = (props) => {
  const isElectron = getIsElectron();

  const [isAppExpanded] = useIsAppExpandedState();

  const isWebPage = !isChromeExtension && !isElectron;

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  return (
    <div
      id="feedback-content"
      className={`flex-display ${isWebPage && "web-main-content-styles"} `}
    >
      <div className="ml-8 mr-8 mb-8" style={mainContainerStyles}>
        <FeedbackForm />
      </div>
    </div>
  );
};

Feedback.propTypes = {};

export default Feedback;
