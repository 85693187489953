import React from "react";

import Redirect from "./Redirect";

const GoogleRedirect = (props) => {
  return <Redirect name="GoogleIntegration" />;
};

GoogleRedirect.propTypes = {};

export default GoogleRedirect;
