import React from "react";
import { ReactComponent as BlueCalendarIcon } from "assets/blue_calendar_icon.svg";
import { shortDateFormatWithWeekDay, dateFormat } from "app/appConstants";
import moment from "moment";

const TodoDueDate = ({ todo, dueDateFilter = "" }) => {
  return (
    <div
      className={"flex-display justify-content-flex-start align-items-center"}
    >
      {todo?.due_date && dueDateFilter !== "today" && (
        <div className={`category_in_todo flex-display align-items-center`}>
          <BlueCalendarIcon
            className="mr-8"
            style={{ minWidth: "12px", minHeight: "14px" }}
          />
          {todo?.due_date &&
            (moment().format(dateFormat) === todo?.due_date ? (
              <span>Today</span>
            ) : moment().add(1, "day").format(dateFormat) === todo?.due_date ? (
              <span>Tomorrow</span>
            ) : (
              moment(todo?.due_date, dateFormat).format(
                shortDateFormatWithWeekDay
              )
            ))}
        </div>
      )}
    </div>
  );
};

TodoDueDate.propTypes = {};

export default TodoDueDate;
