import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Typography, Input, Button, Select } from "antd";

import { BoxLoader, InlineSpinner } from "common/components";
import {
  getTodoCategoriesApi,
  saveTodoCategoryApi,
  reorderTodoCategoriesApi,
} from "app/settings/categories/todoCategoriesApis";
import Category from "./components/Category";
import { categoryOptions } from "./constants";
import { updateMenu } from "app/appConstants";
import { reorder, errorNotification, sendIPCUpdateEvent } from "app/appUtils";

import styles from "./Categories.module.css";

const { Title } = Typography;
const { Option } = Select;

const Categories = (props) => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    key: "rgb(184, 37, 95)",
    value: "berry-red",
  });

  const { data, isLoading, isFetching, refetch } = useQuery(
    "getCategories",
    getTodoCategoriesApi,
    {
      onSuccess: (data) => {
        setCategories(data);
      },
    }
  );

  const { mutate: saveTodoCategory, isLoading: isSavingTodoCategory } =
    useMutation(saveTodoCategoryApi, {
      onSuccess: (data, variables) => {
        refetch();
        if (variables?.onSuccess) {
          variables.onSuccess();
        }
        sendIPCUpdateEvent(updateMenu);
      },
      onError: (err, variables) => {
        errorNotification(err);
        if (variables?.onError) {
          variables.onError();
        }
      },
    });

  const { mutate: reorderTodoCategories, isLoading: isReorderingTodoCategory } =
    useMutation(reorderTodoCategoriesApi, {
      onSuccess: () => {
        refetch();
        sendIPCUpdateEvent(updateMenu);
      },
    });

  const handleInputChange = (e) => {
    setNewCategoryName(e.target.value);
  };

  const handleAddCategory = () => {
    saveTodoCategory({
      todo_category: {
        name: newCategoryName,
        color_name: selectedCategory?.value,
        color_formula: selectedCategory?.key,
      },
    });
    setNewCategoryName("");
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    console.log(result);

    const orderedTodoCategories = data
      ?.filter((category) => category?.name !== "Uncategorized")
      ?.sort((a, b) => a?.order - b?.order);
    const unCategorized = categories?.filter(
      (category) => category?.name?.toLowerCase() === "uncategorized"
    );
    const unCategorizedId = unCategorized[0]?.id;

    const reorderedFilteredCategories = reorder(
      orderedTodoCategories,
      result.source.index,
      result.destination.index
    )?.map((category, index) => ({ ...category, order: index + 1 }));

    setCategories(reorderedFilteredCategories);

    const categoryOrderMap = {};

    reorderedFilteredCategories.forEach((category, index) => {
      categoryOrderMap[category?.id] = category?.order;
    });

    categoryOrderMap[unCategorizedId] = 99;

    reorderTodoCategories({ todoCategoryOrderMap: categoryOrderMap });
  };

  const handleAddingCategory = () => {
    setIsAddingCategory(!isAddingCategory);
  };

  const orderedTodoCategories = data
    ?.filter((category) => category?.name !== "Uncategorized")
    ?.sort((a, b) => a?.order - b?.order);

  return (
    <div>
      <Title level={3}>
        Categories{" "}
        {(isSavingTodoCategory || isReorderingTodoCategory) && (
          <InlineSpinner />
        )}
      </Title>
      {isLoading || isFetching ? (
        <BoxLoader />
      ) : (
        <div className={styles.categoriesList}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderedTodoCategories?.map((category, index) => (
                    <Category
                      category={category}
                      index={index}
                      saveTodoCategory={saveTodoCategory}
                      refetch={refetch}
                    />
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="block-display m-8">
            {isAddingCategory ? (
              <div className="flex-display justify-content-space-between align-items-center">
                <Input
                  size="small"
                  className="width-80-percent"
                  value={newCategoryName}
                  onChange={handleInputChange}
                  onPressEnter={handleAddCategory}
                />
                <Select
                  size="small"
                  value={selectedCategory?.value}
                  onChange={(value, option) =>
                    setSelectedCategory({ value, key: option?.key })
                  }
                >
                  {categoryOptions?.map((option) => (
                    <Option key={option?.key} value={option?.value}>
                      {option?.label}
                    </Option>
                  ))}
                </Select>

                <Button
                  className="ml-8"
                  size="small"
                  type="primary"
                  ghost={true}
                  onClick={handleAddingCategory}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button type="primary" onClick={handleAddingCategory}>
                Add Category
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Categories.propTypes = {};

export default Categories;
