import React from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from "antd";
import { ReactComponent as Icon404 } from "assets/result/404.svg";

const PageNotFound = (props) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <Result
      icon={<Icon404 className="result-icon" />}
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleGoBack}>
          Back Home
        </Button>
      }
    />
  );
};

PageNotFound.propTypes = {};

export default PageNotFound;
