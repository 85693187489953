import axios from "axios";
import momentTimeZone from "moment-timezone";
import * as Sentry from "@sentry/react";

const appInitApi = async () => {
  let platform = "";
  let isMobile = "";
  let userAgent = "";
  try {
    userAgent = window?.navigator?.userAgent;
    isMobile = window?.navigator?.userAgentData?.mobile;
    platform = window?.navigator?.userAgentData?.platform;
  } catch (error) {
    if (!userAgent) {
      Sentry.captureMessage(
        `${window?.globalCurrentUserEmail} - There was an issue in getting the OS of the user.`,
        {
          extra: error,
        }
      );
    }
  }

  const { data } = await axios.post("/api/init", {
    platform,
    is_mobile: isMobile,
    user_agent: userAgent,
    time_zone: momentTimeZone.tz.guess(),
  });
  return data;
};

const meApi = async () => {
  const { data } = await axios.get("/api/me");
  return data;
};

const appUsageApi = async () => {
  const { data } = await axios.get("/api/v1/usage");
  return data;
};

const personaOnboardApi = async (user) => {
  const { data } = await axios.post("/api/v1/persona_onboard", {
    user,
  });
  return data;
};

const saveUserDetailsApi = async ({ onboarded }) => {
  const { data } = await axios.post("/api/v1/user", {
    user: { onboarded },
  });
  return data;
};

const createCustomTokenApi = async ({ idToken }) => {
  const { data } = await axios.post("/api/get_fb_custom_token", {
    id_token: idToken,
  });
  return data;
};

const getDailyStatsApi = async ({ queryKey: [_, date] }) => {
  const { data } = await axios.get(`/api/v1/daily_stats/${date}`);
  return data;
};

const userFeedbackApi = async ({ feedback }) => {
  const { data } = await axios.post("/api/v2/feedbacks", {
    feedback,
  });
  return data;
};

export {
  appInitApi,
  meApi,
  appUsageApi,
  personaOnboardApi,
  saveUserDetailsApi,
  createCustomTokenApi,
  getDailyStatsApi,
  userFeedbackApi,
};
