import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Skeleton } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import Meeting from "./Meeting";
import { getAllTimeSlots } from "app/home/homeUtils";

const { Text } = Typography;

const Meetings = ({
  categories = [],
  isLoadingDayView = false,
  meetingsDate = null,
  otherCalendarEventsData = [],
}) => {
  const allTimeSlots = getAllTimeSlots(meetingsDate, otherCalendarEventsData);

  if (isLoadingDayView) {
    return <Skeleton active={true} paragraph={{ row: 2 }} />;
  }

  const noMeetings = allTimeSlots?.length === 0;

  if (!meetingsDate) {
    return null;
  }

  return (
    <div className="mb-8">
      <div id="meeting-popover" />
      <Row className="mb-4" justify="space-between">
        <div>
          <CalendarOutlined style={{ color: "grey", fontSize: "18px" }} />
          <Text className="ml-8">MEETINGS</Text>
        </div>
      </Row>
      {noMeetings ? (
        <Text italic={true} type="secondary">
          No meetings
        </Text>
      ) : (
        <>
          {allTimeSlots?.map((timeSlot) => (
            <Meeting timeSlot={timeSlot} categories={categories} />
          ))}
        </>
      )}
    </div>
  );
};

Meetings.propTypes = {
  events: PropTypes.array,
  defaultSlotsCount: PropTypes.number,
};

export default Meetings;
