import React from "react";
// import PropTypes from 'prop-types';
import { Typography } from "antd";

const { Text } = Typography;

const PriorityText = (props) => {
  return (
    <div className="flex-display align-items-center">
      <span
        style={{
          display: "inline-block",
          marginRight: "2px",
          height: "8px",
          width: "8px",
          borderRadius: "50%",
          backgroundColor: "#ff4d4f",
        }}
      />
      <Text type="secondary" className="font-size-12">
        Priority
      </Text>
    </div>
  );
};

PriorityText.propTypes = {};

export default PriorityText;
