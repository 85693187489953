import React from "react";
import { useQuery } from "react-query";
import { Table } from "antd";
import moment from "moment";
import { appUsageApi } from "common/apis/appApi";
import { errorNotification } from "app/appUtils";
import { Redirect } from "react-router-dom";
import { getHoursMinutesString } from "app/appUtils";
import { dateFormat } from "app/appConstants";
import { BoxLoader } from "common/components";

const Usage = (props) => {
  let columns = [],
    data = [];

  const { data: usageData = [], isLoading: isLoadingAppUsage } = useQuery(
    "getAppUsage",
    appUsageApi,
    {
      onSuccess: (data) => {},
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  if (usageData && usageData.length > 0) {
    columns.push({
      title: "user",
      dataIndex: "user",
      key: "user",
      render: (user) => {
        return (
          <div>
            <p>{user?.name}</p>
            <p>{user?.email}</p>
          </div>
        );
      },
    });

    columns = columns.concat(
      usageData[0]?.day_wise_data?.map((dayData) => {
        const date = dayData?.date;
        return {
          title:
            moment().format(dateFormat) === date ? `${date} (Today)` : date,
          dataIndex: date,
          key: date,
          width: 550,
          render: (data) => {
            return data.show ? (
              <div style={{ width: "155px" }}>
                <p>
                  Todos Created - <b>{data.todos_created || 0}</b>
                </p>
                <p>
                  Todos Completed - <b>{data.todos_completed || 0}</b>
                </p>
                <p>
                  Time Blocks - <b>{data.time_blocks || 0}</b>
                </p>
                <p>
                  DW time -{" "}
                  <b>{getHoursMinutesString(data.dw_time || 0) || 0}</b>
                </p>
                <p>
                  Executed Shutdown -{" "}
                  <b>{data.executed_shutdown ? "Yes" : "No"}</b>
                </p>
              </div>
            ) : (
              <div style={{ width: "155px" }}></div>
            );
          },
        };
      })
    );
  }

  data = usageData?.map((indivUserData) => {
    let dataObj = {};
    indivUserData.day_wise_data.forEach((dayData) => {
      dataObj["user"] = indivUserData?.user;
      dataObj[dayData?.date] = {
        show:
          dayData?.date &&
          indivUserData?.user?.created_at &&
          moment(dayData?.date, dateFormat)?.isSameOrAfter(
            moment(indivUserData?.user?.created_at, dateFormat)
          ),
        todos_created: dayData?.created_todos_count,
        todos_completed: dayData?.completed_todos_count,
        time_blocks: dayData?.time_blocks,
        dw_time: dayData?.dw_time,
        executed_shutdown: dayData?.executed_shutdown,
      };
    });
    dataObj["user"] = indivUserData?.user;
    return dataObj;
  });

  return (
    <div>
      {isLoadingAppUsage ? (
        <BoxLoader />
      ) : usageData && usageData.length > 0 ? (
        <Table
          columns={columns || []}
          dataSource={data || []}
          size="large"
          pagination={{ pageSize: 50 }}
        />
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
};

Usage.propTypes = {};

export default Usage;
