import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Avatar, Typography } from "antd";

import { getUserName, getUserInitials } from "app/appUtils";
import "./UserAvatar.css";

const { Text } = Typography;

const stringToHslColor = (str = "", s, l) => {
  if (!str) {
    return "hsl(0, 0%, 89%)";
  }
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const UserAvatar = ({
  className = "",
  user = {},
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  size = "default",
  onClick = () => {},
  ...props
}) => {
  return (
    <Avatar
      {...props}
      className={
        className ? clsx(className, "employee-avatar") : "employee-avatar"
      }
      size={size}
      src={user?.user_profile_pic_url || ""}
      style={{
        backgroundColor: stringToHslColor(getUserName(user), 60, 50),
        height,
        width,
      }}
      onClick={onClick}
    >
      <Text style={{ fontSize: textFontSize, color: "white" }}>
        {getUserInitials(getUserName(user))}
      </Text>
    </Avatar>
  );
};

const UserAvatarWrapper = ({
  className = "",
  user = {},
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  size = "default",
  onClick = () => {},
}) => {
  const employeeAvatarProps = {
    className,
    user,
    height,
    width,
    textFontSize,
    size,
    onClick,
  };
  return <UserAvatar {...employeeAvatarProps} />;
};

UserAvatarWrapper.propTypes = {
  employee: PropTypes.object,
};

export default UserAvatarWrapper;
