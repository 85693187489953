import React, { useState, useEffect } from "react";
import moment from "moment";
import { Input } from "antd";
import { useQuery, useMutation } from "react-query";

import {
  useIsAfter4PM,
  useSetCurrentDate,
  useMenuTitleUpdater,
  useNavigationFromHome,
} from "./homeHooks";
import DailyStats from "./daily-stats/DailyStats";
import { Redirecting, PageSpinner, RefreshCenter } from "common/components";
import {
  useAppDate,
  useUserIntegrationInfo,
  useSelectedEventState,
  useLastSyncedTimeState,
} from "store";
import { TodosContext } from "app/home/context";
import TopNav from "app/top-nav/TopNav";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import Todos from "./todos/Todos";
import Meetings from "./meetings/Meetings";
import ExecuteDeepWork from "./execute-deepwork/ExecuteDeepWork";
import DueDateTabs from "./due-date-tabs/DueDateTabs";
import { useDoneTodoMessage, useIsAppExpandedState } from "store";
import { getDayViewApi } from "app/home/todos/todosApis";
import { syncIntegrationsApi } from "app/settings/integrations/integrationsApi";
import {
  getIsElectron,
  errorNotification,
  getMainContainerStyling,
} from "app/appUtils";
import { getDayViewOnSuccess } from "./HomeOnSuccessHandlers";
import { dateFormat, dateTimeFormat } from "app/appConstants";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const Home = (props) => {
  // App Level state
  const [, setLastSyncedTime] = useLastSyncedTimeState();
  const [doneTodoMsg] = useDoneTodoMessage();
  const [isAppExpanded] = useIsAppExpandedState();
  const [appDate] = useAppDate();
  const [userIntegrationInfo] = useUserIntegrationInfo();
  const [selectedEvent, setSelectedEvent] = useSelectedEventState();

  // Component Level State
  const [currentDate, setCurrentDate] = useState(appDate);
  const [meetingsDate, setMeetingsDate] = useState(currentDate);
  const [dueDateFilter, setDueDateFilter] = useState("today");
  const [searchTodoStr, setSearchTodoStr] = useState("");
  const [tomorrowTabDate, setTomorrowTabDate] = useState(
    moment(appDate, dateFormat)?.add(1, "day")?.format(dateFormat)
  );
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [, setClearingDoneTodos] = useState(false);
  const [selectedTab, setSelectedTab] = useState("todos");
  const [wasHomeSelectedWhileFocusing, setWasHomeSelectedWhileFocusing] =
    useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const [isAfter4PM] = useIsAfter4PM();
  const [categories, setCategories] = useState([]);
  const [todosCount, setTodosCount] = useState({
    totalCount: 0,
    pendingTodosTodayCount: 0,
    doneTodosCount: 0,
    todayTodosCount: 0,
    tomorrowTodosCount: 0,
    allTodosCount: 0,
  });

  // Component Logic
  const isElectron = getIsElectron();
  const isWebPage = !isChromeExtension && !isElectron;
  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  // Api Calls
  const {
    data,
    isLoading,
    // isFetching,
    // refetch
  } = useQuery(
    ["getDayView", dueDateFilter === "today" ? currentDate : tomorrowTabDate],
    getDayViewApi,
    {
      onSuccess: (data) => {
        getDayViewOnSuccess({
          wasHomeSelectedWhileFocusing,
          data,
          setSelectedTab,
          handleSelectedEvent,
        });
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const { mutate: syncIntegrations } = useMutation(syncIntegrationsApi, {
    onSuccess: () => {
      // NOTE: you can sync integrations but no need to refresh query,
      // they'll come up whenever it happens
      // queryClient.invalidateQueries("getTodos");
      // queryClient.invalidateQueries("getOtherCalendarEvents");
      setLastSyncedTime(moment()?.format(dateTimeFormat));
    },
    onError: (err) => {},
  });

  useEffect(() => {
    syncIntegrations({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMenuTitleUpdater({
    isLoading,
    selectedEvent,
    todosCount,
    currentDate,
    dayViewData: data,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const openParam = urlParams.get("openParam");

  useNavigationFromHome({ openParam });

  useSetCurrentDate({ setCurrentDate, setTomorrowTabDate });

  // Event Handlers
  const handleSearch = (e) => {
    setSearchTodoStr(e?.target?.value || "");
  };

  const handleSelectedEvent = (event = null) => {
    setSelectedEvent(event);
  };

  if (openParam === "settings" || openParam === "sidecar") {
    return <Redirecting />;
  }

  const isToday = dueDateFilter === "today";
  if (isLoading && isToday) {
    return (
      <PageSpinner
        className={isChromeExtension ? "height-200-px" : undefined}
      />
    );
  }

  if (Boolean(selectedEvent) && selectedTab === "execute") {
    return (
      <ExecuteDeepWork
        selectedEvent={{ ...selectedEvent }}
        setSelectedTab={setSelectedTab}
        setWasHomeSelectedWhileFocusing={setWasHomeSelectedWhileFocusing}
        handleSelectedEvent={handleSelectedEvent}
      />
    );
  }

  return (
    <div
      id="main-menu-content"
      className={`flex-display justify-content-center 
      ${isWebPage && "web-main-content-styles"} 
      `}
      style={{ height: isLoading && isToday ? "50px" : undefined }}
    >
      <div style={mainContainerStyles}>
        <TodosContext.Provider
          value={{
            categoriesWithTodos,
            setCategoriesWithTodos,
          }}
        >
          <RefreshCenter fromMenu={true} />
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <TopNav
              from="home"
              shouldShowDWing={Boolean(selectedEvent)}
              doneTodoMsg={doneTodoMsg}
              setSelectedTab={setSelectedTab}
              setClearingDoneTodos={setClearingDoneTodos}
              setWasHomeSelectedWhileFocusing={setWasHomeSelectedWhileFocusing}
            />

            <TodoInput
              from="home"
              categories={categories}
              isUserTyping={isUserTyping}
              setIsUserTyping={setIsUserTyping}
              dueDateFilter={dueDateFilter}
              currentTodoCategoryId={0}
              // saveTodo={saveTodo}
            />

            <DueDateTabs
              currentDate={currentDate}
              dueDateFilter={dueDateFilter}
              meetingsDate={meetingsDate}
              tomorrowTabDate={tomorrowTabDate}
              isAfter4PM={isAfter4PM}
              todosCount={todosCount}
              setMeetingsDate={setMeetingsDate}
              setDueDateFilter={setDueDateFilter}
              setTomorrowTabDate={setTomorrowTabDate}
            />

            {dueDateFilter === "upcoming" && (
              <Input
                size="small"
                className="font-size-14"
                placeholder="Search Todo"
                value={searchTodoStr}
                onChange={handleSearch}
              />
            )}

            <Todos
              isHomeLoading={isLoading}
              dueDateFilter={dueDateFilter}
              searchTodoStr={searchTodoStr}
              tomorrowTabDate={tomorrowTabDate}
              pendingTodosTodayCount={todosCount?.pendingTodosTodayCount}
              dailyCategorySummary={data?.daily_category_summary}
              setTodosCount={setTodosCount}
              setCategories={setCategories}
              setSelectedTab={setSelectedTab}
            />

            {dueDateFilter === "today" &&
              userIntegrationInfo?.calendar_integrations > 0 && (
                <Meetings
                  meetingsDate={meetingsDate}
                  categories={categories}
                  isLoadingDayView={isLoading}
                  otherCalendarEventsData={data?.calendar_events}
                />
              )}
          </div>

          {dueDateFilter === "today" && (
            <DailyStats
              currentDate={currentDate}
              calendarEvents={data?.calendar_events}
            />
          )}
        </TodosContext.Provider>
      </div>
    </div>
  );
};

Home.propTypes = {};

export default Home;
