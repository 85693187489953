import axios from "axios";

const getAllIntegrationsApi = async () => {
  const { data } = await axios.get("/api/v2/integrations");

  return data;
};

const integrateInitApi = async ({ type, code }) => {
  const { data } = await axios.post(`api/v2/integrations/init`, {
    type,
    code,
  });

  return data;
};

const getIntegrationDetailsApi = async ({ queryKey }) => {
  const { data } = await axios.get(`/api/v2/integrations/${queryKey[1]}`);

  return data;
};

const updateIntegrationApi = async ({ id, todo_category_id }) => {
  const { data } = await axios.post(`/api/v2/integrations/${id}/update`, {
    todo_category_id,
  });

  return data;
};

const configureIntegrationApi = async ({
  id,
  type,
  user_selected_projects,
  user_selected_statuses,
  user_selected_done_status,
}) => {
  const { data } = await axios.post(`/api/v2/integrations/${id}/configure`, {
    type,
    user_selected_projects,
    user_selected_statuses,
    user_selected_done_status,
  });

  return data;
};

const syncIntegrationApi = async ({ id }) => {
  const { data } = await axios.post(`api/v2/integrations/${id}/sync`);
  return data;
};

const syncIntegrationsApi = async ({ ids = [] }) => {
  const { data } = await axios.post(
    ids?.length > 0
      ? `/api/v2/integrations/sync_all?ids=${ids?.join(",")}`
      : "/api/v2/integrations/sync_all"
  );
  return data;
};

const deleteIntegrationApi = async ({ id }) => {
  const { data } = await axios.delete(`api/v2/integrations/${id}`);
  return data;
};

export {
  getAllIntegrationsApi,
  integrateInitApi,
  getIntegrationDetailsApi,
  configureIntegrationApi,
  syncIntegrationApi,
  syncIntegrationsApi,
  deleteIntegrationApi,
  updateIntegrationApi,
};
