import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

const { Text } = Typography;

const MultiLineText = ({
  className = "",
  text = "",
  italicize = false,
  startInSameLine = false,
  shouldLastSentenceBeBlockDisplay = false,
  onClick = () => {},
}) => {
  const textArray = text?.split("\n");
  return (
    <>
      {textArray?.map((txt, index) => {
        if (index === 0)
          return (
            <Text
              className={`${
                startInSameLine
                  ? "inline-block"
                  : shouldLastSentenceBeBlockDisplay
                  ? "block-display"
                  : index === textArray?.length - 1
                  ? ""
                  : "block-display"
              } ${className}`}
              onClick={onClick}
            >
              {italicize ? (
                <>
                  &nbsp;<i>{txt}</i>
                </>
              ) : (
                <>{txt}</>
              )}
            </Text>
          );
        else
          return (
            <Text
              className={`${
                shouldLastSentenceBeBlockDisplay
                  ? "block-display"
                  : index === textArray?.length - 1
                  ? ""
                  : "block-display"
              }  ${className}`}
              onClick={onClick}
            >
              {italicize ? <i>{txt}</i> : <>{txt}</>}
            </Text>
          );
      })}
    </>
  );
};
MultiLineText.propTypes = {
  text: PropTypes.string,
};

export default MultiLineText;
