import moment from "moment";
import { dateTimeFormat } from "app/appConstants";

export const getTotalPlannedMeetingTime = (otherCalendarEventsData = []) => {
  let totalMeetingTime = 0;
  if (otherCalendarEventsData) {
    otherCalendarEventsData.forEach((event) => {
      let eventTime = moment(event?.to_time_utc, dateTimeFormat)?.diff(
        moment(event?.from_time_utc, dateTimeFormat),
        "minutes"
      );
      totalMeetingTime += eventTime;
    });
  }
  return totalMeetingTime;
};
