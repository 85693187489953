import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row, Typography, Button, Popover, Dropdown } from "antd";
import {
  AimOutlined,
  EditOutlined,
  PlusOutlined,
  MoreOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import SaveGoal from "./SaveGoal";
import { TodosContext } from "app/home/context";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { dateFormat, displayDateFormatString } from "app/appConstants";
import {
  getNumberOfHoursMinutesString,
  getMinutesFromSeconds,
  capitalizeFirstLetter,
} from "app/appUtils";
import { ReactComponent as TimerIcon } from "assets/timer_small.svg";

import styles from "app/goals/Goals.module.css";

const { Text } = Typography;

const getCompletedTodosCount = (todos) => {
  const completedTodos = todos?.filter((todo) => Boolean(todo?.completed_at));

  return completedTodos?.length;
};

const getAllDaysTime = (todos = []) => {
  let totalTime = 0;
  todos.forEach((todo) => {
    if (todo.day_tracks) {
      todo.day_tracks.forEach((dayTrack) => {
        totalTime += dayTrack?.seconds || 0;
      });
    }
  });

  return totalTime;
};

const GoalHeader = ({
  showAddTodo = true,
  showDetails = true,
  showCompletedTodos = false,
  goal = {},
  refetch = () => {},
  setShowCompletedTodos = () => {},
}) => {
  const history = useHistory();

  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const handleAddTodo = () => {
    setIsAddingTodo(!isAddingTodo);
  };

  const handleEditGoal = () => {
    setIsEditingGoal(!isEditingGoal);
  };

  const handleNavigateToGoals = () => {
    history.push("/goals");
  };

  const handleShowComplete = () => {
    setShowCompletedTodos((showCompletedTodos) => !showCompletedTodos);
  };

  const completedTodosCount = getCompletedTodosCount(goal?.todos);

  const goalMenuItems = [
    {
      key: "edit",
      label: (
        <Row align={"middle"} onClick={handleEditGoal}>
          <EditOutlined className="mr-8" /> <Text> Edit</Text>
        </Row>
      ),
    },
  ];

  if (completedTodosCount > 0) {
    goalMenuItems.push({
      key: "show-completed-todos",
      label: (
        <Row align={"middle"} onClick={handleShowComplete}>
          <CheckCircleOutlined className="mr-8" />{" "}
          <Text>{`${
            showCompletedTodos ? "Hide" : "Show"
          } Completed Todos`}</Text>
        </Row>
      ),
    });
  }

  if (isEditingGoal) {
    return (
      <SaveGoal
        goal={goal}
        showDelete={true}
        refetch={refetch}
        handleCancel={handleEditGoal}
      />
    );
  }

  return (
    <div>
      <Row className="mt-8" align={"middle"} justify="space-between">
        <div className="flex-display align-items-center justify-content-start">
          <AimOutlined
            style={{
              fontSize: "20px",
              color: "#26A26B",
            }}
            onClick={handleNavigateToGoals}
          />
          <div className="ml-8">
            <Text
              className="font-weight-600 cursor-pointer font-size-16"
              onClick={handleNavigateToGoals}
            >
              {capitalizeFirstLetter(goal?.name)}
            </Text>
            {showDetails && (
              <Text className="ml-8 font-size-12">
                by{" "}
                {moment(goal?.target_date, dateFormat)?.format(
                  displayDateFormatString
                )}
              </Text>
            )}
            {showDetails && (
              <Dropdown menu={{ items: goalMenuItems }} trigger={"click"}>
                <MoreOutlined className="ml-4" />
              </Dropdown>
            )}
          </div>
        </div>
      </Row>
      {showDetails && (
        <Row align="middle" className="ml-24">
          <Popover
            content={
              <div>
                {goal?.motivation?.split("\n")?.map((val) => (
                  <div>{val}</div>
                ))}
              </div>
            }
          >
            <Text type="warning">Motivation</Text>
          </Popover>

          <Text
            className={styles.completedTodoCount}
            onClick={handleNavigateToGoals}
          >
            {completedTodosCount} / {goal?.todos?.length} done
          </Text>

          <TimerIcon
            className="text-secondary ml-8"
            style={{
              fontSize: "8px",
              marginTop: "-2px",
              stroke: "#a3a3a3",
              strokeWidth: "0.9",
            }}
          />
          <Text type="secondary" className="ml-4">
            Total -{" "}
            {getNumberOfHoursMinutesString(
              getMinutesFromSeconds(getAllDaysTime(goal?.todos))
            )}
          </Text>

          {/* <Button type="link">See completed</Button> */}
        </Row>
      )}

      {showAddTodo && (
        <>
          {isAddingTodo ? (
            <TodosContext.Provider
              value={{
                categoriesWithTodos,
                setCategoriesWithTodos,
              }}
            >
              <TodoInput
                //Categories not needed
                from="add-goal"
                isGoal={true}
                goalId={goal?.id}
                loader={false}
                isFromArchive={true}
                isForEdit={false}
                setIsEdit={setIsAddingTodo}
                refetchTodos={refetch}
                onMenuCancel={handleAddTodo}
              />
            </TodosContext.Provider>
          ) : (
            <Button
              className="pl-0 font-size-11"
              type="link"
              ghost={true}
              size="small"
              onClick={handleAddTodo}
            >
              <PlusOutlined />
              Add Todo
            </Button>
          )}
        </>
      )}
    </div>
  );
};

GoalHeader.propTypes = {};

export default GoalHeader;
