import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Typography, Row } from "antd";
import MultiSwitch from "react-multi-switch-toggle";
import { CloseOutlined, AimOutlined } from "@ant-design/icons";
// import PropTypes from "prop-types";

import { BoxLoader } from "common/components";
import {
  useAppDate,
  useIsUserRegisteredState,
  useIsAppExpandedState,
} from "store";
import ArchiveTodo from "./components/ArchiveTodo";
import {
  getCompletedTodosApi,
  getTodoCategoriesApi,
} from "app/settings/categories/todoCategoriesApis";
import { dateFormat } from "app/appConstants";
import {
  getIsElectron,
  errorNotification,
  getMainContainerStyling,
} from "app/appUtils";

import styles from "app/home/todos/Todos.module.css";

const { Title, Text } = Typography;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const getGoalCompletedTodos = (category = []) => {
  if (category?.type === "Goal") {
    return category?.todos?.filter((todo) => Boolean(todo?.completed_at));
  } else {
    return [];
  }
};

const Archive = (props) => {
  const isElectron = getIsElectron();
  const history = useHistory();
  const isWebPage = !isChromeExtension && !isElectron;

  const [isUserRegistered] = useIsUserRegisteredState();
  const [appDate] = useAppDate();
  const [isAppExpanded] = useIsAppExpandedState();

  const [selectedTab, setSelectedTab] = useState(0);
  const [fromDate, setFromDate] = useState(
    moment(appDate, dateFormat)?.subtract(7, "days")?.format(dateFormat)
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["getCompletedTodosApi", fromDate, appDate],
    getCompletedTodosApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const { data: categories, isLoading: isLoadingCategories } = useQuery(
    "getTodoCategories",
    getTodoCategoriesApi,
    {
      enabled: isUserRegistered,
      onSuccess: () => {},
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleChange = (activeKey) => {
    setSelectedTab(activeKey);
    switch (activeKey) {
      case 0:
        setFromDate(
          moment(appDate, dateFormat)?.subtract(7, "days")?.format(dateFormat)
        );
        break;
      case 1:
        setFromDate(
          moment(appDate, dateFormat)?.subtract(30, "days")?.format(dateFormat)
        );
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    history.push("/");
  };

  const loader = isLoading || isLoadingCategories;

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  return (
    <div
      id="main-menu-content"
      className={`flex-display ${isWebPage && "web-main-content-styles"} `}
    >
      <div className="ml-8 mr-8 mb-8 pl-16 pr-16" style={mainContainerStyles}>
        <Row className="mt-8" justify="end">
          <CloseOutlined
            className="font-size-16 cursor-pointer"
            onClick={handleClose}
          />
        </Row>

        <div>
          <Row justify="center">
            <Title level={4}>Done Todos</Title>
          </Row>

          <Row
            justify="center"
            className="width-100-percent mt-8 archive-toggle"
          >
            <MultiSwitch
              texts={["7 Days", "30 Days"]}
              selectedSwitch={selectedTab}
              bgColor={"#e3e3e3"}
              onToggleCallback={handleChange}
              fontColor={"black"}
              selectedFontColor={"black"}
              height={"30px"}
              eachSwitchWidth={75}
              fontSize={"16px"}
            ></MultiSwitch>
          </Row>
          {isFetching && !loader && <BoxLoader height="10vh" />}
          {loader ? (
            <BoxLoader />
          ) : (
            <div>
              {data?.map((category, index) => {
                if (
                  (category?.type === "TodoCategory" &&
                    category?.completed_todos?.length === 0) ||
                  (category?.type === "Goal" &&
                    getGoalCompletedTodos(category)?.length === 0)
                ) {
                  return null;
                }

                return (
                  <div className="mt-8">
                    <div className="flex-display align-items-center">
                      {category?.type === "Goal" && (
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#26A26B",
                            marginRight: "8px",
                          }}
                        />
                      )}
                      <Text
                        className={styles.todoCategoryName}
                        style={{ color: category?.color_formula }}
                      >
                        {category?.name?.toUpperCase()}
                      </Text>
                    </div>

                    {category?.type === "TodoCategory"
                      ? category?.completed_todos?.map((todo) => {
                          return (
                            <div>
                              <ArchiveTodo
                                todo={todo}
                                categories={categories}
                                refetchTodos={refetch}
                              />
                            </div>
                          );
                        })
                      : getGoalCompletedTodos(category)?.map((todo) => (
                          <div>
                            <ArchiveTodo
                              todo={todo}
                              categories={categories}
                              refetchTodos={refetch}
                            />
                          </div>
                        ))}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Archive.propTypes = {};

export default Archive;
