import React from "react";
import { Row, Col, Typography } from "antd";

import { getTotalTimeSpentHMStr } from "app/appUtils";

const { Text } = Typography;

const InsightsRow = ({ item = {}, colorMap = {}, percentage }) => {
  const focusTimePercentage =
    (item?.focusTimeSeconds / item?.seconds) * percentage;
  const meetingsTimePercentage =
    (item?.meetingTimeSeconds / item?.seconds) * percentage;

  return (
    <Row>
      <Col
        span={6}
        style={{
          height: "30px",
        }}
        className={`flex-display align-items-center`}
      >
        <Text
          className="font-size-13"
          style={{ color: colorMap[item?.category] || "black" }}
        >
          {item?.category?.toUpperCase()}
        </Text>
      </Col>
      <Col
        span={18}
        className={`flex-display align-items-center`}
        style={{
          height: "30px",
          borderLeft: "1px solid grey",
          paddingLeft: "2px",
          paddingRight: "4px",
        }}
      >
        <div
          style={{ width: "70%" }}
          className={`flex-display align-items-center`}
        >
          <div
            style={{
              height: "15px",
              width: `${Math.round(focusTimePercentage * 100)}%`,
              backgroundColor: "#0000ff",
            }}
          />
          <div
            style={{
              height: "15px",
              width: `${Math.round(meetingsTimePercentage * 100)}%`,
              backgroundColor: "grey",
            }}
          />
        </div>
        <Text className="ml-8" type="secondary">
          {item?.seconds ? getTotalTimeSpentHMStr(item?.seconds) : "0 m"}
        </Text>
      </Col>
    </Row>
  );
};

const InsightsHorizontalChart = ({ colorMap = {}, insights = {} }) => {
  let minTimeRange = 0;
  let maxTimeRange = 0;
  const categoryTimesDataStore = Object.entries(
    insights?.category_times || {}
  )?.map(([itemTitle, timeSpentArr]) => {
    const itemTimeSpent = timeSpentArr[0] + timeSpentArr[1];
    if (itemTimeSpent < minTimeRange) {
      minTimeRange = itemTimeSpent;
    }
    if (itemTimeSpent > maxTimeRange) {
      maxTimeRange = itemTimeSpent;
    }
    return {
      category: itemTitle,
      seconds: itemTimeSpent,
      focusTimeSeconds: timeSpentArr[0],
      meetingTimeSeconds: timeSpentArr[1],
    };
  });

  return (
    <div className="mt-24">
      {categoryTimesDataStore?.map((item) => {
        const percentage =
          (item?.seconds - minTimeRange) / (maxTimeRange - minTimeRange);

        return (
          <InsightsRow
            colorMap={colorMap}
            item={item}
            percentage={categoryTimesDataStore?.length === 1 ? 100 : percentage}
          />
        );
      })}
    </div>
  );
};

InsightsHorizontalChart.propTypes = {};

export default InsightsHorizontalChart;
