import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { InlineSpinner } from "common/components";

const ButtonWithSpinner = ({
  isSpinning = false,
  spinnerColor = "white",
  children,
  spinnerFontSize = 20,
  ...otherProps
}) => {
  return (
    <Button {...otherProps}>
      {isSpinning && (
        <InlineSpinner
          className="mr-8"
          fontSize={spinnerFontSize}
          color={spinnerColor}
        />
      )}
      {children}
    </Button>
  );
};

ButtonWithSpinner.propTypes = {
  isSpinning: PropTypes.bool,
  spinnerColor: PropTypes.string,
};

export default ButtonWithSpinner;
