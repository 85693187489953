import React, { useState } from "react";
// import PropTypes from 'prop-types';
import moment from "moment";

import { Typography, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";

import MinutesInput from "./MinutesInput";
import { formattedShortDate, getTotalTimeSpentHMStr } from "app/appUtils";

const { Text } = Typography;

const SessionRow = ({
  isFromExecution = false,
  todoId = null,
  item = {},
  percentage = 0,
  upsertDayTrack = () => {},
}) => {
  const [timeSpent, setTimeSpent] = useState(item?.seconds);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleUpdateTime = () => {
    upsertDayTrack({
      todoId,
      date: item.date,
      seconds: timeSpent,
    });
    handleEdit();
  };

  const isTodayItem = moment(item?.date, "YYYY-MM-DD")?.isSame(moment(), "day");

  if (item?.seconds < 60) {
    return null;
  }

  return (
    <Row>
      <Col span={8}>
        <Text className="font-size-13" type="secondary" strong={isTodayItem}>
          {formattedShortDate(item?.date)} {`${isTodayItem ? "(Today)" : ""} `}
        </Text>
      </Col>
      <Col
        span={10}
        className={`flex-display align-items-center ${isEdit && "pb-8"}`}
        style={{
          borderLeft: "1px solid grey",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <div
          style={{
            height: "10px",
            width: `${Math.round(percentage * 100)}%`,
            backgroundColor: "#0000ff",
          }}
        />
      </Col>
      <Col span={5} className={isEdit ? "mb-8" : ""}>
        {isEdit ? (
          <MinutesInput
            hideSubmitBtn={true}
            shouldAddOnPressEvent={true}
            value={Math.floor(timeSpent / 60)}
            onChange={(e) => setTimeSpent(e.target.value * 60)}
            onPressEnter={handleUpdateTime}
            onCancel={handleEdit}
          />
        ) : (
          <div>
            <Text type="secondary">
              {getTotalTimeSpentHMStr(item?.seconds)}
            </Text>
            {!isFromExecution && (
              <EditOutlined className="cursor-pointer" onClick={handleEdit} />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

SessionRow.propTypes = {};

export default SessionRow;
