import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip, Row, Col, Button, Typography } from "antd";
import { HomeOutlined, AimOutlined } from "@ant-design/icons";

import AvatarDropDown from "./AvatarDropDown";
import {
  useInsightsDefaultTabState,
  useEnabledFeaturesState,
  useAssistsState,
} from "store";

import assistsIcon from "assets/assists_icon.png";
import growthIcon from "assets/growth.png";

import styles from "./TopNav.module.css";

const { Text } = Typography;

const TopNav = ({
  from = "home",
  shouldShowDWing = false,
  doneTodoMsg = "",
  setSelectedTab = () => {},
  setClearingDoneTodos = () => {},
  setWasHomeSelectedWhileFocusing = () => {},
}) => {
  const history = useHistory();
  const [, setDefaultTab] = useInsightsDefaultTabState();
  const [enabledFeatures] = useEnabledFeaturesState();
  const [assists] = useAssistsState();

  const handleNavigateToHome = () => {
    // setSelectedInputType("home");
    history.push("/");
    setDefaultTab("");
  };

  const handleNavigateToGoals = () => {
    history.push("/goals");
  };

  const handleNavigateToHabits = () => {
    history.push("/habits");
  };

  const handleNavigateToAssists = () => {
    history.push("/assists");
  };

  // eslint-disable-next-line no-unused-vars
  const isHabitsAndAssistsEnabled = Boolean(enabledFeatures?.habits);

  const shouldShowAssists = assists?.length > 0;

  return (
    <Row
      className="mt-4 mb-4 width-100-percent"
      justify="space-between"
      align="middle"
    >
      <Col>
        <div>
          <div
            style={{
              display: " inline-block",
              padding: "4px",
              paddingBottom: "2px",
            }}
            onClick={handleNavigateToHome}
          >
            <HomeOutlined
              className="cursor-pointer"
              style={{
                fontSize: "20px",
                color: from === "home" ? "black" : "#C4C4C4",
              }}
            />
          </div>
          <div
            style={{
              display: " inline-block",
              marginLeft: "8px",
            }}
            onClick={handleNavigateToGoals}
          >
            <Tooltip title="Goals">
              <AimOutlined
                className="cursor-pointer"
                style={{
                  fontSize: "20px",
                  color: from === "goals" ? "black" : "#C4C4C4",
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Col>
      <Col>
        {shouldShowDWing && (
          <Button
            size="small"
            className="font-size-12"
            type="primary"
            onClick={() => {
              setWasHomeSelectedWhileFocusing(false);
              setSelectedTab("execute");
            }}
          >
            Continue Focusing...
          </Button>
        )}
        {doneTodoMsg && <Text strong>{doneTodoMsg}</Text>}
      </Col>
      <Col className="flex-display justify-content-flex-end align-items-center">
        <Tooltip title="Assists">
          {isHabitsAndAssistsEnabled && shouldShowAssists && (
            <img
              className={`cursor-pointer ${styles.assistsIcon}`}
              src={assistsIcon}
              alt="assists"
              onClick={handleNavigateToAssists}
            />
          )}
        </Tooltip>

        {isHabitsAndAssistsEnabled && (
          <Tooltip title="Habits">
            <img
              className="cursor-pointer"
              height={20}
              src={growthIcon}
              alt="habits"
              onClick={handleNavigateToHabits}
            />
          </Tooltip>
        )}
        <AvatarDropDown setClearingDoneTodos={setClearingDoneTodos} />
      </Col>
    </Row>
  );
};

TopNav.propTypes = {};

export default TopNav;
