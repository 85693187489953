import React from "react";
import { useMutation } from "react-query";
import { Typography } from "antd";

import { InlineSpinner } from "common/components";
import { useUserSettings } from "store";
import TimeZoneSelect from "./TimeZoneSelect";
import { successNotification, errorNotification } from "app/appUtils";
import { saveSettingsApi } from "app/settings/settingsApi";

const { Title, Text } = Typography;

const TimeZone = (props) => {
  const [userSettings, setUserSettings] = useUserSettings();

  const { mutate: saveSettings, isLoading: isSavingSettings } = useMutation(
    saveSettingsApi,
    {
      onSuccess: (data) => {
        successNotification("Saved timezone");
        setUserSettings({
          timezone: data?.timezone,
        });
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleUpdateSettings = (val) => {
    saveSettings({
      setting: {
        timezone: val,
      },
    });
  };

  return (
    <div>
      <Title level={3}>Timezone</Title>

      <Text>Your timezone is set to</Text>

      <TimeZoneSelect
        className="width-250-px ml-8"
        value={userSettings?.timezone}
        onChange={handleUpdateSettings}
      />

      {isSavingSettings && <InlineSpinner />}
    </div>
  );
};

TimeZone.propTypes = {};

export default TimeZone;
