import React from "react";
// import PropTypes from 'prop-types';
import { Row, Typography } from "antd";

import { ReactComponent as AssistResolved } from "assets/assist_resolved.svg";

const { Text } = Typography;

const ResolvedView = (props) => {
  return (
    <div>
      <Row justify={"center"} align="middle" className="flex-direction-column">
        <AssistResolved />
        <Text>Done</Text>
      </Row>
    </div>
  );
};

ResolvedView.propTypes = {};

export default ResolvedView;
