import React from "react";
// import PropTypes from 'prop-types';
import { Typography, Layout, Card } from "antd";

import { InlineSpinner } from "common/components";
import logo from "assets/logo.svg";

const { Text, Link } = Typography;

const Redirecting = (props) => {
  return (
    <div>
      <Layout className="full-height">
        <Card className="info-page-card">
          <div className="info-page-div">
            <Link href="https://deepwork.easy.xyz/">
              <img className="mt-24 block-display" src={logo} alt="logo" />
            </Link>
            <div>
              <Text>Redirecting to web view</Text>
              <InlineSpinner className="ml-8" fontSize={24} />
            </div>
          </div>
        </Card>
      </Layout>
    </div>
  );
};

Redirecting.propTypes = {};

export default Redirecting;
