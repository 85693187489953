import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { Typography, Row, Button, Tooltip, DatePicker } from "antd";
import {
  HomeOutlined,
  InfoCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { useSaveTodo } from "common/hooks";
import { TodosContext } from "app/home/context";
import {
  useAppDate,
  useUserSettings,
  useOnboardedState,
  useIsAppPinnedState,
  useIsUserRegisteredState,
} from "store";
import {
  dateFormat,
  dateTimeOnly,
  shortDateFormatWithWeekDay,
  dateTimeFormatZ,
  updateIsPinned,
} from "app/appConstants";
import LinkedTodos from "./LinkedTodos";
import DWSessionTrackerModal from "app/home/todos/categories-list/todo/dw-sessions-tracker-modal/DWSessionTrackerModal";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { getTodoCategoriesApi } from "app/settings/categories/todoCategoriesApis";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import {
  getTotalTimeSpentHMStr,
  errorNotification,
  sendIPCUpdateEvent,
} from "app/appUtils";
import { finerAdjustEndTime } from "../utils";
import { ReactComponent as Add15Mins } from "assets/add_time_15_mins.svg";
import { ReactComponent as Add30Mins } from "assets/add_time_30_mins.svg";
import { ReactComponent as Add60Mins } from "assets/add_time_60_mins.svg";
import { ReactComponent as AddTodoIcon } from "assets/add_todo_icon.svg";

const { Text, Title } = Typography;

const ExecutionTimer = ({
  isPinned = false,
  endDWBtnClassName = "",
  selectedEvent = {},
  handleUpdateTimeSlot = () => {},
  setWasHomeSelectedWhileFocusing = () => {},
  setSelectedTab = () => {},
}) => {
  const selectedEventToTimeRef = useRef();

  const queryClient = useQueryClient();
  const [appDate] = useAppDate();
  const [onboarded] = useOnboardedState();
  const [, setIsPinned] = useIsAppPinnedState();
  const [isUserRegistered] = useIsUserRegisteredState();
  const [settings] = useUserSettings();
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const [isDWSessionTrackerVisible, setIsDWSessionTrackerVisible] =
    useState(false);
  const [isDoDateEdit, setIsDoDateEdit] = useState(false);
  const [showAddTodoForm, setShowAddTodoForm] = useState(false);
  const [remainingMins, setRemainingMins] = useState(
    moment(selectedEvent?.to_time_utc, dateTimeFormatZ)?.diff(
      moment(),
      "seconds"
    )
  );
  const [todoIdsDone, setTodoIdsDone] = useState([]);

  const timeZone = settings?.timezone;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingMins(
        moment(selectedEventToTimeRef.current, dateTimeFormatZ)?.diff(
          moment(),
          "seconds"
        )
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    mutate: createDWNowTimeSlot,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(saveTimeSlotApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getDayView");

      if (!onboarded) {
        queryClient.invalidateQueries("me");
      }
    },
    onError: (err) => {},
  });

  const { data: categories } = useQuery(
    "getTodoCategories",
    getTodoCategoriesApi,
    {
      enabled: isUserRegistered,
      onSuccess: () => {},
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      if (variables?.extraParams?.resetValues) {
        variables.extraParams.resetValues();
      }
      if (!showAddTodoForm) {
        queryClient.invalidateQueries("getDayView");
        // sendIPCUpdateEvent(ipcUpdateEvent, {});
      }
      if (variables?.extraParams?.createDWNow) {
        createDWNowTimeSlot({
          time_slot: {
            title: data?.title,
            quick_plan: data?.description,
            from_time: moment()?.utc()?.format(dateTimeFormatZ),
            to_time: moment()
              ?.add(30, "minutes")
              ?.utc()
              ?.format(dateTimeFormatZ),
            todo_id: data?.id,
            todo_ids: [data?.id],
            is_active: true,
          },
        });

        const isChromeExtension =
          process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
        if (isChromeExtension) {
          // eslint-disable-next-line no-undef
          chrome.runtime.sendMessage({
            toTime: moment()?.add(30, "minutes").format(dateTimeOnly),
          });
        }
      }
      setShowAddTodoForm(false);
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const selectedEventToTime = selectedEvent?.to_time_utc;
  useEffect(() => {
    selectedEventToTimeRef.current = selectedEventToTime;
  }, [selectedEventToTime]);

  const handleEndDeepWork = () => {
    const timeSlot = { ...selectedEvent };
    timeSlot.id = timeSlot?.id === "temp-id" ? selectedEvent?.id : timeSlot?.id;

    const newToTime = moment()?.toDate();

    const adjustedNewToTime = finerAdjustEndTime(
      selectedEvent?.start,
      newToTime
    );

    const updatedTimeSlot = {
      ...timeSlot,
      end: adjustedNewToTime,
      is_active: false,
      todo_ids_done: todoIdsDone,
    };

    delete updatedTimeSlot["session_time_secs"];
    delete updatedTimeSlot["daily_plan_id"];
    delete updatedTimeSlot["from_time_utc"];
    delete updatedTimeSlot["to_time_utc"];
    delete updatedTimeSlot["todos"];
    delete updatedTimeSlot["meet_link"];
    delete updatedTimeSlot["attendees"];
    delete updatedTimeSlot["start"];
    delete updatedTimeSlot["end"];

    if (isPinned) {
      setIsPinned(false);
      sendIPCUpdateEvent(updateIsPinned);
    }

    handleUpdateTimeSlot({
      time_slot: {
        ...updatedTimeSlot,
        to_time: adjustedNewToTime?.utc()?.format(dateTimeFormatZ),
        from_time: moment(timeSlot?.from_time_utc, dateTimeFormatZ)
          ?.utc()
          ?.format(dateTimeFormatZ),
      },
      isPastEvent: true,
      extraParams: {
        endDW: true,
      },
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: null,
      });
    }
  };

  const handleIsDWSessionTrackerVisible = () => {
    if (disabled) {
      return;
    }
    setIsDWSessionTrackerVisible(!isDWSessionTrackerVisible);
  };

  const handleShowAddTodoForm = () => {
    if (disabled) {
      return;
    }
    setShowAddTodoForm((prevState) => !prevState);
  };

  const handleDoDateEdit = () => {
    if (disabled) {
      return;
    }
    setIsDoDateEdit(!isDoDateEdit);
  };

  const handleAddTime = (val = 0) => {
    if (disabled) {
      return;
    }

    const timeSlot = { ...selectedEvent };

    const newToTime = moment(selectedEvent?.to_time_utc, dateTimeFormatZ)?.add(
      val,
      "minutes"
    );

    handleUpdateTimeSlot({
      time_slot: {
        ...timeSlot,
        from_time: moment(timeSlot?.from_time_utc, dateTimeFormatZ)
          ?.utc()
          ?.format(dateTimeFormatZ),
        end: newToTime?.tz(timeZone)?.toDate(),
        to_time: newToTime?.utc()?.format(dateTimeFormatZ),
      },
      isPastEvent: true,
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: newToTime?.format(dateTimeOnly),
      });
    }
  };

  const handleDateChange = (val) => {
    saveTodo({
      todo: { ...selectedEventTodo, due_date: val },
      extraParams: {
        resetValues: () => {
          handleDoDateEdit();
        },
      },
    });
  };

  const remainingTimeStr = getTotalTimeSpentHMStr(
    remainingMins > 0 ? remainingMins : -remainingMins
  );

  const selectedEventTodos = selectedEvent?.todos || [];
  const selectedEventTodo = selectedEventTodos[0];
  const disabled = selectedEvent?.isTempSession || false;

  return (
    <div>
      {isDWSessionTrackerVisible && (
        <DWSessionTrackerModal
          isFromExecution={true}
          todoId={selectedEvent?.todos[0]?.id}
          visible={isDWSessionTrackerVisible}
          dayTracks={selectedEvent?.todos[0]?.day_tracks || []}
          onCancel={handleIsDWSessionTrackerVisible}
        />
      )}
      <Row
        className="mt-8 flex-direction-column"
        justify="center"
        align="middle"
      >
        <Title
          level={4}
          type={remainingMins <= 0 ? "danger" : ""}
          className={remainingMins > 0 ? "text-primary" : ""}
          style={{ fontSize: "20px" }}
        >
          {remainingTimeStr} {remainingMins > 0 ? "left" : "overshot"}
        </Title>

        <Row className="mt-8" align="middle">
          <Tooltip title="See your todos">
            <div
              className="cursor-pointer mb-8 mr-8"
              onClick={() => {
                if (disabled) {
                  return;
                }
                setWasHomeSelectedWhileFocusing(true);
                setSelectedTab("todos");
              }}
              style={{
                display: "inline",
                cursor: disabled ? "not-allowed" : undefined,
              }}
            >
              <HomeOutlined
                disabled={disabled}
                style={{
                  fontSize: "24px",
                  pointerEvents: "none",
                  color: "#A3A3A3",
                  marginTop: "-4px",
                }}
              />
            </div>
          </Tooltip>

          <Tooltip title="Add Todo">
            <div
              className="cursor-pointer mb-8"
              onClick={handleShowAddTodoForm}
              style={{
                display: "inline",
                cursor: disabled ? "not-allowed" : undefined,
              }}
            >
              <AddTodoIcon
                style={{
                  cursor: disabled ? "not-allowed" : undefined,
                  pointerEvents: "none",
                }}
              />
            </div>
          </Tooltip>

          <div
            className="cursor-pointer mb-8 ml-8"
            onClick={handleIsDWSessionTrackerVisible}
            style={{
              display: "inline",
              cursor: disabled ? "not-allowed" : undefined,
            }}
          >
            <InfoCircleOutlined
              style={{
                cursor: disabled ? "not-allowed" : undefined,
                pointerEvents: "none",
                fontSize: "24px",
                color: "#A3A3A3",
                marginTop: "-4px",
              }}
            />
          </div>

          <div className="ml-8" style={{ marginTop: "8px", display: "inline" }}>
            <div>
              <Tooltip title="Add 15 mins">
                <div
                  className="cursor-pointer mr-8"
                  onClick={() => {
                    handleAddTime(15);
                  }}
                  style={{
                    cursor: disabled ? "not-allowed" : undefined,
                    display: "inline",
                  }}
                >
                  <Add15Mins
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Add 30 mins">
                <div
                  className="cursor-pointer mr-8"
                  onClick={() => {
                    handleAddTime(30);
                  }}
                  style={{
                    cursor: disabled ? "not-allowed" : undefined,
                    display: "inline",
                  }}
                >
                  <Add30Mins
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Add 60 mins">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleAddTime(60);
                  }}
                  style={{
                    cursor: disabled ? "not-allowed" : undefined,
                    display: "inline",
                  }}
                >
                  <Add60Mins style={{ pointerEvents: "none" }} />
                </div>
              </Tooltip>
            </div>
            <div className="flex-display justify-content-center">
              <Text type="secondary" className="font-size-13">
                Add Time
              </Text>
            </div>
          </div>
        </Row>

        {showAddTodoForm && (
          <TodosContext.Provider
            value={{
              categoriesWithTodos,
              setCategoriesWithTodos,
            }}
          >
            <TodoInput
              categories={categories}
              className="width-100-percent-imp"
              from="execution"
              loader={isSavingTodo}
              todo={{
                due_date: moment(appDate, dateFormat)?.format(dateFormat),
              }}
              isSavingTodo={isSavingTodo}
              saveTodo={saveTodo}
              onMenuCancel={handleShowAddTodoForm}
            />
          </TodosContext.Provider>
        )}

        <LinkedTodos
          todoIdsDone={todoIdsDone}
          setTodoIdsDone={setTodoIdsDone}
          selectedEvent={selectedEvent}
          isSavingTodo={isSavingTodo}
          saveTodo={saveTodo}
          handleUpdateTimeSlot={handleUpdateTimeSlot}
        />

        <Row className="mt-8" align="middle">
          Do Date:
          {isDoDateEdit ? (
            <Row className="ml-8" justify={"center"} align="middle">
              <DatePicker
                size="small"
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
                value={
                  selectedEventTodo?.due_date
                    ? moment(selectedEventTodo?.due_date, dateFormat)
                    : null
                }
                onChange={handleDateChange}
                style={{ color: "#003399" }}
                placeholder="Re-Schedule"
              />
              <CloseCircleOutlined
                className="ml-8 cursor-pointer"
                onClick={handleDoDateEdit}
              />
            </Row>
          ) : (
            <Row className="ml-8" align="middle">
              {moment(selectedEventTodo?.due_date, dateFormat)?.format(
                shortDateFormatWithWeekDay
              )}{" "}
              <EditOutlined
                className={`ml-8 ${
                  disabled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={handleDoDateEdit}
              />
            </Row>
          )}
        </Row>

        <Button
          disabled={disabled}
          className={`mt-32 ${endDWBtnClassName}`}
          type="primary"
          size="small"
          ghost={true}
          onClick={handleEndDeepWork}
        >
          End Focus Session
        </Button>
      </Row>{" "}
    </div>
  );
};

ExecutionTimer.propTypes = {
  totalTimeSpentStr: PropTypes.string,
};

export default ExecutionTimer;
