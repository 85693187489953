import { useQuery } from "react-query";

import { getTodosWithCategoriesApi } from "app/settings/categories/todoCategoriesApis";
import { errorNotification } from "app/appUtils";

const useGetTodos = ({ onSuccess = () => {} }) => {
  const {
    data,
    isLoading: isLoadingTodos,
    isFetching,
    refetch: refetchTodos,
  } = useQuery("getTodos", getTodosWithCategoriesApi, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  return { data, isLoading: isLoadingTodos, isFetching, refetch: refetchTodos };
};

export default useGetTodos;
