import React from "react";
import { Row, Col, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import { getTotalTimeSpentHMStr } from "app/appUtils";
import { ReactComponent as TimerIcon } from "assets/timer_big.svg";

import styles from "app/insights/Insights.module.css";

const { Text, Title } = Typography;

const InsightsTimeBreakdown = ({ data }) => {
  return (
    <div className="mt-16">
      <Row justify="center">
        <Text className="block-display mt-8">Your time breakdown</Text>
      </Row>
      <Row justify="center" className="mt-8">
        <Col span={10} className={styles.timeBreakdown}>
          <div className="flex-display align-items-center">
            <TimerIcon className="text-primary" />
            <div className="ml-8">
              <Title level={3} style={{ fontSize: "20px" }}>
                {data?.time_breakdown?.focus > 0
                  ? getTotalTimeSpentHMStr(data?.time_breakdown?.focus)
                  : "0m"}
              </Title>
              <Text>FOCUS</Text>
            </div>
          </div>
        </Col>
        <Col span={10} offset={1} className={styles.timeBreakdown}>
          <div className="flex-display align-items-center">
            <CalendarOutlined style={{ fontSize: "28px", color: "#626262" }} />
            <div className="ml-8">
              <Title level={3} style={{ fontSize: "20px" }}>
                {data?.time_breakdown?.meetings > 0
                  ? getTotalTimeSpentHMStr(data?.time_breakdown?.meetings)
                  : "0m"}
              </Title>
              <Text>MEETINGS</Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

InsightsTimeBreakdown.propTypes = {};

export default InsightsTimeBreakdown;
