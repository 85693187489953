import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { CalendarOutlined, CheckOutlined } from "@ant-design/icons";

import { EllipseText } from "common/components";
import { useInsightsDefaultTabState } from "store";
import { getTotalTimeSpentHMStr } from "app/appUtils";

import { ReactComponent as TodoCircleIcon } from "assets/todoCircle.svg";

const { Text } = Typography;

const Details = ({ colorMap = {}, data = {} }) => {
  const [defaultTab] = useInsightsDefaultTabState();

  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    if (defaultTab) {
      setSelectedTab(defaultTab);
    } else {
      const firstKey =
        Object.keys(data)?.length > 0 ? Object.keys(data)[0] : "";
      setSelectedTab(firstKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  const selectedItems = data[selectedTab] || [];

  const sortedSelectedItems = selectedItems?.sort(
    (a, b) => b?.seconds - a?.seconds
  );

  return (
    <div>
      <div
        className="mt-8 flex-display insights-details-tabs"
        style={{ flexWrap: "wrap" }}
      >
        {Object.entries(data || {})?.map(([title, items = []], index) => {
          return (
            <div
              className={`px-8 py-4 insights-details-tab ${
                selectedTab === title ? "insights-details-tab-active" : ""
              }`}
              style={{
                color: colorMap[title] || "black",
                lineHeight: "1.8",
              }}
              onClick={() => setSelectedTab(title)}
            >
              {title?.toUpperCase()}
            </div>
          );
        })}
      </div>

      <div>
        {sortedSelectedItems?.map((item) => (
          <div>
            {item?.type === "TodoDayTrack" ? (
              <div className="flex-display align-items-center">
                {Boolean(item?.completed_at) ? (
                  <CheckOutlined
                    style={{ color: "#C4C4C4", fontSize: "14px" }}
                  />
                ) : (
                  <TodoCircleIcon style={{ width: "14px", height: "14px" }} />
                )}
                <EllipseText
                  className="ml-8 font-size-13 flex-grow-2 cursor-pointe line-height-1px"
                  text={item?.title}
                  characterLimit={35}
                />
                <Text>
                  {item?.seconds > 0
                    ? getTotalTimeSpentHMStr(item?.seconds)
                    : "0m"}
                </Text>
              </div>
            ) : (
              <div className="flex-display">
                <CalendarOutlined
                  style={{ color: "#C4C4C4", fontSize: "14px" }}
                />

                <EllipseText
                  className="ml-8 font-size-13 flex-grow-2"
                  text={item?.title}
                  characterLimit={35}
                />
                <Text>{getTotalTimeSpentHMStr(item?.seconds)}</Text>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const InsightsDetails = ({ colorMap, insights }) => {
  const totalTime =
    (insights?.time_breakdown?.focus || 0) +
    (insights?.time_breakdown?.meetings || 0);

  return (
    <div>
      {totalTime > 0 && (
        <div>
          <Details colorMap={colorMap} data={insights?.grouped_time_stats} />
        </div>
      )}
    </div>
  );
};

InsightsDetails.propTypes = {};

export default InsightsDetails;
