import React from "react";
import { Layout, Card, Typography } from "antd";

import logo from "assets/logo.svg";
import ElectronIntegrationsRedirectButton from "../common/ElectronIntegrationsRedirectButton";

const { Title, Link } = Typography;

const SuccessfullyIntegratedRedirectToElectron = (props) => {
  const integrationType = localStorage.getItem("integrationType");
  const integrationId = localStorage.getItem("integrationId");

  return (
    <Layout className="full-height">
      <Card className="info-page-card">
        <div className="info-page-div">
          <Link href="https://deepwork.easy.xyz/">
            <img className="block-display mb-16" src={logo} alt="logo" />
          </Link>

          <Title level={4} className="block-display mb-16">
            Integration successful
          </Title>
          <ElectronIntegrationsRedirectButton
            integrationId={integrationId}
            integrationType={integrationType}
            redirectParam="cal_integrations"
          />
        </div>
      </Card>
    </Layout>
  );
};

SuccessfullyIntegratedRedirectToElectron.propTypes = {};

export default SuccessfullyIntegratedRedirectToElectron;
