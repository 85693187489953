import axios from "axios";

const getSettingsApi = async () => {
  const { data } = await axios.get("/api/v1/settings");
  return data;
};

const saveSettingsApi = async ({ setting }) => {
  const { data } = await axios.post("/api/v1/settings", {
    setting,
  });

  return data;
};

const saveHabitsConfigApi = async ({ atomic_habit }) => {
  const { data } = await axios.post("/api/v1/atomic_habits", {
    atomic_habit,
  });

  return data;
};

export { getSettingsApi, saveSettingsApi, saveHabitsConfigApi };
