import moment from "moment";

import { dateFormat, dateTimeFormat } from "app/appConstants";

export const handleGetTodosSuccess = ({
  appDate = null,
  data = {},
  tomorrowTabDate = "",
  setAllTodos = () => {},
  setTodosCount = () => {},
  setCategories = () => {},
  setCategoriesWithTodos = () => {},
}) => {
  const completeTodoSet = [];
  data.forEach((category) => {
    if (category && category.active_todos) {
      category.active_todos.forEach((todo) => {
        completeTodoSet.push(todo);
      });
    }
  });
  setAllTodos(completeTodoSet);

  const todosDueToday =
    completeTodoSet?.filter((todo) =>
      moment(todo?.due_date, dateFormat)?.isSameOrBefore(
        moment(appDate, dateFormat)?.endOf("day")
      )
    ) || [];
  const pendingTodosTodayCount =
    todosDueToday?.filter((todo) => !Boolean(todo?.completed_at))?.length || 0;

  const todayTodosCount =
    completeTodoSet?.filter((todo) =>
      moment(todo?.due_date, dateTimeFormat)?.isSameOrBefore(
        moment()?.endOf("day")
      )
    )?.length || 0;

  const allTodosCount =
    completeTodoSet?.filter((todo) => !Boolean(todo?.completed_at))?.length ||
    0;

  const tomorrowTodosCount =
    completeTodoSet?.filter((todo) => {
      return moment(todo?.due_date, dateTimeFormat)?.isSame(
        moment(tomorrowTabDate, dateFormat)
      );
    })?.length || 0;

  setTodosCount((todos) => ({
    ...todos,
    totalCount: todosDueToday?.length || 0,
    pendingTodosTodayCount,
    todayTodosCount,
    allTodosCount,
    tomorrowTodosCount,
  }));

  const categories = data?.map((category) => {
    return {
      id: category?.id,
      name: category?.name,
      type: category?.type,
      order: category?.order,
      color_formula: category?.color_formula,
      color_name: category?.color_name,
    };
  });

  setCategories(categories);
  setCategoriesWithTodos(data);
};

export const bulkMoveTodosDueDateOnSuccess = ({ queryClient }) => {
  queryClient.invalidateQueries("getTodos");
};

export const saveTodoOnSuccess = ({
  queryClient = {},
  data = {},
  variables = {},
  handleCreateDWNow = () => {},
  refetchTodos = () => {},
}) => {
  refetchTodos();
  if (variables?.extraParams?.resetValues) {
    variables.extraParams.resetValues();
  }
  if (variables?.extraParams?.createDWNow) {
    handleCreateDWNow(null, {
      todoId: data?.id,
      todoTitle: data?.title,
      todoDesc: data?.description,
    });
  }
  queryClient.invalidateQueries(["getDailyStats"]);
};

export const createDWNowOnSuccess = ({
  onboarded,
  data = {},
  queryClient = {},
}) => {
  setTimeout(() => {
    queryClient.invalidateQueries("getDayView");
  }, 500);

  if (!onboarded) {
    queryClient.invalidateQueries("me");
  }
};

export const clearDoneTodosOnSuccess = ({ queryClient = {} }) => {
  queryClient.invalidateQueries("getTodos");
};
