import React from "react";
// import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Typography } from "antd";

import SaveGoal from "./SaveGoal";
import TopNav from "app/top-nav/TopNav";
import { getIsElectron } from "app/appUtils";

import styles from "app/goals/Goals.module.css";

const { Text, Title } = Typography;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const ConvertToGoal = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams?.get("name");
  const targetDate = urlParams?.get("target_date");
  const history = useHistory();

  const isElectron = getIsElectron();

  const isWebPage = !isChromeExtension && !isElectron;

  return (
    <div className={`flex-display ${isWebPage && "web-main-content-styles"} `}>
      <div
        className="ml-8 mr-8 mb-8"
        id="insights-content"
        style={{
          width: "360px",
          border: isWebPage ? "1px solid grey" : undefined,
          backgroundColor: isWebPage ? "white" : undefined,
          maxHeight: isWebPage ? "90vh" : undefined,
          overflowY: isWebPage ? "auto" : undefined,
          paddingBottom: isWebPage ? "8px" : undefined,
          paddingTop: "0px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <div>
          <TopNav from="goals" />

          <Title level={3} className="mt-8 mb-8">
            Your Goals
          </Title>

          <div className={`${styles.contentContainer} mt-16`}>
            <Text>Convert your Task to a Goal</Text>
          </div>

          <div className="mt-16">
            <SaveGoal
              saveTxt="Add Goal"
              showDesc={false}
              goal={{ name, target_date: targetDate }}
              onSave={() => {
                history.push("/goals");
              }}
              handleCancel={() => {
                history.push("/");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConvertToGoal.propTypes = {};

export default ConvertToGoal;
