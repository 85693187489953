import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Modal, Radio, Space, Row, Button, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ButtonWithSpinner } from "common/components";
import { deleteTodoApi, deleteFutureTodosApi } from "app/home/todos/todosApis";
import { errorNotification } from "app/appUtils";

const { Text } = Typography;

const DeleteTodoModal = ({
  visible = false,
  todo = {},
  refetchTodos = () => {},
  onChange = () => {},
}) => {
  const [selectedState, setSelectedState] = useState("this-todo");

  const { mutate: deleteTodo, isLoading: isDeletingTodo } = useMutation(
    deleteTodoApi,
    {
      onSuccess: () => {
        refetchTodos();

        onChange();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const { mutate: deleteFutureTodos, isLoading: isDeletingFutureTodo } =
    useMutation(deleteFutureTodosApi, {
      onSuccess: () => {
        refetchTodos();

        onChange();
      },
      onError: (error) => {
        errorNotification(error);
      },
    });

  const handleChange = (val) => {
    setSelectedState(val);
  };

  const handleDelete = () => {
    if (selectedState === "this-todo") {
      deleteTodo({ todoId: todo?.id });
    } else {
      deleteFutureTodos({ todoId: todo?.id });
    }
  };

  const isRepeating = Boolean(todo?.recurring);

  return (
    <Modal footer={null} visible={visible} onCancel={onChange}>
      <Text strong>
        <ExclamationCircleOutlined className="mr-8 text-danger" col />
        Are you sure, you want to delete?
      </Text>

      {isRepeating && (
        <div className="mt-16">
          <Space direction="vertical">
            <Radio
              checked={selectedState === "this-todo"}
              onClick={() => handleChange("this-todo")}
            >
              This todo
            </Radio>
            <Radio
              checked={selectedState === "this-and-future-todo"}
              onClick={() => handleChange("this-and-future-todo")}
            >
              This and future todos
            </Radio>
          </Space>
        </div>
      )}

      <div className="mt-16">
        <Row justify="end">
          <Button onClick={onChange}>Cancel</Button>

          <ButtonWithSpinner
            isSpinning={isDeletingFutureTodo || isDeletingTodo}
            disabled={isDeletingFutureTodo || isDeletingTodo}
            className="ml-8"
            type="danger"
            ghost={true}
            onClick={handleDelete}
          >
            Ok
          </ButtonWithSpinner>
        </Row>
      </div>
    </Modal>
  );
};

DeleteTodoModal.propTypes = {};

export default DeleteTodoModal;
