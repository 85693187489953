import React, { Fragment, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

import { useCurrentUser } from "store";
import {
  AuthGuard,
  PageNotFound,
  Forbidden403,
  OnboardGuard,
} from "common/components";
import Auth from "app/auth/Auth";
import Home from "app/home/Home";
import ElectronAuth from "app/auth/ElectronAuth";
import Settings from "app/settings/Settings";
import SideCar from "app/sidecar/SideCar";
import Insights from "app/insights/Insights";
import SharableInsights from "app/insights/components/SharableInsights";
import Usage from "app/usage/Usage";
import Habits from "app/habits/Habits";
import Archive from "app/archive/Archive";
import Feedback from "app/feedback/Feedback";
import Goals from "app/goals/Goals";
import ConvertToGoal from "app/goals/components/ConvertToGoal";
import Assists from "app/assists/Assists";
import integrationRoutes from "app/settings/integrations/integrations.routes.js";

function ErrorFallback({ error }) {
  const history = useHistory();
  const location = useLocation();
  const [currentUser] = useCurrentUser();

  const handleReload = () => {
    history.push("/");
  };

  useEffect(() => {
    Sentry.captureMessage(
      `${currentUser?.displayName} (${currentUser?.email}) - Error from FE - ${error?.message}
      This error occurred at path:${location?.pathname} search: ${location?.search}`,
      {
        extra: error,
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={handleReload}>Try again</button>
    </div>
  );
}

const routesConfig = [
  {
    exact: true,
    path: "/",
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: Home,
  },
  {
    exact: true,
    path: "/auth",
    component: Auth,
  },
  {
    exact: true,
    path: "/electron-auth",
    component: ElectronAuth,
  },
  {
    exact: true,
    path: "/access-denied",
    component: Forbidden403,
  },
  {
    path: "/integrations",
    // guard: AuthGuard,
    // onboard: OnboardGuard,
    routes: [...integrationRoutes],
  },
  {
    exact: true,
    path: "/usage",
    component: Usage,
    guard: AuthGuard,
    onboard: OnboardGuard,
  },
  {
    path: "/settings",
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: Settings,
  },
  {
    path: "/sidecar",
    // guard: AuthGuard,
    onboard: OnboardGuard,
    component: SideCar,
  },
  {
    path: "/insights",
    // guard: AuthGuard,
    onboard: OnboardGuard,
    component: Insights,
  },
  {
    path: "/time",
    // guard: AuthGuard,
    onboard: OnboardGuard,
    component: SharableInsights,
  },
  {
    path: "/habits",
    // guard: AuthGuard,
    onboard: OnboardGuard,
    component: Habits,
  },
  {
    path: "/convert-to-goal",
    onboard: OnboardGuard,
    component: ConvertToGoal,
  },
  {
    path: "/goals",
    // guard: AuthGuard,
    onboard: OnboardGuard,
    component: Goals,
  },
  {
    path: "/archive",
    onboard: OnboardGuard,
    component: Archive,
  },
  {
    path: "/feedback",
    onboard: OnboardGuard,
    component: Feedback,
  },
  {
    path: "/assists",
    onboard: OnboardGuard,
    component: Assists,
  },
];

const renderRoutes = (routes, isAdmin) =>
  routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;
        let Guard = Fragment;
        let Onboard = Fragment;
        let isAdminRoute = false;

        if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
          Guard = route.guard || Fragment;
          Onboard = route.onboard || Fragment;
          isAdminRoute = route.isAdminRoute || false;
        } else {
          Guard = route.guard || Fragment;
          Onboard = route.onboard || Fragment;
        }

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Onboard>
                  <Layout>
                    {isAdminRoute && !isAdmin ? (
                      <>{route.routes ? <Forbidden403 /> : <Forbidden403 />}</>
                    ) : (
                      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Component {...props} />
                          </ErrorBoundary>
                        )}
                      </Sentry.ErrorBoundary>
                    )}
                  </Layout>
                </Onboard>
              </Guard>
            )}
          />
        );
      })}
      <Route
        render={() => {
          return <PageNotFound />;
        }}
      />
    </Switch>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
