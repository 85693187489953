import { Server } from "miragejs";
import { firebaseApiMock } from "./appApi.mock";
import {
  getPlanDetailsYesterday,
  getPlanDetails,
  getPlanDetailsTomorrow,
  createDailyPlan,
} from "./dailyPlan.mock";
import { getHabitsData } from "./habits.mock";

function makeServer({ environment = "development" }) {
  let server = new Server({
    environment,

    routes() {
      this.urlPrefix = "http://localhost:8000";
      this.namespace = "/api/v1";

      this.get(
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=AIzaSyD0pasoSaTsjxNbaQuSsTKTuaA9kDKYc-8I4",
        firebaseApiMock
      );

      this.get("/daily_plans/date/01-08-2021", getPlanDetailsYesterday);
      this.get("/daily_plans/date/02-08-2021", getPlanDetails);
      this.get("/daily_plans/date/03-08-2021", getPlanDetailsTomorrow);
      this.post("/daily_plans", createDailyPlan);
      this.get("/habits/7", getHabitsData);
    },
  });

  return server;
}

export { makeServer };
