import React, { useState } from "react";
import { useMutation } from "react-query";
import { Row, Col, Typography, Checkbox, Button } from "antd";

import { useAppDate } from "store";
import AssistHeader from "./AssistHeader";
import ResolvedView from "./ResolvedView";
import DoneState from "./DoneState";
import { resolveAssistsApi } from "app/assists/assistsApi";
import { errorNotification } from "app/appUtils";
import { clearAssistApi } from "app/assists/assistsApi";
import { getCurrentTodo, getIsTodoInFuture } from "../assistsUtils";

import styles from "app/assists/Assists.module.css";

const { Text } = Typography;

const PriorityTodo = ({ todoId, todoText, setPriorityTodoIds = () => {} }) => {
  const handleCheck = (checked) => {
    if (checked) {
      setPriorityTodoIds((todoIds) => [...todoIds, todoId]);
    }
  };

  return (
    <Row justify="space-between">
      <Col span={22}>
        <Text>{todoText}</Text>
      </Col>
      <Col span={2} className="flex-display align-items-center">
        <Checkbox onChange={handleCheck} />
      </Col>
    </Row>
  );
};

const SetPriorityAssist = ({
  data = {},
  allTodos = [],
  firstTodoCategory = "",
  icon = null,
  refetch = () => {},
}) => {
  const [appDate] = useAppDate();

  const [priorityTodoIds, setPriorityTodoIds] = useState([]);
  const [isStateExpanded, setIsStateExpanded] = useState(
    firstTodoCategory === "priority"
  );
  const [isAssistDone, setIsAssistDone] = useState(false);

  const {
    mutate: resolveAssists,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(resolveAssistsApi, {
    onSuccess: () => {
      refetch();
      setIsAssistDone(true);
      clearAssist({ assistId: data?.id });
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const { mutate: clearAssist, isLoading: isClearingAssist } = useMutation(
    clearAssistApi,
    {
      onSuccess: () => {
        setTimeout(() => {
          refetch();
          setIsAssistDone(false);
        }, 3000);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleExpand = () => {
    setIsStateExpanded(!isStateExpanded);
  };

  const handleResolveAssist = () => {
    resolveAssists({
      assistId: data?.id,
      cta: "mark_priority",
      todo_ids: priorityTodoIds,
    });
  };

  const info = JSON.parse(data?.info);

  const todos = info?.todos || [];

  const isResolved = Boolean(data?.resolved_at);

  return (
    <div className={styles.assist}>
      <AssistHeader
        isResolved={isResolved}
        isExpanded={isStateExpanded}
        shouldShowInfo={!isAssistDone}
        isClearingAssist={isClearingAssist}
        data={data}
        icon={icon}
        onClick={handleExpand}
        refetch={refetch}
        clearAssist={clearAssist}
      />

      {isStateExpanded && (
        <>
          {isAssistDone ? (
            <DoneState />
          ) : isResolved ? (
            <ResolvedView />
          ) : (
            <div>
              <Row justify="space-between" className="mt-16">
                <div className="width-200-px" />
                <Text className="mr-24" italic={true}>
                  Mark Priority
                </Text>
              </Row>
              <div className="ml-16">
                {todos?.map(([todoId, todoText]) => {
                  const currentTodo = getCurrentTodo(todoId, allTodos);

                  if (
                    getIsTodoInFuture(currentTodo, appDate) ||
                    Boolean(currentTodo?.completed_at) ||
                    !currentTodo
                  ) {
                    return null;
                  }

                  return (
                    <PriorityTodo
                      todoId={todoId}
                      todoText={todoText}
                      setPriorityTodoIds={setPriorityTodoIds}
                    />
                  );
                })}
              </div>

              <Button
                className="my-8"
                type="primary"
                ghost={true}
                size="small"
                onClick={handleResolveAssist}
              >
                Prioritise marked Todos
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

SetPriorityAssist.propTypes = {};

export default SetPriorityAssist;
