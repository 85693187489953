import React from "react";
// import PropTypes from 'prop-types';
import { Input, Button, Form } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const MinutesInput = ({
  submitTxt = "",
  value = null,
  hideSubmitBtn = false,
  shouldAddOnPressEvent = false,
  shouldShowCancel = true,
  onChange = () => {},
  onPressEnter = () => {},
  onCancel = () => {},
}) => {
  return (
    <div className="flex-display align-items-center height-100-percent">
      <Input
        style={{ width: "60px", minWidth: "60px" }}
        size="small"
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        placeholder="0m"
      />
      {/* <Text className="text-primary ml-4">minutes</Text> */}
      {!hideSubmitBtn && (
        <Form.Item noStyle>
          <Button
            size="small"
            className="ml-8"
            type="primary"
            htmlType="submit"
            onClick={shouldAddOnPressEvent ? onPressEnter : undefined}
          >
            {submitTxt || "Submit"}
          </Button>
        </Form.Item>
      )}

      {shouldShowCancel && (
        <CloseCircleOutlined className="ml-8" onClick={onCancel} />
      )}
    </div>
  );
};

MinutesInput.propTypes = {};

export default MinutesInput;
