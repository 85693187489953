import axios from "axios";

const saveGoalApi = async ({ goal }) => {
  const { data } = await axios.post("/api/v2/goals", {
    goal,
  });

  return data;
};

const deleteGoalApi = async (goalId) => {
  const { data } = await axios.delete(`/api/v2/goals/${goalId}`);

  return data;
};

export { saveGoalApi, deleteGoalApi };
