import moment from "moment";

import { dateFormat } from "app/appConstants";

export const saveTodoOnSucess = ({
  queryClient = {},
  data = {},
  priorityAssist = [],
  priorityAssistExists = false,
  variables = {},
  handleCreateDWNow = () => {},
  clearAssist = () => {},
}) => {
  queryClient.invalidateQueries("getTodos");
  queryClient.invalidateQueries("getDayInsights");
  queryClient.invalidateQueries(["getDailyStats", moment().format(dateFormat)]);
  // queryClient.invalidateQueries("getDayView");
  if (variables?.extraParams?.resetValues) {
    variables.extraParams.resetValues();
  }
  if (variables?.extraParams?.createDWNow) {
    handleCreateDWNow(null, {
      todoTitle: data?.title,
      todoDesc: data?.description,
      todoId: data?.id,
    });
  }
  if (priorityAssistExists) {
    clearAssist({ assistId: priorityAssist[0]?.id });
  }
};
