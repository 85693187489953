const getPlanDetailsYesterday = () => {
  return {
    daily_plan_exists: true,
    daily_plan_hash: {
      daily_plan_id: 2,
      time_blocks: [
        {
          id: 3,
          daily_plan_id: 2,
          work_plan_id: 1,
          title: "title 1",
          cloned_id: null,
          resource_id: 1,
          from_time_utc: "2021-05-30T09:00:00.000Z",
          to_time_utc: "2021-05-30T10:00:00.000Z",

          // the below shall not be sent
          quick_plan: "",
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
        {
          id: 4,
          daily_plan_id: 2,
          work_plan_id: 2,
          title: "title 1",
          quick_plan: "",
          from_time_utc: "2021-05-30T10:00:00.000Z",
          to_time_utc: "2021-05-30T12:00:00.000Z",
          cloned_id: null,
          resource_id: 1,
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
      ],
      todos: [
        {
          id: 4,
          daily_plan_id: 2,
          title: "title 1 yesterday",
          completed_at: "",
        },
        {
          id: 5,
          daily_plan_id: 2,
          title: "title 1 yesterday",
          completed_at: "",
        },
      ],
    },
  };
};

const getPlanDetails = () => {
  return {
    daily_plan_exists: true,
    daily_plan_hash: {
      daily_plan_id: 2,
      time_blocks: [
        {
          id: 3,
          daily_plan_id: 2,
          work_plan_id: 1,
          title: "title 1",
          cloned_id: null,
          resource_id: 1,
          from_time_utc: "2021-05-30T09:00:00.000Z",
          to_time_utc: "2021-05-30T10:00:00.000Z",

          // the below shall not be sent
          quick_plan: "",
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
        {
          id: 4,
          daily_plan_id: 2,
          work_plan_id: 2,
          title: "title 1",
          quick_plan: "",
          from_time_utc: "2021-05-30T10:00:00.000Z",
          to_time_utc: "2021-05-30T12:00:00.000Z",
          cloned_id: null,
          resource_id: 1,
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
      ],
      todos: [
        {
          id: 4,
          daily_plan_id: 2,
          title: "title 1",
          completed_at: "",
        },
        {
          id: 5,
          daily_plan_id: 2,
          title: "title 1",
          completed_at: "",
        },
      ],
    },
  };
};

const getPlanDetailsTomorrow = () => {
  return {
    daily_plan_exists: true,
    daily_plan_hash: {
      daily_plan_id: 2,
      time_blocks: [
        {
          id: 3,
          daily_plan_id: 2,
          work_plan_id: 1,
          title: "title 1",
          cloned_id: null,
          resource_id: 1,
          from_time_utc: "2021-05-30T09:00:00.000Z",
          to_time_utc: "2021-05-30T10:00:00.000Z",

          // the below shall not be sent
          quick_plan: "",
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
        {
          id: 4,
          daily_plan_id: 2,
          work_plan_id: 2,
          title: "title 1",
          quick_plan: "",
          from_time_utc: "2021-05-30T10:00:00.000Z",
          to_time_utc: "2021-05-30T12:00:00.000Z",
          cloned_id: null,
          resource_id: 1,
          actual_time_spend: null,
          is_task_completed: "unspecified", // "unspecified", "true", "false"
        },
      ],
      todos: [
        {
          id: 4,
          daily_plan_id: 2,
          title: "title 1 tomorrow",
          completed_at: "",
        },
        {
          id: 5,
          daily_plan_id: 2,
          title: "title 1 tomorrow",
          completed_at: "",
        },
      ],
    },
  };
};

const createDailyPlan = () => {
  return {
    message: "Successfully saved the Daily Plan",
  };
};

export {
  getPlanDetailsYesterday,
  getPlanDetails,
  getPlanDetailsTomorrow,
  createDailyPlan,
};
