import React, { useState, useEffect } from "react";
import { Row, Typography } from "antd";
import { BulbOutlined, BulbFilled } from "@ant-design/icons";

import { openMenuBarWindow } from "app/appConstants";
import { useCurrentUser, useIsUserRegisteredState } from "store";
import {
  getIsElectron,
  sendIPCUpdateEvent,
  handleSidecarWindowResize,
} from "app/appUtils";

import styles from "./SideCar.module.css";

const { Text } = Typography;

const getUpperAndLowerCaseCharacters = (title) => {
  let lowerCaseCount = 0;
  let upperCaseCount = 0;
  let spaceCount = 0;
  let specialCharacterCount = 0;
  for (let i = 0; i < title?.length; i++) {
    if (title[i] === " ") {
      spaceCount += 1;
    } else if (/[~`!#$%&*+=\-';,/{}|\\":<>().]/g.test(title[i])) {
      specialCharacterCount += 1;
    } else if (title[i] === title[i].toUpperCase()) {
      upperCaseCount += 1;
    } else {
      lowerCaseCount += 1;
    }
  }
  return [upperCaseCount, lowerCaseCount, spaceCount, specialCharacterCount];
};

const getWidthFromTitle = (title) => {
  const titleLength = title?.length;
  if (!titleLength) {
    return 150;
  }

  /*
  Possible lengths of title:
   <=10 - 4 todos, 10 Todos, Loading...
   15 - Plan your Todos
   15 or <=10 - DW event title
   18 - 5 m to end session
   20 - less than a min left
   16 - Session Overshot
   20 or 21 - Session Paused (1 m)
   14 - Session Paused
   max 25 - closestEvent(18 characters) in 5 m
   min  8  - closestEvent(1 char) in 5 m
  */

  /*
    This formula works well for:
     - 4 Todos, 3 Todos, 10 Todos
     - Plan your Todos
     - DeepWork Eve...
     - 5 m to end session
     - less than a min left
     - Session Paused and Session Paused (1 m)
     - a in 5 m
     - Morning StandupT... in 5 m
  */

  const [upperCaseCount, lowerCaseCount, spaceCount, specialCharacterCount] =
    getUpperAndLowerCaseCharacters(title);

  return (
    Math.ceil(
      lowerCaseCount >= 15
        ? lowerCaseCount * 6.8
        : lowerCaseCount >= 10
        ? lowerCaseCount * 7.6
        : lowerCaseCount * 8
    ) +
    Math.ceil(upperCaseCount * 10.5) +
    spaceCount * 6 +
    specialCharacterCount * 5 +
    20 +
    (title?.length < 15 ? 4 : 0) +
    4 +
    4
  ); // 20 is widht of bulb, 4 is right side padding, 4 is the left side padding, 4 is right side of bulb left side of title
};

const SideCar = (props) => {
  const [currentUser] = useCurrentUser();
  const [isUserRegistered] = useIsUserRegisteredState();
  const [isDWing, setIsDWing] = useState(false);
  const [title, setTitle] = useState("");

  const isElectron = getIsElectron();
  const isUserLoggedIn = Boolean(currentUser);

  const ipcRendererWrapperToUpdateSideCarTitle = isElectron
    ? window?.electronDW?.ipcRendererWrapperToUpdateSideCarTitle
    : () => {};

  useEffect(() => {
    ipcRendererWrapperToUpdateSideCarTitle((e, args) => {
      setTitle(args?.title || "");
      setIsDWing(args?.isDWing);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSidecarWindowResize({
      width:
        !isUserRegistered || !isUserLoggedIn ? 100 : getWidthFromTitle(title),
    });
  }, [isUserLoggedIn, isUserRegistered, title]);

  const handleClick = () => {
    sendIPCUpdateEvent(openMenuBarWindow);
  };

  return (
    <Row
      id="sidebar-content"
      className="cursor-pointer ml-4"
      align="middle"
      style={{ minHeight: "24px" }}
      onClick={handleClick}
    >
      {isDWing ? (
        <BulbFilled
          className={styles.sideCarBulb}
          style={{ color: "#FFCD00" }}
        />
      ) : (
        <BulbOutlined className={styles.sideCarBulb} />
      )}

      {!isUserLoggedIn ? (
        <Text className="ml-4" strong>
          Log In
        </Text>
      ) : !isUserRegistered ? (
        <Text className="ml-4" strong>
          Sign Up
        </Text>
      ) : (
        <div className={styles.sideCarTitle}>
          <Text className={title?.length < 15 ? "mr-4" : ""} strong>
            {title}
          </Text>
        </div>
      )}
    </Row>
  );
};

SideCar.propTypes = {};

export default SideCar;
