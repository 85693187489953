import React from "react";
// import PropTypes from "prop-types";
import { Row, Typography } from "antd";

import { InlineSpinner } from "common/components";
import { ReactComponent as AssistResolved } from "assets/assist_resolved.svg";

const { Text } = Typography;

const DoneState = (props) => {
  return (
    <Row
      justify={"center"}
      align="middle"
      className="flex-direction-column mt-24"
    >
      <AssistResolved />
      <Row align={"middle"}>
        <InlineSpinner></InlineSpinner>
        <Text className="ml-8">Clearing Todo</Text>
      </Row>
    </Row>
  );
};

DoneState.propTypes = {};

export default DoneState;
