import React from "react";
// import PropTypes from "prop-types";
import { Typography } from "antd";

const { Text, Link } = Typography;

const getTransformedText = (suggestionText) => {
  let isStarFound = false;
  let newString = "<p>";

  for (let i = 0; i < suggestionText?.length; i++) {
    if (suggestionText[i] === "*") {
      if (!isStarFound) {
        newString += "<b>";
        isStarFound = true;
      } else {
        newString += "</b>";
        isStarFound = false;
      }
    } else {
      newString += suggestionText[i];
    }
  }

  newString += "</p>";

  return newString;
};

const Suggestions = ({ suggestions = [] }) => {
  if (suggestions?.length === 0) {
    return null;
  }

  return (
    <div>
      <Text className="block-display mt-16" strong>
        Suggestions
      </Text>
      {suggestions?.map((suggestion) => {
        return (
          <div className="mt-4">
            <span
              className="font-size-12"
              dangerouslySetInnerHTML={{
                __html: getTransformedText(suggestion?.text),
              }}
            />
            <Link
              className="cursor-pointer ml-4 font-size-12"
              target="_blank"
              href={suggestion?.link}
            >
              Know more
            </Link>
          </div>
        );
      })}
    </div>
  );
};

Suggestions.propTypes = {};

export default Suggestions;
