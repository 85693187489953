/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
// import PropTypes from 'prop-types';
import { Typography } from "antd";

import SaveGoal from "./SaveGoal";
import styles from "app/goals/Goals.module.css";

const { Text, Title } = Typography;

const EmptyGoalsScreen = ({ refetch = () => {} }) => {
  return (
    <div>
      <Title level={3} className="mb-8">
        Your Goals
      </Title>

      <div className={styles.contentContainer}>
        <Text>
          <span>↗️</span> Goals are your next big things. Set your first goal
          now and give direction for your growth by turning your aspirations
          into real achievements.
        </Text>
      </div>

      <div className="mt-32">
        <SaveGoal
          saveTxt="Add Goal"
          onSave={() => {
            refetch();
          }}
          showCancel={false}
        />
      </div>
    </div>
  );
};

EmptyGoalsScreen.propTypes = {};

export default EmptyGoalsScreen;
