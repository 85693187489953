import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Row, Col, Typography } from "antd";

import { useSaveTodo } from "common/hooks";
import { ReactComponent as TodoEdit } from "assets/todoEdit.svg";
import { errorNotification } from "app/appUtils";

import { TodosContext } from "app/home/context";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import TodoTick from "app/home/todos/categories-list/todo/todo-tick/TodoTick";
import archiveStyles from "app/archive/Archive.module.css";
import styles from "app/home/todos/Todos.module.css";

const { Text } = Typography;

const ArchiveTodo = ({ todo, categories = [], refetchTodos }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      refetchTodos();
      if (!variables?.extraParams?.isFromCheck) {
        handleEdit();
      }
    },
    onError: (err) => {
      refetchTodos();
      errorNotification(err);
    },
  });

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCheckTodo = () => {
    if (Boolean(todo?.completed_at)) {
      saveTodo({
        todo: { ...todo, completed_at: null },
        extraParams: { isFromCheck: true },
      });
    } else {
      let updatedTodo = { ...todo, completed_at: new Date() };
      delete updatedTodo["time_spent_seconds"];
      saveTodo({
        todo: updatedTodo,
        extraParams: { isFromCheck: true },
      });
    }
  };

  return (
    <div>
      {isEdit ? (
        <div className="mb-8 mr-8">
          <TodosContext.Provider
            value={{
              categoriesWithTodos,
              setCategoriesWithTodos,
            }}
          >
            <TodoInput
              loader={false}
              saveTodo={saveTodo}
              categories={categories}
              from="archive"
              isSavingTodo={isSavingTodo}
              // todoCategories={todoCategories}
              isForEdit={true}
              setIsEdit={setIsEdit}
              todo={todo}
              // handleIsDWSessionTrackerVisible={handleIsDWSessionTrackerVisible}
              refetchTodos={refetchTodos}
            />
          </TodosContext.Provider>
        </div>
      ) : (
        <div>
          <Row align="middle" className="width-100-percent my-4">
            <Col span={2} className={styles.todoTickIconCol}>
              {todo.completed_at ? (
                <div onClick={handleCheckTodo}>
                  <TodoTick checked={true} />
                </div>
              ) : (
                <div onClick={handleCheckTodo}>
                  <TodoTick checked={false} />
                </div>
              )}
            </Col>
            <Col span={21}>
              <div
                className={`flex-display align-items-center justify-content-space-between width-100-percent ${archiveStyles.todoContent}`}
              >
                <Text>{todo?.title}</Text>

                <div>
                  <TodoEdit
                    className={archiveStyles.todoEdit}
                    onClick={handleEdit}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

ArchiveTodo.propTypes = {};

export default ArchiveTodo;
