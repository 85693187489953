import React from "react";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/react";

import { PageSpinner } from "common/components";
import {
  useIsUserRegisteredState,
  useCurrentUser,
  useIsFetchingUser,
  useOnboardedState,
  useUserPointsState,
  useUserSettings,
  useUserIntegrationInfo,
  useEnabledFeaturesState,
} from "store";
import { appInitApi } from "common/apis/appApi";

const OnboardGuard = ({ children }) => {
  const [isFetchingUser] = useIsFetchingUser();

  const [, setIsUserRegistered] = useIsUserRegisteredState();
  const [currentUser] = useCurrentUser();
  const [, setOnboarded] = useOnboardedState();
  const [, setUserPointsState] = useUserPointsState();
  const [, setUserSettings] = useUserSettings();
  const [, setUserIntegrationInfo] = useUserIntegrationInfo();
  const [, setEnabledFeatures] = useEnabledFeaturesState();

  const {
    isLoading,
    // isFetching
  } = useQuery("appInit", appInitApi, {
    //enabled: currentUser && !isUserRegistered,
    enabled: !!currentUser,
    onError: (error) => {
      const status = error?.response?.status;
      if (status === 308) {
        // Making another API call to the same api is going to create a user.
        setIsUserRegistered(true);
      }
      console.log(error);
    },
    onSuccess: (data) => {
      setIsUserRegistered(true);

      setOnboarded(data?.onboarded);

      setUserPointsState(data?.current_points);

      setUserSettings(data?.setting);

      setUserIntegrationInfo(data?.integrations_info);

      setEnabledFeatures(data?.setting);

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        if (
          typeof window !== "undefined" &&
          typeof window.gtag !== "undefined"
        ) {
          if (!Boolean(data?.onboarded)) {
            window.gtag("event", "conversion", {
              send_to: "AW-1005812573/Tu2ACMify_8CEN32zd8D",
            });
          }
        } else {
          Sentry.captureMessage(
            `${currentUser?.displayName} (${currentUser?.email}) - Error from FE - window.gtag is not set.`
          );
        }
      }
    },
  });

  const isPageProgressVisible = isFetchingUser || isLoading;

  if (isPageProgressVisible) {
    return <PageSpinner />;
  }

  return <div>{children}</div>;
};

OnboardGuard.propTypes = {};

export default OnboardGuard;
