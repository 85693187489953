import React, { useCallback } from "react";
// import PropTypes from 'prop-types';
import { useQuery, useMutation } from "react-query";
import _debounce from "lodash/debounce";
import { Typography, Row, Col, Switch, Slider } from "antd";

import { BoxLoader, InlineSpinner } from "common/components";
import { getSettingsApi, saveHabitsConfigApi } from "app/settings/settingsApi";
import { errorNotification, handleOpenLink } from "app/appUtils";
import {
  getConfig,
  getCurrentConfig,
  getDescription,
  getMarks,
  getValue,
  habitsInfo,
} from "./habitsCustomizationUtils";

import styles from "./HabitsCustomization.module.css";

const { Title, Text, Link } = Typography;

const CustomizationRow = ({
  className = "",
  id = "",
  habit = {},
  habitInfo = {},
  refetch = () => {},
}) => {
  const currentConfig = getCurrentConfig(habit);

  const { mutate: saveHabitsConfig, isLoading } = useMutation(
    saveHabitsConfigApi,
    {
      onSuccess: () => {
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const value = getValue({
    currentConfig,
    state: habitInfo?.state,
    isSliderRange: habitInfo?.isSliderRange,
    valueConversion: habitInfo?.valueConversion,
  });

  const handleEnable = (checked) => {
    saveHabitsConfig({
      atomic_habit: {
        id,
        enabled: checked,
      },
    });
  };

  const handleChange = (val) => {
    saveHabitsConfig({
      atomic_habit: {
        id,
        config: getConfig(val, habitInfo),
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounce = useCallback(_debounce(handleChange, 200), []);

  return (
    <div className={className}>
      <div id={`habits-tooltip-container-${id}`} />
      <Row justify="space-between">
        <div>
          <Text strong className={styles.customizationRowTitle}>
            {habitInfo?.title}
          </Text>
          {isLoading && <InlineSpinner className="mx-4" fontSize={24} />}
          <Link
            underline
            className={`ml-8 ${styles.customizationRowLink}`}
            // href={habitInfo?.href}
            // target="_blank"
            onClick={() => {
              handleOpenLink(habitInfo?.href);
            }}
          >
            Know More
          </Link>
        </div>
        <Switch checked={habit?.enabled} onChange={handleEnable} />
      </Row>

      <Slider
        className="block-display"
        disabled={!Boolean(habit?.enabled)}
        value={value}
        min={habitInfo?.min}
        max={habitInfo?.max}
        marks={getMarks(habit?.type, value, habitInfo?.max)}
        range={habitInfo?.isSliderRange}
        tooltip={{
          open: true,
          getPopupContainer: () => {
            return document?.getElementById(`habits-tooltip-container-${id}`);
          },
        }}
        onChange={handleChangeDebounce}
      />
      <Text className="block-display">{getDescription(habitInfo, value)}</Text>
    </div>
  );
};

const HabitsCustomization = (props) => {
  const {
    data: settings,
    isLoading,
    refetch,
  } = useQuery("getSettings", getSettingsApi);

  const habitsSettings = settings?.habit_settings || {};

  const organisationHabitId = habitsSettings?.organization_habit || Infinity;

  const executionHabitId = habitsSettings?.execution_habit || Infinity;

  const bettermentHabitId = habitsSettings?.betterment_habit || Infinity;

  const atomicHabits = habitsSettings?.atomic_habits || [];

  const organisationHabits = atomicHabits?.filter(
    (habit) => habit?.growth_habit_id === organisationHabitId
  );

  const executionHabits = atomicHabits?.filter(
    (habit) => habit?.growth_habit_id === executionHabitId
  );

  const bettermentHabits = atomicHabits?.filter(
    (habit) => habit?.growth_habit_id === bettermentHabitId
  );

  const loader = isLoading;

  return (
    <div className={styles.habitsList}>
      <Title level={3} strong>
        Customize your Productivity Habits
      </Title>

      {loader ? (
        <BoxLoader />
      ) : (
        <div>
          <Row className="mt-32">
            <Col span={18}>
              <Text className={`block-display ${styles.habitTitle}`}>
                Organisation Atomic Habits
              </Text>
              {organisationHabits?.map((habit) => {
                const habitInfo = habitsInfo[habit?.type] || {};
                return (
                  <CustomizationRow
                    className="mt-16 ml-16"
                    id={habit?.id}
                    key={habit?.id}
                    habitInfo={habitInfo}
                    habit={habit}
                    refetch={refetch}
                  />
                );
              })}

              <Text className={`mt-24 block-display ${styles.habitTitle}`}>
                Focussed Execution Atomic Habits
              </Text>

              {executionHabits?.map((habit) => {
                const habitInfo = habitsInfo[habit?.type] || {};
                return (
                  <CustomizationRow
                    className="mt-16 ml-16"
                    id={habit?.id}
                    key={habit?.id}
                    habitInfo={habitInfo}
                    habit={habit}
                    refetch={refetch}
                  />
                );
              })}

              <Text className={`mt-24 block-display ${styles.habitTitle}`}>
                Betterment Atomic Habits
              </Text>

              {bettermentHabits?.map((habit) => {
                const habitInfo = habitsInfo[habit?.type] || {};

                return (
                  <CustomizationRow
                    className="mt-16 ml-16"
                    id={habit?.id}
                    key={habit?.id}
                    habitInfo={habitInfo}
                    habit={habit}
                    refetch={refetch}
                  />
                );
              })}
            </Col>
            <Col span={4}></Col>
          </Row>
        </div>
      )}
    </div>
  );
};

HabitsCustomization.propTypes = {};

export default HabitsCustomization;
