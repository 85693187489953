import React, { useState, useEffect } from "react";
import moment from "moment";
import { useQueryClient } from "react-query";
import { Tooltip } from "antd";

import { useAppDate, useAppRefreshTs } from "store";
import { dateFormat } from "app/appConstants";

import { ReactComponent as AutoRenew } from "assets/autorenew.svg";

const RefreshCenter = ({ fromMenu = false }) => {
  const queryClient = useQueryClient();

  const [appDate, setAppDate] = useAppDate();
  const [, setAppRefreshTs] = useAppRefreshTs();

  const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);

  useEffect(() => {
    // Check every 10 seconds if date has changed
    const intervalId = setInterval(() => {
      if (moment().format(dateFormat) !== appDate) {
        // Date has changed. So update it.
        setAppDate(moment().format(dateFormat));
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [appDate, setAppDate]);

  const handleClick = () => {
    // This invalidates Queries
    setAppRefreshTs(moment());
    setIsAutoRefreshing(true);
    setTimeout(() => {
      setIsAutoRefreshing(false);
    }, 2000);

    queryClient.invalidateQueries(["getDailyStats"]);
  };

  return fromMenu ? (
    <></>
  ) : (
    <Tooltip title="Refresh">
      <AutoRenew
        style={{ fontSize: "18px" }}
        className={`ml-8 cursor-pointer ${
          isAutoRefreshing ? "refresh-spin" : ""
        }`}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default RefreshCenter;
