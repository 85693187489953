import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "antd";
import { PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { useInsightsDefaultTabState } from "store";
import { TodosContext } from "app/home/context";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { getTotalTimeSpentHMStr } from "app/appUtils";
import { ReactComponent as TimerIcon } from "assets/timer.svg";

import styles from "app/home/todos/Todos.module.css";

const { Text } = Typography;

const CategoryTitle = ({
  category = {},
  dailyCategorySummary = {},
  loader = false,
  dueDateFilter = "",
  remainingPan,
  categories,
  shouldShowTime,
  saveTodo,
  isSavingTodo,
}) => {
  const history = useHistory();
  const [, setDefaultTab] = useInsightsDefaultTabState();

  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const handleAddTodo = () => {
    setIsAddingTodo(!isAddingTodo);
  };

  const categoryStats = dailyCategorySummary[category?.name];

  let categoryFocusTime = 0;
  // let categoryMeetingTime = 0;
  let categoryTodos = 0;

  if (categoryStats && categoryStats?.length === 3) {
    categoryFocusTime = categoryStats[0];
    // categoryMeetingTime = categoryStats[1];
    categoryTodos = categoryStats[2];
  }

  const categoryFocusTimeStr =
    categoryFocusTime > 0 ? getTotalTimeSpentHMStr(categoryFocusTime) : "0 m";

  const handleNavigateToInsights = (categoryTitle) => {
    history.push("/insights");
    setDefaultTab(categoryTitle);
  };

  return (
    <div>
      <div className="flex-display justify-content-space-between align-items-center">
        <div
          className={`flex-display align-items-center ${styles.categoryTitleDiv}`}
          style={{ color: category?.color_formula }}
        >
          <Text
            className={styles.todoCategoryName}
            style={{ color: category?.color_formula }}
          >
            {category?.name?.toUpperCase()} ({remainingPan})
          </Text>
          <Button
            size="small"
            className={`ml-8 ${styles.categoryAddIcon} cursor-pointer`}
            style={{
              backgroundColor: category?.color_formula,
              border: `1px solid ${category?.color_formula}`,
            }}
          >
            <PlusOutlined
              className={styles.categoryPlus}
              onClick={handleAddTodo}
            />
          </Button>
        </div>

        <div
          className="inline-flex-display align-items-center cursor-pointer"
          onClick={() => handleNavigateToInsights(category?.name)}
        >
          {categoryFocusTime > 0 && shouldShowTime && (
            <div className="inline-flex-display align-items-center">
              <TimerIcon
                style={{
                  stroke: category?.color_formula,
                  strokeWidth: "0.6px",
                  width: "16px",
                  height: "15px",
                }}
              />

              <Text
                className="ml-2"
                style={{ color: category?.color_formula, lineHeight: "1" }}
              >
                {categoryFocusTimeStr}
              </Text>
            </div>
          )}
          {categoryTodos > 0 && (
            <div className="ml-8 inline-flex-display align-items-center">
              <CheckCircleOutlined
                style={{
                  color: category?.color_formula,
                }}
              />
              <Text
                className="ml-2"
                style={{ color: category?.color_formula, lineHeight: "1" }}
              >
                {categoryTodos}
              </Text>
            </div>
          )}
        </div>
      </div>
      {isAddingTodo && (
        <TodosContext.Provider
          value={{
            categoriesWithTodos,
            setCategoriesWithTodos,
          }}
        >
          <TodoInput
            categories={categories}
            from="quick-add"
            loader={loader}
            saveTodo={saveTodo}
            dueDateFilter={dueDateFilter}
            currentTodoCategoryId={category?.id}
            isSavingTodo={isSavingTodo}
            onMenuCancel={handleAddTodo}
          />
        </TodosContext.Provider>
      )}
    </div>
  );
};

CategoryTitle.propTypes = {};

export default CategoryTitle;
