import moment from "moment";

import { dateFormat } from "app/appConstants";

const getIsTodoInFuture = (currentTodo, appDate) => {
  return moment(currentTodo?.due_date, dateFormat)?.isAfter(
    moment(appDate, dateFormat)
  );
};

const getCurrentTodo = (todoId, allTodos) => {
  let currentTodo = allTodos?.filter((todo) => todo?.id === todoId) || [];

  if (currentTodo?.length > 0) {
    currentTodo = currentTodo[0];
    return currentTodo;
  }

  return null;
};

export { getIsTodoInFuture, getCurrentTodo };
