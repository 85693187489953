import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Typography, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { BoxLoader } from "common/components";
import { useIsAppExpandedState } from "store";
import { useGetTodos } from "common/hooks";
import { TodosContext } from "app/home/context";
import SaveGoal from "./components/SaveGoal";
import Goal from "./components/Goal";
import EmptyGoalsScreen from "./components/EmptyGoalsScreen";
import TopNav from "app/top-nav/TopNav";
import {
  getIsElectron,
  getIsGoalDone,
  getMainContainerStyling,
} from "app/appUtils";

import styles from "./Goals.module.css";

const { Title } = Typography;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const Goals = (props) => {
  const [isAppExpanded] = useIsAppExpandedState();

  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [showCompletedGoals, setShowCompletedGoals] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const { data, isLoading, refetch } = useGetTodos({ enabled: true });

  const handleIsAddingGoal = () => {
    setIsAddingGoal(!isAddingGoal);
  };

  const handleShowCompletedGoals = () => {
    setShowCompletedGoals(!showCompletedGoals);
  };

  const isElectron = getIsElectron();

  const isWebPage = !isChromeExtension && !isElectron;

  const goals = data?.filter((item) => item?.type === "Goal");

  const areGoalsEmpty = goals?.length === 0;

  const pendingGoals = goals?.filter((goal) => !getIsGoalDone(goal));
  const completedGoals = goals?.filter((goal) => getIsGoalDone(goal));

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  return (
    <TodosContext.Provider
      value={{
        categoriesWithTodos,
        setCategoriesWithTodos,
      }}
    >
      <div
        className={`flex-display ${isWebPage && "web-main-content-styles"} `}
      >
        <div
          className="ml-8 mr-8 mb-8 pl-16 pr-16"
          id="insights-content"
          style={mainContainerStyles}
        >
          <TopNav from="goals" />

          {areGoalsEmpty ? (
            <EmptyGoalsScreen refetch={refetch} />
          ) : (
            <>
              <Row align="middle">
                <Title level={4} className="mt-8 mb-8">
                  Your Goals
                </Title>
                <Button
                  className={styles.addGoalBtn}
                  size="small"
                  onClick={handleIsAddingGoal}
                >
                  <PlusOutlined className="font-size-12" />
                </Button>
              </Row>

              {isAddingGoal && (
                <SaveGoal
                  saveTxt="Add Goal"
                  handleCancel={handleIsAddingGoal}
                  onSave={refetch}
                />
              )}

              {isLoading ? (
                <BoxLoader />
              ) : (
                <div>
                  {pendingGoals?.map((goal) => {
                    return <Goal goal={goal} refetch={refetch} />;
                  })}

                  {completedGoals?.length > 0 && (
                    <Row justify="center">
                      <Button
                        type="link"
                        className="pl-0 mt-8 mb-8"
                        onClick={handleShowCompletedGoals}
                      >
                        See Completed
                      </Button>
                    </Row>
                  )}

                  {showCompletedGoals && (
                    <>
                      {completedGoals?.map((goal) => {
                        return <Goal goal={goal} refetch={refetch} />;
                      })}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </TodosContext.Provider>
  );
};

Goals.propTypes = {};

export default Goals;
