import React from "react";
import Chart from "react-apexcharts";

import { getNumberOfHoursMinutesString } from "app/appUtils";

const ColumnChart = ({
  series = [],
  xAxisLabelColors = [],
  xCategories = [],
}) => {
  return (
    <div>
      <Chart
        height={200}
        width={300}
        options={{
          fill: {
            opacity: 1,
          },
          chart: {
            id: "bar-chart",
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ["#0000ff", "#818181"],
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
            formatter: (val) => {
              return getNumberOfHoursMinutesString(val);
            },
          },
          xaxis: {
            categories: xCategories,
            labels: {
              style: {
                colors: xAxisLabelColors,
              },
            },
          },
          yaxis: {
            categories: null,
            labels: {
              formatter: (val) => getNumberOfHoursMinutesString(val),
            },
            // tickAmount:
          },
          tooltip: {
            y: {
              formatter: (val) => getNumberOfHoursMinutesString(val),
            },
          },
        }}
        series={series}
        type="bar"
      />
    </div>
  );
};

ColumnChart.propTypes = {};

export default ColumnChart;
