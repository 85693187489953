import React from "react";
import moment from "moment";
import MultiSwitch from "react-multi-switch-toggle";
import { Row, Typography, DatePicker } from "antd";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";

import {
  dateFormat,
  shortDateFormatWithWeekDay,
  displayShortDateFormatString,
} from "app/appConstants";

const { RangePicker } = DatePicker;

const { Title } = Typography;

const InsightsHeader = ({
  view = 0, // 0 -> Day, 1 -> Week
  currentDate = null,
  fromDate = null,
  toDate = null,
  setView,
  setFromDate = () => {},
  setToDate = () => {},
  setCurrentDate = () => {},
}) => {
  const handlePeriodDateChange = ([start, end]) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleReduceDate = () => {
    setCurrentDate(
      moment(currentDate, dateFormat)?.subtract(1, "day")?.format(dateFormat)
    );
  };

  const handleIncreaseDate = () => {
    setCurrentDate(
      moment(currentDate, dateFormat)?.add(1, "day")?.format(dateFormat)
    );
  };

  return (
    <Row
      className="flex-display flex-direction-column width-100-percent"
      justify="center"
      align="middle"
    >
      <div className="insights-header-toggle">
        <MultiSwitch
          texts={["Day", "Week"]}
          selectedSwitch={view}
          bgColor={"#e3e3e3"}
          onToggleCallback={setView}
          fontColor={"black"}
          selectedFontColor={"black"}
          height={"30px"}
          eachSwitchWidth={50}
          fontSize={"16px"}
        ></MultiSwitch>
      </div>
      <Row className="my-8 flex-display align-items-center justify-content-center">
        {view === 0 ? (
          <>
            <CaretLeftFilled
              className="font-size-20"
              onClick={handleReduceDate}
            />
            <Title level={5} className="mx-16">
              {moment(currentDate, dateFormat)?.format(
                shortDateFormatWithWeekDay
              )}
            </Title>
            <CaretRightFilled
              className="font-size-20"
              onClick={handleIncreaseDate}
            />
          </>
        ) : (
          <Row>
            <RangePicker
              value={[moment(fromDate, dateFormat), moment(toDate, dateFormat)]}
              format={displayShortDateFormatString}
              disabledDate={(current) => current && current?.isAfter(moment())}
              onChange={handlePeriodDateChange}
            />
          </Row>
        )}
      </Row>
    </Row>
  );
};

InsightsHeader.propTypes = {};

export default InsightsHeader;
