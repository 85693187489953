import axios from "axios";

const getTodosWithCategoriesApi = async () => {
  const { data } = await axios.get(`/api/v1/todo_categories/with_todos`);
  return data;
};

const getTodoCategoriesApi = async () => {
  const { data } = await axios.get(`/api/v1/todo_categories`);
  return data;
};

const saveTodoCategoryApi = async ({ todo_category }) => {
  const { data } = await axios.post(`/api/v1/todo_categories`, {
    todo_category: todo_category,
  });

  return data;
};

const deleteTodoCategoryApi = async ({ todoCategoryId }) => {
  const { data } = await axios.delete(
    `/api/v1/todo_categories/${todoCategoryId}`
  );

  return data;
};

const reorderTodoCategoriesApi = async ({ todoCategoryOrderMap }) => {
  const { data } = await axios.post(`/api/v1/todo_categories/reorder`, {
    reorder: {
      ...todoCategoryOrderMap,
    },
  });

  return data;
};

const getCompletedTodosApi = async ({ queryKey }) => {
  const { data } = await axios.get(
    `/api/v1/todo_categories/with_todos_completed?from=${queryKey[1]}&to=${queryKey[2]}`
  );
  return data;
};

export {
  getTodosWithCategoriesApi,
  getTodoCategoriesApi,
  saveTodoCategoryApi,
  deleteTodoCategoryApi,
  reorderTodoCategoriesApi,
  getCompletedTodosApi,
};
