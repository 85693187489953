import React from "react";
// import PropTypes from "prop-types";
import { Typography } from "antd";

import { ReactComponent as TodoEdit } from "assets/todoEdit.svg";
import { ReactComponent as TimerIcon } from "assets/timer.svg";

import styles from "app/home/todos/Todos.module.css";

const { Text } = Typography;

const TodoActions = ({
  disabled = false,
  shouldShowTime = false,
  timeSpentToday = 0,
  todo = {},
  handleEdit = () => {},
  handleCreateDWNow = () => {},
  handleIsDWSessionTrackerVisible = () => {},
}) => {
  return (
    <div className={"flex-display"}>
      <div className={`${styles.todoActionButtons}`}>
        <button
          style={{
            cursor: disabled ? "not-allowed" : undefined,
            marginLeft: "8px",
            marginRight: shouldShowTime
              ? timeSpentToday > 0
                ? "38px"
                : "30px"
              : "0px",
          }}
        >
          <TodoEdit onClick={handleEdit} />
        </button>

        {shouldShowTime && (
          <>
            {timeSpentToday > 0 ? (
              <Text
                style={{
                  cursor: disabled ? "not-allowed" : undefined,
                  marginLeft: "8px",
                  width: "40px",
                }}
                type="secondary"
                onClick={handleIsDWSessionTrackerVisible}
              >
                {`${timeSpentToday}m`}
              </Text>
            ) : (
              <button>
                <Text
                  style={{
                    cursor: disabled ? "not-allowed" : undefined,
                    marginLeft: "8px",
                  }}
                  type="secondary"
                  onClick={handleIsDWSessionTrackerVisible}
                >
                  {`${timeSpentToday}m`}
                </Text>
              </button>
            )}
          </>
        )}
      </div>
      <div className="inline-flex-display align-items-flex-start">
        <TimerIcon
          className="cursor-pointer "
          style={{
            cursor: disabled ? "not-allowed" : undefined,
            fontSize: "20px",
          }}
          onClick={() => {
            handleCreateDWNow(null, {
              todoId: todo?.id,
              todoTitle: todo?.title,
              todoDesc: todo?.description,
              todoDueDate: todo?.due_date,
            });
          }}
        />
      </div>
    </div>
  );
};

TodoActions.propTypes = {};

export default TodoActions;
