import moment from "moment";

const getHabitsDays = (dailySummary = []) => {
  const days = [];

  dailySummary.forEach((item) => {
    const tempDay = moment(item?.date, "YYYY/MM/DD");
    days.push({
      day: tempDay?.format("ddd"),
      date: tempDay?.format("D"),
      day_type: item?.day_type,
      signal_label: item?.signal_label,
      signal_type: item?.signal_type,
      signal_value: item?.signal_value,
    });
  });

  return days;
};

const getAtomicHabit = (name = "", atomicHabits = []) => {
  const atomicHabit =
    atomicHabits?.filter((habit) => habit?.name === name) || [];

  if (atomicHabit?.length > 0) {
    return atomicHabit[0];
  }

  return {};
};

const getSuggestions = (atomicHabits = []) => {
  const suggestions = [];
  atomicHabits.forEach((habit) => {
    const habitSuggestion = habit?.suggestions || [];
    suggestions.push(...habitSuggestion);
  });

  return suggestions;
};

export { getHabitsDays, getAtomicHabit, getSuggestions };
