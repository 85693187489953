import React from "react";
// import PropTypes from 'prop-types';
import { Row, Typography, Checkbox } from "antd";

import NewLearningsText from "./NewLearningsText";
import PriorityText from "./PriorityText";

const { Text } = Typography;

const TodoTypeCheckbox = ({
  isPriority = false,
  newLearnings = false,
  handlePriority = () => {},
  handleNewLearnings = () => {},
}) => {
  return (
    <div>
      <Row className="mt-4 todo-input-checkboxes" align="middle">
        <Text className="font-size-12" italic={true} type="secondary">
          Mark as:{" "}
        </Text>
        <Checkbox
          checked={isPriority}
          className="ml-8 mr-4"
          onChange={handlePriority}
        ></Checkbox>
        <PriorityText />
        <Checkbox
          checked={newLearnings}
          className="ml-8 mr-4"
          onChange={handleNewLearnings}
        ></Checkbox>
        <NewLearningsText />
      </Row>
    </div>
  );
};

TodoTypeCheckbox.propTypes = {};

export default TodoTypeCheckbox;
