import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Card, Row, Col, Typography, Tag } from "antd";
import { RightOutlined } from "@ant-design/icons";

import {
  useOnboardedState,
  useSelectedIntegrationState,
  useSuccessMessageStateState,
} from "store";
import { EllipseText, BoxLoader } from "common/components";
import IntegrationDetails from "./IntegrationDetails";
import {
  getAllIntegrationsApi,
  integrateInitApi,
} from "app/settings/integrations/integrationsApi";
import { typeMap } from "./common/constants";
import ElectronIntegrationsButton from "./common/ElectronIntegrationsButton";
import { errorNotification, errorNotificationWithString } from "app/appUtils";
import { iconMap, oauthURLs } from "app/appConstants";
import styles from "../Settings.module.css";

const { Title, Text } = Typography;

const ConnectedCard = ({
  className = "",
  type = "",
  integrationName = "",
  id = 0,
  name = "",
  status = "",
  statusType = "",
  handleSelectIntegration = () => {},
}) => {
  return (
    <Card
      className={`cursor-pointer ${className} mb-8`}
      onClick={() => {
        handleSelectIntegration({ name: type, id });
      }}
    >
      <Row align="middle">
        <Col>{iconMap[type]}</Col>
        <Col>
          <Text className="block-display">{integrationName}</Text>
          <Row align="middle" justify="start">
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor:
                  statusType === "error"
                    ? "#f44336"
                    : statusType === "warning"
                    ? "#F4A024"
                    : "#45A914",
                borderRadius: "50%",
              }}
            />
            <EllipseText className="ml-8" text={name} characterLimit={15} />
          </Row>
        </Col>
        <Col>
          <Tag className="ml-16" color={statusType}>
            {status === "erroring"
              ? "Erroring"
              : status === "initialized"
              ? "Reconnect"
              : "Connected"}
          </Tag>
          <RightOutlined />
        </Col>
      </Row>
    </Card>
  );
};
ConnectedCard.propTypes = {
  integrationName: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  statusType: PropTypes.string,
};

const getStatusType = (aasm_state) => {
  if (aasm_state === "erroring") {
    return "error";
  }

  return aasm_state === "initialized" ? "warning" : "success";
};

const Integrations = ({
  title = "Add Integration",
  description = "You can add multiple accounts for the same tool",
  allIntegrations = [],
  connectedIntegrationTypes = [],
}) => {
  const [onboarded] = useOnboardedState();
  const queryClient = useQueryClient();

  const [, setSuccessMessage] = useSuccessMessageStateState();
  const [selectedIntegration, setSelectedIntegration] =
    useSelectedIntegrationState(null);

  const { data, isLoading, isFetching, refetch } = useQuery(
    "getAllIntegrations",
    getAllIntegrationsApi
  );

  useEffect(() => {
    return () => {
      setSelectedIntegration(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: initIntegration, isLoading: isInitializingIntegration } =
    useMutation(integrateInitApi, {
      onSuccess: (data) => {
        setSuccessMessage("Integration Initialized Successfully.");
        setSelectedIntegration((prevState) => {
          return {
            name: data?.type,
            code: undefined,
            id: data?.id,
          };
        });
        queryClient.invalidateQueries("getDayView");
        queryClient.invalidateQueries("getCalendars");
        queryClient.invalidateQueries("getSettings");
        queryClient.invalidateQueries("getTodos");

        if (!onboarded) {
          queryClient.invalidateQueries("me");
        }
      },
      onError: (error) => {
        console.log(error);
        if (error?.response?.data?.code === 403) {
          errorNotificationWithString(
            "Insufficient access. You need to allow all requested scopes to proceed."
          );
        } else {
          errorNotification(error);
        }
      },
    });

  useEffect(() => {
    if (
      selectedIntegration?.name &&
      selectedIntegration?.code &&
      !selectedIntegration?.id
    ) {
      initIntegration({
        type: selectedIntegration?.name,
        code: selectedIntegration?.code,
      });
    }
  }, [initIntegration, selectedIntegration]);

  const handleSelectIntegration = (integration = null) => {
    setSelectedIntegration(integration);
  };

  const isAStaticIntegration =
    selectedIntegration?.name === "SlackIntegration" ||
    selectedIntegration?.name === "GoogleIntegration";

  const loader = isLoading || isFetching;

  if (
    loader ||
    (selectedIntegration?.name &&
      selectedIntegration?.code &&
      !selectedIntegration?.id) ||
    isInitializingIntegration
  ) {
    return <BoxLoader />;
  }

  if (selectedIntegration?.name && selectedIntegration?.id) {
    return (
      <IntegrationDetails
        name={selectedIntegration?.name}
        isAStaticIntegration={isAStaticIntegration}
        integrationId={selectedIntegration?.id}
        handleSelectIntegration={handleSelectIntegration}
        refetchIndexList={refetch}
      />
    );
  }

  const connectedIntegrations = data?.filter((item) =>
    connectedIntegrationTypes?.includes(item?.type)
  );

  return (
    <div className={`mr-16 ${styles.integrationDetails}`}>
      {connectedIntegrations?.length > 0 && (
        <>
          <Title className="mt-16" level={3}>
            Connected
          </Title>
          <Row className="mb-24 mt-8">
            {connectedIntegrations?.map((connectedIntegration) => (
              <ConnectedCard
                className="connected-integrations-card"
                type={connectedIntegration?.type}
                integrationName={typeMap[connectedIntegration?.type]}
                id={connectedIntegration?.id}
                name={connectedIntegration?.key}
                status={connectedIntegration?.aasm_state}
                statusType={getStatusType(connectedIntegration?.aasm_state)}
                handleSelectIntegration={handleSelectIntegration}
              />
            ))}
          </Row>{" "}
        </>
      )}
      <Title level={3}>{title}</Title>
      <Text className="block-display">{description}</Text>
      <Row className="mt-8">
        {allIntegrations?.map((integ) => (
          <Card
            className="mr-8 mb-8 width-100-percent cursor-pointer unconnected-integ-card"
            // onClick={() => {
            //   handleSelectIntegration({ name: integ, id: null });
            // }}
          >
            <ElectronIntegrationsButton
              isGoogle={integ?.name === "Google"}
              redirectParam={integ?.name}
              name={integ?.name}
              oauthURL={oauthURLs[integ?.name?.toLowerCase()]}
            />
            {integ?.benefits?.map((benefit, index) => (
              <Row className="mb-16">
                <Col span={12} className="flex-display align-items-center">
                  <div className={styles.integrationBenefitTextContainer}>
                    <span className={styles.integrationBenefitSlNo}>
                      {index + 1}
                    </span>
                    <Text className="block-display ml-16">{benefit?.text}</Text>
                  </div>
                </Col>
                <Col span={12} className="flex-display justify-content-center">
                  {benefit?.src && (
                    <img
                      src={benefit?.src}
                      alt="benefit"
                      {...benefit?.srcProps}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </Card>
        ))}
      </Row>
    </div>
  );
};

Integrations.propTypes = {};

export default Integrations;
