import React from "react";
// import PropTypes from 'prop-types';
import { Row, Typography, Select, Input, Radio, Space, DatePicker } from "antd";

import WeekButtons from "./WeekButtons";
import { shortDateFormatWithWeekDay } from "app/appConstants";
import styles from "./ScheduleTodo.module.css";

const { Option } = Select;
const { Text } = Typography;

const ScheduleTodo = ({
  periodicity = "",
  ends = "",
  selectedDays = [],
  monthlyDays = "",
  onMonth = "",
  setEnds = () => {},
  setPeriodicity = () => {},
  setOnMonth = () => {},
  setSelectedDays = () => {},
  setMonthlyDays = () => {},
}) => {
  const handleChangePeriodicity = (val) => {
    if (val === "weekly") {
      setMonthlyDays("");
    } else {
      setSelectedDays([]);
    }
    setPeriodicity(val);
  };

  const handleOnMonth = (val) => {
    setOnMonth(val);
  };

  const handleEnds = (val) => {
    setEnds(val);
  };

  const handleChangeMonthlyDays = (e) => {
    setMonthlyDays(e?.target?.value);
  };

  const focusElement = (focusItem = "") => {
    document.getElementById(focusItem).focus();
  };

  return (
    <div className={`width-280-px p-8 px-8 ${styles?.scheduleTodoBody}`}>
      <Row className="width-100-percent" justify="space-between" align="middle">
        <Text strong>Repeats every</Text>
      </Row>

      <Select
        size="small"
        value={periodicity}
        onChange={handleChangePeriodicity}
      >
        <Option key="weekly" value="weekly">
          week
        </Option>
        <Option key="monthly" value="monthly">
          month
        </Option>
      </Select>

      {periodicity === "weekly" ? (
        <div className="mt-8 mb-8">
          <WeekButtons value={selectedDays} onChange={setSelectedDays} />
        </div>
      ) : (
        <div className="mt-8">
          <Input
            id="schedule-month-input"
            size="small"
            placeholder="enter dates: 6, 26"
            value={monthlyDays}
            onClick={() => focusElement("schedule-month-input")}
            onChange={handleChangeMonthlyDays}
          />
          <Text type="secondary" italic={true} className="font-size-12">
            Note: Last date will be used if month does not have date
          </Text>
        </div>
      )}

      <Text strong className="block-display">
        Ends
      </Text>

      <Space direction="vertical">
        <Radio
          checked={ends === "never"}
          onClick={() => {
            handleEnds("never");
          }}
        >
          Never
        </Radio>
        <Radio
          value={"on"}
          checked={ends === "on"}
          onClick={() => {
            handleEnds("on");
          }}
        >
          <DatePicker
            size="small"
            format={shortDateFormatWithWeekDay}
            value={onMonth}
            onChange={handleOnMonth}
          />
        </Radio>
      </Space>
    </div>
  );
};

ScheduleTodo.propTypes = {};

export default ScheduleTodo;
