import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { Row, Col } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import { EllipseText } from "common/components";
import { useDoneTodoMessage, useAssistsState } from "store";
import { useSaveTodo } from "common/hooks";
import UpdateTodoModal from "./components/UpdateTodoModal";
import TodoStatusRadio from "./components/TodoStatusRadio";
import TodoActions from "./components/TodoActions";
import TodoDueDate from "./components/TodoDueDate";
import DWSessionTrackerModal from "app/home/todos/categories-list/todo/dw-sessions-tracker-modal/DWSessionTrackerModal";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { clearAssistApi } from "app/assists/assistsApi";
import { errorNotification } from "app/appUtils";
import { saveTodoOnSucess } from "app/home/todos/categories-list/todo/TodoSuccessHandlers";
import { getTimeSpentToday } from "app/home/todos/todoUtils";

import { ReactComponent as NewLearningsIcon } from "assets/new_learnings.svg";

import styles from "app/home/todos/Todos.module.css";

const Todo = ({
  loader = false,
  isGoal = false,
  dueDateFilter = "",
  shouldShowTime = false,
  todo = {},
  goalId = null,
  categories = [],
  refetchTodos = () => {},
  handleCreateDWNow = () => {},
}) => {
  const queryClient = useQueryClient();

  // Global State
  const [, setDoneTodoMsg] = useDoneTodoMessage();
  const [assists] = useAssistsState();

  // Component State
  const [isDWSessionTrackerVisible, setIsDWSessionTrackerVisible] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdateTodoTimeModalVisible, setIsUpdateTodoTimeModalVisible] =
    useState(false);
  const [isJustCrossed, setIsJustCrossed] = useState(false);

  // Mutation
  const { mutate: saveTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      saveTodoOnSucess({
        queryClient,
        data,
        priorityAssist,
        priorityAssistExists,
        variables,
        handleCreateDWNow,
        clearAssist,
      });
    },
    onError: (err) => {
      refetchTodos();
      errorNotification(err);
    },
  });

  const { mutate: clearAssist } = useMutation(clearAssistApi, {
    onError: (err) => {
      errorNotification(err);
    },
  });

  // Event Handlers
  const handleEdit = () => {
    if (isTempTodo) {
      return;
    }

    setIsEdit(!isEdit);
  };

  const handleCreateDWNowWrapper = (event, extraParams) => {
    if (isTempTodo) {
      return;
    }

    handleCreateDWNow(event, extraParams);
  };

  const handleUpdateTodoTimeModal = () => {
    setIsUpdateTodoTimeModalVisible(!isUpdateTodoTimeModalVisible);
  };

  const handleIsDWSessionTrackerVisible = () => {
    if (isTempTodo) {
      return;
    }

    setIsDWSessionTrackerVisible(!isDWSessionTrackerVisible);
  };

  // Component Logic
  const priorityAssist = assists?.filter(
    (assist) => assist?.type === "SetPriorityAssist"
  );
  const priorityAssistExists = priorityAssist?.length > 0;
  const isPriority = todo?.priority;
  const isNewLearnings = todo?.new_learnings;
  const isRecurring = Boolean(todo?.recurring);
  const disabled = loader;
  const charLimit = 55;

  const timeSpentToday = getTimeSpentToday(todo?.day_tracks);

  const isTempTodo = todo?.isTempTodo || false;

  if (isEdit) {
    return (
      <div className="mb-8 mr-8">
        <TodoInput
          loader={disabled}
          timeSpentToday={timeSpentToday}
          categories={categories}
          isGoal={isGoal}
          goalId={goalId}
          from="edit"
          setIsEdit={setIsEdit}
          todo={todo}
          handleCreateDWNow={() => {
            handleCreateDWNow(null, {
              todoId: todo?.id,
              todoTitle: todo?.title,
              todoDesc: todo?.description,
            });
          }}
          handleIsDWSessionTrackerVisible={handleIsDWSessionTrackerVisible}
          refetchTodos={refetchTodos}
        />
      </div>
    );
  }

  return (
    <div>
      {isUpdateTodoTimeModalVisible && (
        <UpdateTodoModal
          todo={todo}
          timeSpentToday={timeSpentToday}
          visible={isUpdateTodoTimeModalVisible}
          refetchTodos={refetchTodos}
          onCancel={handleUpdateTodoTimeModal}
        />
      )}
      {isDWSessionTrackerVisible && (
        <DWSessionTrackerModal
          todoId={todo?.id}
          visible={isDWSessionTrackerVisible}
          dayTracks={todo?.day_tracks || []}
          onCancel={handleIsDWSessionTrackerVisible}
          refetchTodos={refetchTodos}
        />
      )}

      <div className={styles.todoContainer}>
        <div className={styles.todoDiv}>
          <Row align="middle" className="width-100-percent mt-4">
            <Col span={2} className={styles.todoTickIconCol}>
              <TodoStatusRadio
                disabled={isTempTodo}
                todo={todo}
                saveTodo={saveTodo}
                setDoneTodoMsg={setDoneTodoMsg}
                setIsJustCrossed={setIsJustCrossed}
              />
            </Col>
            <Col span={22} className={styles.todoMainCol}>
              <div className="width-100-percent flex-display justify-content-space-between">
                <div
                  className={
                    isJustCrossed
                      ? `${styles.strikeText} ${styles.todoContent}`
                      : `${styles.todoContent}`
                  }
                >
                  {isPriority && <span className={styles.todoTitleContent} />}
                  {isNewLearnings && (
                    <NewLearningsIcon className={isPriority ? "ml-4" : ""} />
                  )}
                  {isRecurring && (
                    <RetweetOutlined
                      className={`ml-4 font-size-18 ${styles.repeatIcon}`}
                    />
                  )}
                  <EllipseText
                    integrationLink={todo?.integration_todo_url || ""}
                    className={styles.mainText}
                    baseTextClassName={"word-break-break-all hyphenate-auto"}
                    text={todo?.title}
                    characterLimit={charLimit}
                    tooltipOnMore={true}
                    shouldDisplayLink={true}
                  />

                  <div>
                    <EllipseText
                      className={`${styles.description} mt-4`}
                      text={todo?.description}
                      shouldDisplayTooltip={false}
                      characterLimit={charLimit}
                      shouldDisplayLink={true}
                    />
                  </div>
                </div>
                <TodoActions
                  disabled={isTempTodo}
                  shouldShowTime={shouldShowTime}
                  timeSpentToday={timeSpentToday}
                  todo={todo}
                  handleEdit={handleEdit}
                  handleCreateDWNow={handleCreateDWNowWrapper}
                  handleIsDWSessionTrackerVisible={
                    handleIsDWSessionTrackerVisible
                  }
                />
              </div>
              <TodoDueDate todo={todo} dueDateFilter={dueDateFilter} />
            </Col>
            <Col span={4} className={styles.actionsCol}>
              <div></div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
Todo.propTypes = {};

export default Todo;
