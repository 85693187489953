import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import moment from "moment";
import { Typography, Input, DatePicker, Button, Row, Modal } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { ButtonWithSpinner, InlineSpinner } from "common/components";
import { saveGoalApi, deleteGoalApi } from "app/goals/goalsApi";
import { dateFormat } from "app/appConstants";
import { errorNotification, errorNotificationWithString } from "app/appUtils";

const { Text } = Typography;
const { confirm } = Modal;

const SaveGoal = ({
  goal = {},
  showDesc = true,
  showCancel = true,
  showDelete = false,
  saveTxt = "Save Goal",
  handleCancel = () => {},
  onSave = () => {},
  refetch = () => {},
  onCancel = () => {},
}) => {
  const [name, setName] = useState(goal?.name || "");
  const [motivation, setMotivation] = useState(goal?.motivation || "");
  const [targetDate, setTargetDate] = useState(
    goal?.target_date ? moment(goal?.target_date, dateFormat) : null
  );

  const { mutate: saveGoal, isLoading: isSavingGoal } = useMutation(
    saveGoalApi,
    {
      onSuccess: () => {
        refetch();
        handleCancel();
        onSave();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const { mutate: deleteGoal, isLoading: isDeletingGoal } = useMutation(
    deleteGoalApi,
    {
      onSuccess: () => {
        refetch();
        handleCancel();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleAddGoal = () => {
    if (!motivation) {
      errorNotificationWithString("Motivation cant be empty");
    } else {
      saveGoal({
        goal: {
          id: goal?.id,
          name,
          motivation,
          target_date: targetDate?.format(dateFormat),
        },
      });
    }
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this goal (${name || ""}).`,
      onOk() {
        deleteGoal(goal?.id);
      },
      okText: "Delete",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleNameChange = (e) => {
    setName(e?.target?.value);
  };

  const handleMotivationChange = (e) => {
    setMotivation(e?.target?.value);
  };

  return (
    <div>
      {showDesc && (
        <div className="mb-8 flex-display justify-content-flex-start align-items-center">
          <CheckCircleOutlined className="text-successs" />
          <Text className="ml-8">
            What is the next big thing you want to achieve in the next 3 months?
          </Text>
        </div>
      )}
      <div className="flex-display align-items-center">
        <Text className="width-40-px">I will</Text>
        <Input
          className="width-250-px"
          size="small"
          value={name}
          placeholder="ex: Launch my startup idea"
          onChange={handleNameChange}
        />
      </div>
      <div className="flex-display align-items-center mt-16">
        <Text className="width-40-px">by</Text>
        <DatePicker
          className="ml-0"
          size="small"
          placeholder="June 30, 2023"
          value={targetDate}
          onChange={setTargetDate}
        />
      </div>

      {showDesc && (
        <div className="mt-16 mb-8 flex-display justify-content-flex-start align-items-center">
          <CheckCircleOutlined className="text-successs" />
          <Text className="ml-8">Motivation - Why is this a worthy goal?</Text>
        </div>
      )}

      <Input.TextArea
        size="small"
        value={motivation}
        placeholder=""
        onChange={handleMotivationChange}
      />

      <Row align="middle" className="mt-8 mb-16" justify="space-between">
        <div>
          <ButtonWithSpinner
            isSpinning={isSavingGoal}
            type="primary"
            ghost={true}
            size="small"
            onClick={handleAddGoal}
          >
            {saveTxt}
          </ButtonWithSpinner>

          {showCancel && (
            <Button className="ml-8" size="small" onClick={handleCancel}>
              Cancel
            </Button>
          )}
        </div>

        {showDelete && (
          <Button danger onClick={handleDelete}>
            {isDeletingGoal ? <InlineSpinner /> : <DeleteOutlined />}
          </Button>
        )}
      </Row>
    </div>
  );
};

SaveGoal.propTypes = {};

export default SaveGoal;
