import React from "react";
import { Empty, Typography } from "antd";

import Goal from "app/goals/components/Goal";
import Category from "./Category";
import { getTotalRemainingTodos, getPendingGoals } from "../todoUtils";

import { ReactComponent as OkEmoji } from "assets/ok_emoji.svg";
import { ReactComponent as QuestionMarkEmoji } from "assets/question_mark_emoji.svg";

const { Text } = Typography;

const CategoriesList = ({
  loader = false,
  isSavingTodo = false,
  dueDateFilter = "today",
  searchStr = "",
  tomorrowTabDate = null,
  appDate = null,
  isDateTomorrow = false,
  goals = [],
  dailyCategorySummary = {},
  categories = [],
  categoriesWithTodos = [],
  refetchTodos = () => {},
  handleCreateDWNow = () => {},
  saveTodo = () => {},
}) => {
  const totalRemainingTodos = getTotalRemainingTodos(
    categoriesWithTodos,
    dueDateFilter,
    searchStr,
    appDate,
    tomorrowTabDate
  );

  const pendingGoals = getPendingGoals(
    goals,
    searchStr,
    dueDateFilter === "tomorrow" ? tomorrowTabDate : appDate
  );

  if (totalRemainingTodos === 0 && pendingGoals?.length === 0 && !loader) {
    return (
      <Empty
        image={dueDateFilter === "today" ? <OkEmoji /> : <QuestionMarkEmoji />}
        description={
          searchStr !== "" ? (
            <Text strong>No todos </Text>
          ) : dueDateFilter === "today" ? (
            <Text strong>All done for Today</Text>
          ) : dueDateFilter === "tomorrow" && isDateTomorrow ? (
            <Text strong>
              Nothing planned for Tomorrow{" "}
              <Text className="text-primary block-display" strong>
                Yet
              </Text>
            </Text>
          ) : (
            "Nothing planned"
          )
        }
      />
    );
  }

  return (
    <div>
      {categoriesWithTodos?.map((category, index) => {
        return (
          <Category
            loader={loader}
            isSavingTodo={isSavingTodo}
            dueDateFilter={dueDateFilter}
            tomorrowTabDate={tomorrowTabDate}
            category={category}
            categories={categories}
            dailyCategorySummary={dailyCategorySummary}
            searchStr={searchStr}
            appDate={appDate}
            saveTodo={saveTodo}
            refetchTodos={refetchTodos}
            handleCreateDWNow={handleCreateDWNow}
          />
        );
      })}

      {pendingGoals?.map((goal) => {
        return (
          <Goal
            tomorrowTabDate={tomorrowTabDate}
            dueDateFilter={dueDateFilter}
            isFromMenu={true}
            goal={goal}
            showAddTodo={false}
            refetch={refetchTodos}
          />
        );
      })}
    </div>
  );
};

CategoriesList.propTypes = {};

export default CategoriesList;
