import React from "react";
import { Typography } from "antd";

import { httpUrlRegex, wwwUrlRegex } from "app/appConstants";
import { errorNotificationWithString, getIsElectron } from "app/appUtils";

const { Link } = Typography;

const DisplayTextWithLink = ({ text = "" }) => {
  if (!text) return text;
  const allMatches = [
    ...text.matchAll(httpUrlRegex),
    ...text.matchAll(wwwUrlRegex),
  ];

  const isElectron = getIsElectron();

  let linkEnd = 0;

  const handleLinkClick = (link = "") => {
    if (isElectron) {
      if (
        window?.electronDW &&
        window?.electronDW?.shell &&
        window?.electronDW?.shell?.openExternal
      ) {
        // eslint-disable-next-line no-unused-expressions
        window?.electronDW?.shell?.openExternal(link);
      } else {
        errorNotificationWithString("Couldn't open link");
      }
    } else {
      if (link?.startsWith("www")) {
        window.open(`https://${link}`, "_blank");
      } else {
        window.open(link, "_blank");
      }
    }
  };

  return allMatches.length > 0 ? (
    <span>
      {allMatches.map((matchArray) => {
        const link = matchArray[0];
        const startText = text.slice(linkEnd, text.indexOf(link, linkEnd));
        linkEnd = text.indexOf(link, linkEnd) + link.length;

        return (
          <span>
            {startText}
            <Link
              onClick={() => {
                handleLinkClick(link);
              }}
            >
              {link}
            </Link>
          </span>
        );
      })}
      <span>{text.slice(linkEnd)}</span>
    </span>
  ) : (
    text
  );
};

DisplayTextWithLink.propTypes = {};

export default DisplayTextWithLink;
