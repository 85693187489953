import React, { useState, useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { DatePicker } from "antd";

import { ColumnChart, BoxLoader } from "common/components";
import InsightsTimeBreakdown from "./InsightsTimeBreakdown";
import InsightsHorizontalChart from "./InsightsHorizontalChart";
import InsightsDetails from "./InsightsDetails";
import { getPeriodInsightsApi } from "app/insights/insightsApi";
import { dateFormat, displayShortDateFormatString } from "app/appConstants";
import { errorNotification, getIsElectron } from "app/appUtils";

const { RangePicker } = DatePicker;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const SharableInsights = ({ setView = () => {} }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const from = urlParams?.get("from");
  const to = urlParams?.get("to");

  const [showDetails, setShowDetails] = useState(false);
  const [series, setSeries] = useState([
    { name: "Focus", data: [] },
    { name: "Meeetings", data: [] },
  ]);
  const [colorMap, setColorMap] = useState({});
  const [xCategories, setXCategories] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    if (from && moment(from, dateFormat)?.isValid()) {
      setFromDate(from);
    } else {
      setFromDate(moment()?.subtract(7, "days")?.format(dateFormat));
    }
  }, [from]);

  useEffect(() => {
    if (to && moment(to, dateFormat)?.isValid()) {
      setToDate(to);
    } else {
      setToDate(moment()?.format(dateFormat));
    }
  }, [to]);

  const {
    data: periodInsights = {},
    isLoading: isPeriodInsightsLoading,
    // isFetching: isFetchingInsights,
  } = useQuery(["getPeriodInsights", fromDate, toDate], getPeriodInsightsApi, {
    enabled: Boolean(fromDate && toDate),
    onSuccess: (data) => {
      const perDayTimes = data?.per_day_times || [];
      let tempDate = fromDate;
      const seriesData = [
        { name: "Focus", data: [] },
        { name: "Meetings", data: [] },
      ];
      const tempXCategories = [];

      while (
        moment(tempDate, dateFormat)?.isSameOrBefore(moment(toDate, dateFormat))
      ) {
        const timeValues = perDayTimes[tempDate] || [0, 0, false];
        seriesData[0].data.push(
          timeValues[0] > 60 ? Math.round(timeValues[0] / 60) : 0
        );
        seriesData[1].data.push(
          timeValues[1] > 60 ? Math.round(timeValues[1] / 60) : 0
        );
        tempXCategories.push(
          moment(tempDate, dateFormat)?.format("dddd").substring(0, 3)
        );
        tempDate = moment(tempDate, dateFormat)
          ?.add(1, "day")
          ?.format(dateFormat);
      }
      setSeries(seriesData);
      setXCategories(tempXCategories);

      const tempColorMap = {};
      data.category_info.forEach((category) => {
        tempColorMap[category?.name] = category?.color_formula;
      });
      setColorMap(tempColorMap);
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleDateChange = ([start, end]) => {
    setFromDate(start);
    setToDate(end);
  };

  const isElectron = getIsElectron();

  const isWebPage = !isChromeExtension && !isElectron;

  return (
    <div className={`flex-display ${isWebPage && "web-main-content-styles"} `}>
      <div
        className="ml-8 mr-8 mb-8"
        style={{
          width: "360px",
          border: isWebPage ? "1px solid grey" : undefined,
          backgroundColor: isWebPage ? "white" : undefined,
          maxHeight: isWebPage ? "90vh" : undefined,
          overflowY: isWebPage ? "auto" : undefined,
          paddingBottom: isWebPage ? "8px" : undefined,
          paddingTop: "0px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {isPeriodInsightsLoading ? (
          <BoxLoader />
        ) : (
          <div>
            <div className="flex-display justify-content-center mt-16">
              <RangePicker
                value={[
                  moment(fromDate, dateFormat),
                  moment(toDate, dateFormat),
                ]}
                format={displayShortDateFormatString}
                disabledDate={(current) =>
                  current && current?.isAfter(moment())
                }
                onChange={handleDateChange}
              />
            </div>

            <InsightsTimeBreakdown data={periodInsights} />

            <InsightsHorizontalChart
              insights={periodInsights}
              colorMap={colorMap}
            />

            <ColumnChart series={series} xCategories={xCategories} />
            <InsightsDetails
              insights={periodInsights}
              colorMap={colorMap}
              showDetails={showDetails}
              handleShowDetails={handleShowDetails}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SharableInsights.propTypes = {};

export default SharableInsights;
