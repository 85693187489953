import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { getIsElectron } from "app/appUtils";
import { iconMapUnconnected } from "app/appConstants";
import GoogleSignIn from "assets/google_sign_in.png";

const ElectronIntegrationsButton = ({
  isGoogle = false,
  redirectParam = "",
  oauthURL = "",
  name = "",
  btnSize = "",
  btnTxt = "",
  className = "",
}) => {
  const isElectron = getIsElectron();

  const handleRedirect = (e) => {
    if (isElectron) {
      e.preventDefault();
      if (
        window?.electronDW &&
        window?.electronDW?.shell &&
        window?.electronDW?.shell?.openExternal
      ) {
        if (oauthURL) {
          localStorage.setItem("integrationTab", redirectParam);
        }
        // eslint-disable-next-line no-unused-expressions
        window?.electronDW?.shell?.openExternal(
          `${
            process.env.REACT_APP_ELECTRON_AUTH_URL
          }/integrations/open-from-electron?redirectURL=${encodeURIComponent(
            oauthURL
          )}`
        );
      }
    } else {
      if (oauthURL) {
        localStorage.setItem("integrationTab", redirectParam);
      }

      window.location = oauthURL;
    }
  };

  return (
    <div>
      {/* <Alert message="In order to integrate we have to redirect you to the web view, please click on the link below" /> */}
      {isGoogle ? (
        <img
          className="cursor-pointer"
          src={GoogleSignIn}
          alt="google"
          onClick={handleRedirect}
        />
      ) : (
        <Button
          className={className}
          size={btnSize || "middle"}
          type="primary"
          ghost={true}
          onClick={handleRedirect}
        >
          <div className="flex-display align-items-center">
            {iconMapUnconnected[name]}
            {name}
          </div>
        </Button>
      )}
    </div>
  );
};

ElectronIntegrationsButton.propTypes = {
  redirectParam: PropTypes.string,
};

export default ElectronIntegrationsButton;
