import React from "react";
import Redirect from "./Redirect";

const SlackRedirect = (props) => {
  return <Redirect name="SlackIntegration" />;
};

SlackRedirect.propTypes = {};

export default SlackRedirect;
