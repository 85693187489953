import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const OpenIntegrationsFromElectron = (props) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const redirectURL = query.get("redirectURL");

  useEffect(() => {
    localStorage.setItem("isFromElectron", true);
    if (redirectURL) {
      window.location = redirectURL;
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

OpenIntegrationsFromElectron.propTypes = {};

export default OpenIntegrationsFromElectron;
