import moment from "moment";

import { dateTimeFormat } from "app/appConstants";

export const getDayViewOnSuccess = ({
  wasHomeSelectedWhileFocusing = false,
  data,
  setSelectedTab = () => {},
  handleSelectedEvent = () => {},
}) => {
  if (data?.active_time_slot?.id) {
    handleSelectedEvent({
      ...data?.active_time_slot,
      start: moment(
        data?.active_time_slot?.from_time_utc,
        dateTimeFormat
      )?.toDate(),
      end: moment(
        data?.active_time_slot?.to_time_utc,
        dateTimeFormat
      )?.toDate(),
    });

    if (!wasHomeSelectedWhileFocusing) {
      setSelectedTab("execute");
    }
  }
};
