import React from "react";

import CategoryLabel from "./components/CategoryLabel";

export const categoryOptions = [
  {
    key: "rgb(184, 37, 95)",
    value: "berry-red",
    label: (
      <CategoryLabel label="Berry Red" backgroundColor="rgb(184, 37, 95)" />
    ),
  },
  {
    value: "red",
    key: "rgb(219, 64, 53)",
    label: <CategoryLabel label="Red" backgroundColor="rgb(219, 64, 53)" />,
  },
  {
    value: "orange",
    key: "rgb(255, 153, 51)",
    label: <CategoryLabel label="Orange" backgroundColor="rgb(255, 153, 51)" />,
  },
  {
    value: "yellow",
    key: "rgb(250, 208, 0)",
    label: <CategoryLabel label="Yellow" backgroundColor="rgb(250, 208, 0)" />,
  },
  {
    value: "olive-green",
    key: "rgb(175, 184, 59)",
    label: (
      <CategoryLabel label="Olive Green" backgroundColor="rgb(175, 184, 59)" />
    ),
  },
  {
    value: "lime-green",
    key: "rgb(126, 204, 73)",
    label: (
      <CategoryLabel label="Lime Green" backgroundColor="rgb(126, 204, 73)" />
    ),
  },
  {
    value: "green",
    key: "rgb(41, 148, 56)",
    label: <CategoryLabel label="Green" backgroundColor="rgb(41, 148, 56)" />,
  },
  {
    value: "mint-green",
    key: "rgb(106, 204, 188)",
    label: (
      <CategoryLabel label="Mint Green" backgroundColor="rgb(106, 204, 188)" />
    ),
  },
  {
    value: "teal",
    key: "rgb(21, 143, 173)",
    label: <CategoryLabel label="Teal" backgroundColor="rgb(21, 143, 173)" />,
  },
  {
    value: "sky-blue",
    key: "rgb(20, 170, 245)",
    label: (
      <CategoryLabel label="Sky Blue" backgroundColor="rgb(20, 170, 245)" />
    ),
  },
  {
    value: "blue",
    key: "rgb(64, 115, 255)",
    label: <CategoryLabel label="Blue" backgroundColor="rgb(64, 115, 255)" />,
  },
  {
    value: "grape",
    key: "rgb(136, 77, 255)",
    label: <CategoryLabel label="Grape" backgroundColor="rgb(136, 77, 255)" />,
  },
  {
    value: "violet",
    key: "rgb(175, 56, 235)",
    label: <CategoryLabel label="Violet" backgroundColor="rgb(175, 56, 235)" />,
  },
  {
    value: "lavender",
    key: "rgb(235, 150, 235)",
    label: (
      <CategoryLabel label="Lavender" backgroundColor="rgb(235, 150, 235)" />
    ),
  },
  {
    value: "magenta",
    key: "rgb(224, 81, 148)",
    label: (
      <CategoryLabel label="Magenta" backgroundColor="rgb(224, 81, 148)" />
    ),
  },
  {
    value: "salmon",
    key: "rgb(255, 141, 133)",
    label: (
      <CategoryLabel label="Salmon" backgroundColor="rgb(255, 141, 133)" />
    ),
  },
  {
    value: "taupe",
    key: "rgb(204, 172, 147)",
    label: <CategoryLabel label="Taupe" backgroundColor="rgb(204, 172, 147)" />,
  },
];
