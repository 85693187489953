import { atom, useRecoilState } from "recoil";
import moment from "moment";

import { dateFormat } from "app/appConstants";

// The date the app is running on.
const appDateState = atom({
  key: "appDate",
  default: moment().format(dateFormat),
});

const useAppDate = () => {
  const [appDate, setAppDate] = useRecoilState(appDateState);
  return [appDate, setAppDate];
};

// State that captures the Refresh Ts so that data can be refetched.
const appRefreshTsState = atom({
  key: "appRefreshTs",
  default: moment(),
});

const useAppRefreshTs = () => {
  const [appRefreshTs, setAppRefreshTs] = useRecoilState(appRefreshTsState);
  return [appRefreshTs, setAppRefreshTs];
};

const currentUserState = atom({
  key: "currentUserState",
  default: null,
  dangerouslyAllowMutability: true,
});

const useCurrentUser = () => {
  const [user, setUser] = useRecoilState(currentUserState);
  return [user, setUser];
};

const isUserSigningOutState = atom({
  key: "isUserSigningOut",
  default: false,
});

const useIsUserSigningOut = () => {
  const [isUserSigningOut, setIsUserSigningOut] = useRecoilState(
    isUserSigningOutState
  );

  return [isUserSigningOut, setIsUserSigningOut];
};

// TODO: Do we really need this state? Can it not be inferred from currentUser?
const isUserRegisteredState = atom({
  key: "isUserRegistered",
  default: false,
});

const useIsUserRegisteredState = () => {
  const [isUserRegistered, setIsUserRegistered] = useRecoilState(
    isUserRegisteredState
  );

  return [isUserRegistered, setIsUserRegistered];
};

const selectedIntegrationState = atom({
  key: "selectedIntegration",
  default: null,
});

const useSelectedIntegrationState = () => {
  const [selectedIntegration, setSelectedIntegration] = useRecoilState(
    selectedIntegrationState
  );

  return [selectedIntegration, setSelectedIntegration];
};

// TODO: Used in Initial Signup Video. Remove if the functionality is removed.
const isUserFromSignUpState = atom({
  key: "isUserFromSignUp",
  default: false,
});

const useIsUserFromSignUp = () => {
  const [isUserFromSignUp, setIsUserFromSignUp] = useRecoilState(
    isUserFromSignUpState
  );

  return [isUserFromSignUp, setIsUserFromSignUp];
};

const isFetchingUserState = atom({
  key: "isFetchingUser",
  default: true, // process.env.REACT_APP_MOCK_ENV !== "mirage-start"
});

const useIsFetchingUser = () => {
  const [isFetchingUser, setIsFetchingUser] =
    useRecoilState(isFetchingUserState);

  return [isFetchingUser, setIsFetchingUser];
};

const successMessageState = atom({
  key: "successMessage",
  default: "",
});

const useSuccessMessageStateState = () => {
  const [successMessage, setSuccessMessage] =
    useRecoilState(successMessageState);

  return [successMessage, setSuccessMessage];
};

const onboardedState = atom({
  key: "onboarded",
  default: true,
});

const useOnboardedState = () => {
  const [onboarded, setOnboarded] = useRecoilState(onboardedState);

  return [onboarded, setOnboarded];
};

const userPointsState = atom({
  key: "userPoints",
  default: 0,
});

const useUserPointsState = () => {
  const [userPoints, setUserPoints] = useRecoilState(userPointsState);

  return [userPoints, setUserPoints];
};

const userSettingsState = atom({
  key: "userSettings",
  default: null,
});

const useUserSettings = () => {
  const [userSettings, setUserSettings] = useRecoilState(userSettingsState);

  return [userSettings, setUserSettings];
};

const userIntegrationInfoState = atom({
  key: "userIntegrationInfo",
  default: null,
});

const useUserIntegrationInfo = () => {
  const [userIntegrationInfo, setUserIntegrationInfo] = useRecoilState(
    userIntegrationInfoState
  );

  return [userIntegrationInfo, setUserIntegrationInfo];
};

const lastSyncedTimeState = atom({
  key: "lastSyncedTime",
  default: null,
});

const useLastSyncedTimeState = () => {
  const [lastSyncedTime, setLastSyncedTime] =
    useRecoilState(lastSyncedTimeState);

  return [lastSyncedTime, setLastSyncedTime];
};

const doneTodoMessageState = atom({
  key: "doneTodoMessage",
  default: "",
});

const useDoneTodoMessage = () => {
  const [doneTodoMsg, setDoneTodoMsg] = useRecoilState(doneTodoMessageState);

  return [doneTodoMsg, setDoneTodoMsg];
};

const insightsDefaultTabState = atom({
  key: "insightsDefaultTab",
  default: "",
});

const useInsightsDefaultTabState = () => {
  const [defaultTab, setDefaultTab] = useRecoilState(insightsDefaultTabState);

  return [defaultTab, setDefaultTab];
};

const isAppPinnedState = atom({
  key: "isAppPinned",
  default: false,
});

const useIsAppPinnedState = () => {
  const [isAppPinned, setIsAppPinned] = useRecoilState(isAppPinnedState);

  return [isAppPinned, setIsAppPinned];
};

const isAppExpandedState = atom({
  key: "isAppExpanded",
  default: false,
});

const useIsAppExpandedState = () => {
  const [isAppExpanded, setIsAppExpanded] = useRecoilState(isAppExpandedState);

  return [isAppExpanded, setIsAppExpanded];
};

const enabledFeaturesState = atom({
  key: "enabledFeatures",
  default: {},
});

const useEnabledFeaturesState = () => {
  const [enabledFeatures, setEnabledFeatures] =
    useRecoilState(enabledFeaturesState);

  return [enabledFeatures, setEnabledFeatures];
};

const selectedEventState = atom({
  key: "selectedEvent",
  default: null,
});

const useSelectedEventState = () => {
  const [selectedEvent, setSelectedEvent] = useRecoilState(selectedEventState);

  return [selectedEvent, setSelectedEvent];
};

const assistsState = atom({
  key: "assistsState",
  default: null,
});

const useAssistsState = () => {
  const [assists, setAssists] = useRecoilState(assistsState);

  return [assists, setAssists];
};

export {
  useAppDate,
  useAppRefreshTs,
  useCurrentUser,
  useIsUserSigningOut,
  useIsUserRegisteredState,
  useIsUserFromSignUp,
  useIsFetchingUser,
  useSuccessMessageStateState,
  useSelectedIntegrationState,
  useOnboardedState,
  useUserPointsState,
  useUserSettings,
  useUserIntegrationInfo,
  useLastSyncedTimeState,
  useDoneTodoMessage,
  useInsightsDefaultTabState,
  useIsAppPinnedState,
  useIsAppExpandedState,
  useEnabledFeaturesState,
  useSelectedEventState,
  useAssistsState,
};
