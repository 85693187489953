import React from "react";

import Redirect from "./Redirect";

const JIRARedirect = (props) => {
  return <Redirect name="JiraCloudIntegration" />;
};

JIRARedirect.propTypes = {};

export default JIRARedirect;
