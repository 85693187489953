import axios from "axios";

const getAssistsApi = async () => {
  const { data } = await axios.get("/api/v2/assists");
  return data;
};

const resolveAssistsApi = async ({ assistId, cta, todo_ids, todo_id }) => {
  const { data } = await axios.post(`/api/v2/assists/${assistId}`, {
    cta,
    todo_ids,
    todo_id,
  });
  return data;
};

const clearAssistApi = async ({ assistId }) => {
  const { data } = await axios.post(`/api/v2/assists/${assistId}/clear`);

  return data;
};

export { getAssistsApi, resolveAssistsApi, clearAssistApi };
