import React from "react";
// import PropTypes from "prop-types";
import { Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { BoxLoader } from "common/components";
import { getDailyStatsApi } from "common/apis/appApi";
import { getHoursFromMins } from "app/appUtils";
import { getTotalPlannedMeetingTime } from "./utils";

import { ReactComponent as TimerIcon } from "assets/timer-primary.svg";
import { ReactComponent as CheckCircle } from "assets/check_circle.svg";

import styles from "app/home/Home.module.css";

const { Text } = Typography;

const DailyStats = ({ calendarEvents = [], currentDate }) => {
  const history = useHistory();

  const { data: dailyStats, isLoading: isDailyStatsLoading } = useQuery(
    ["getDailyStats", currentDate],
    getDailyStatsApi
  );

  const handleNavigateToInsights = () => {
    history.push("/insights");
  };

  const totalPlannedMeetingTime = getTotalPlannedMeetingTime(
    calendarEvents || []
  );

  return (
    <>
      {isDailyStatsLoading ? (
        <BoxLoader />
      ) : (
        <div
          className={`flex-display
                        justify-content-space-around ${styles.dailyStats}
                        cursor-pointer
                        `}
          onClick={handleNavigateToInsights}
        >
          {totalPlannedMeetingTime > 0 && (
            <div className="inline-flex-display align-items-center">
              <CalendarOutlined className="text-primary" />
              <Text className="text-primary line-height-1 ml-4">
                {getHoursFromMins(totalPlannedMeetingTime)}
              </Text>
            </div>
          )}
          <div className="inline-flex-display align-items-center">
            <TimerIcon className="text-primary" />
            <Text className="text-primary line-height-1 ml-4">
              {getHoursFromMins(dailyStats?.dw_minutes)}
            </Text>
          </div>
          <div className="inline-flex-display align-items-center">
            <CheckCircle className="text-primary" />
            <Text className="text-primary line-height-1 ml-4">
              {dailyStats?.todos_done}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

DailyStats.propTypes = {};

export default DailyStats;
