import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Row, Space, Input } from "antd";
import { useAutosave } from "react-autosave";

import { TimedMessage } from "common/components";
import { useSaveTodo } from "common/hooks";
import { useIsAppExpandedState } from "store";
import {
  errorNotification,
  handleWindowResizeHeightAndWidth,
} from "app/appUtils";

import { ReactComponent as MiniViewInActive } from "assets/mini_view_grey.svg";
import { ReactComponent as MiniView } from "assets/mini_view.svg";
import { ReactComponent as LargeViewInActive } from "assets/large_view_grey.svg";
import { ReactComponent as LargeView } from "assets/large_view.svg";

const { Text } = Typography;

const NotesInput = ({
  className = "",
  event = {},
  linkedTodo = {},
  handleSelectedEvent,
}) => {
  const [isExpanded, setIsExpanded] = useIsAppExpandedState();
  // const queryClient = useQueryClient();
  const hasDescBeenUpdatedRef = useRef(false);
  const eventQuickPlan = event?.quick_plan;
  const linkedTodoDescription = linkedTodo?.description || "";
  const [description, setDescription] = useState(linkedTodoDescription);

  const { mutate: saveTodo, status: savingTodoStatus } = useSaveTodo({
    onSuccess: (_, variables) => {
      const selectedEventTodos = event?.todos || [];
      const selectedEventTodo = selectedEventTodos[0] || {};
      handleSelectedEvent({
        ...event,
        todos: [
          {
            ...selectedEventTodo,
            description: variables?.todo?.description,
          },
        ],
      });
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleQuickPlanAutoSave = useCallback(
    (description) => {
      if (hasDescBeenUpdatedRef.current) {
        saveTodo({
          todo: {
            ...linkedTodo,
            description: description,
          },
        });
        hasDescBeenUpdatedRef.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventQuickPlan]
  );

  useAutosave({
    data: description,
    onSave: handleQuickPlanAutoSave,
  });

  const handleDescription = (e) => {
    setDescription(e?.target?.value);
    hasDescBeenUpdatedRef.current = true;
  };

  const handleIncreaseSize = () => {
    document.getElementById("notes-section").style.width = "700px";

    setTimeout(() => {
      handleWindowResizeHeightAndWidth(700, 700, true);
    }, 1000);
    setIsExpanded(true);
  };

  const handleDecreaseSize = () => {
    document.getElementById("notes-section").style.width = "370px";

    setTimeout(() => {
      const height = document.body.clientHeight;
      handleWindowResizeHeightAndWidth(height, 370, true);
    }, 1000);
    setIsExpanded(false);
  };

  const minRows = isExpanded ? 18 : 3;
  const maxRows = isExpanded ? 18 : 15;

  return (
    <div className={className}>
      <div className="mt-8 flex-display align-items-center">
        <Text strong>Notes</Text>

        {isExpanded ? (
          <MiniViewInActive
            className="ml-8 font-size-20 cursor-pointer"
            onClick={handleDecreaseSize}
          />
        ) : (
          <MiniView
            className="ml-8 font-size-20 cursor-pointer"
            onClick={handleDecreaseSize}
          />
        )}
        {isExpanded ? (
          <LargeView
            className="ml-8 font-size-20 cursor-pointer"
            onClick={handleIncreaseSize}
          />
        ) : (
          <LargeViewInActive
            className="ml-8 font-size-20 cursor-pointer"
            onClick={handleIncreaseSize}
          />
        )}
      </div>
      <Input.TextArea
        id="notes-section"
        disabled={event?.isTempSession}
        className="block-display font-size-14"
        placeholder="Tip: Break down your Todo into smaller sub tasks"
        autoSize={{ minRows, maxRows }}
        value={description}
        onChange={handleDescription}
      />
      <Row className="mt-8" justify="start">
        <Space style={{ minHeight: "20px" }}>
          {savingTodoStatus === "loading" && <Text>Saving...</Text>}
          {savingTodoStatus === "success" && (
            <TimedMessage message={<Text type="success">Saved</Text>} />
          )}
        </Space>
      </Row>
    </div>
  );
};

NotesInput.propTypes = {
  event: PropTypes.object,
  isFromMenuBar: PropTypes.bool,
};

export default NotesInput;
