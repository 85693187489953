const getHabitsData = () => {
  return {
    average_hours_before_planing: {
      metric: 4.53,
      graph: {
        x_axis: "Date",
        y_axis: "Hours Planned Ahead",
        data: {
          "2021-08-04": 1.53,
          "2021-08-05": 2.24,
          "2021-08-06": 10,
          "2021-08-09": 3.43,
          "2021-08-10": 0.99,
          "2021-08-11": null,
          "2021-07-29": 1.53,
        },
      },
    },
    dw_consistency_average: {
      metric: 5,
      graph: {
        x_axis: "Date",
        y_axis: "DeepWork hours",
        data: {
          "2021-08-04": 5,
          "2021-08-05": 4,
          "2021-08-06": 3,
          "2021-08-10": 2,
          "2021-08-09": 1,
          "2021-08-11": 0,
          "2021-07-29": null,
        },
      },
    },
    week_planed_percentage: {
      metric: 66,
      graph: {
        x_axis: "Week",
        y_axis: "Days planned",
        data: {
          "2021-08-09 - 2021-08-15": 4,
          "2021-08-02 - 2021-08-08": 1,
          "2021-07-26 - 2021-08-01": null,
        },
      },
    },
    shutdown_consistency_percentage: {
      metric: 0,
    },
    deep_ritual_consistency_percentage: {
      metric: 0,
    },
  };
};

export { getHabitsData };
