import React, { useState } from "react";
import { useMutation } from "react-query";
import { Draggable } from "react-beautiful-dnd";
import { Typography, Input, Button, Modal, Select } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DragOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { InlineSpinner } from "common/components";
import { deleteTodoCategoryApi } from "app/settings/categories/todoCategoriesApis";
import { categoryOptions } from "../constants";
import { updateMenu } from "app/appConstants";
import { sendIPCUpdateEvent } from "app/appUtils";

const { Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const Category = ({ category, index, saveTodoCategory, refetch, ...props }) => {
  const [categoryName, setCategoryName] = useState(category?.name);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    key: category?.color_formula,
    value: category?.color_name,
  });

  const { mutate: deleteCategory, isLoading: isDeletingTodoCategory } =
    useMutation(deleteTodoCategoryApi, {
      onSuccess: () => {
        refetch();
        sendIPCUpdateEvent(updateMenu);
      },
    });

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e?.target?.value);
  };

  const handleDelete = (categoryId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this todo category.`,
      onOk() {
        deleteCategory(categoryId);
      },
      okText: "Delete",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handlePressEnter = () => {
    saveTodoCategory({
      todo_category: { id: category?.id, name: categoryName },
      onSuccess: () => {
        handleEdit();
      },
      onError: () => {
        handleEdit();
      },
    });
  };

  if (isEdit) {
    return (
      <div className="m-8 flex-display justify-content-space-between align-items-center">
        <Input
          size="small"
          className="width-80-percent"
          value={categoryName}
          onChange={handleCategoryNameChange}
          onPressEnter={handlePressEnter}
        />
        <Select
          size="small"
          className="width-150-px"
          value={selectedCategory?.value}
          onChange={(value, option) => {
            setSelectedCategory({ value, key: option?.key });
            saveTodoCategory({
              todo_category: {
                id: category?.id,
                color_name: option?.value,
                color_formula: option?.key,
              },
              onSuccess: () => {
                handleEdit();
              },
            });
          }}
        >
          {categoryOptions?.map((option) => (
            <Option key={option?.key} value={option?.value}>
              {option?.label}
            </Option>
          ))}
        </Select>
        <Button size="small" type="primary" ghost={true} onClick={handleEdit}>
          Cancel
        </Button>
      </div>
    );
  }

  return (
    <Draggable
      key={category.id}
      draggableId={category.id.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          key={category?.id}
          {...props}
          style={{
            ...provided.draggableProps.style,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className="m-8 p-8 pl-8 justify-content-space-between"
            style={{ border: "1px solid grey", display: "flex" }}
          >
            <div className="inline-flex align-items-center">
              <span
                className="mr-4"
                style={{
                  height: "12px",
                  width: "12px",
                  backgroundColor: category?.color_formula,
                  display: "inline-block",
                  borderRadius: "50%",
                }}
              />
              <Text>{category?.name}</Text>
            </div>

            <div className="inline-display">
              <EditOutlined onClick={handleEdit} />
              {isDeletingTodoCategory ? (
                <InlineSpinner fontSize={28} />
              ) : (
                <DeleteOutlined
                  className="ml-8 mr-8"
                  onClick={() => handleDelete(category?.id)}
                />
              )}
              <DragOutlined className="mr-8" />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

Category.propTypes = {};

export default Category;
