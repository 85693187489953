import { learningWords } from "app/appConstants";

export const getHasLearningWords = (val) => {
  if (learningWords.some((v) => val?.toLowerCase()?.includes(v))) {
    return true;
  }

  return false;
};

export const handleAddTempTodo = ({
  todo = {},
  todoFields = {},
  categoriesWithTodos = [],
  setCategoriesWithTodos = () => {},
}) => {
  const categoryId = todoFields?.todo_category_id;

  let selectedCategoryWithTodos =
    categoriesWithTodos?.filter((category) => category?.id === categoryId) ||
    [];
  if (selectedCategoryWithTodos?.length > 0) {
    selectedCategoryWithTodos = selectedCategoryWithTodos[0];
  }

  let updatedCategoriesWithTodos = [];

  let updatedTodos = [];

  if (todo?.id) {
    // editing a todo
    const selectedCategoryTodos = selectedCategoryWithTodos?.active_todos || [];

    updatedTodos = selectedCategoryTodos?.map((item) => {
      if (item?.id === todo?.id) {
        return {
          ...todo,
          ...todoFields,
          isTempTodo: true,
        };
      } else {
        return item;
      }
    });
  } else {
    //Adding a todo
    const selectedCategoryTodos = selectedCategoryWithTodos?.active_todos || [];

    updatedTodos = [
      ...selectedCategoryTodos,
      { ...todoFields, isTempTodo: true },
    ];
  }
  updatedCategoriesWithTodos = categoriesWithTodos?.map((category) => {
    if (category?.id === categoryId) {
      return { ...category, active_todos: updatedTodos };
    } else {
      return { ...category };
    }
  });

  setCategoriesWithTodos(updatedCategoriesWithTodos);
};
