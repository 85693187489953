import { useState, useEffect } from "react";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { useUserSettings, useAppDate } from "store";
import {
  dateTimeFormatZ,
  updateMenuBarTitle,
  dateTimeOnly,
  dateFormat,
} from "app/appConstants";
import { sendIPCUpdateEvent, getIsElectron } from "app/appUtils";
import { getAllTimeSlots, getAllFutureTimeSlots } from "./homeUtils";

export const useSetCurrentDate = ({
  setCurrentDate = () => {},
  setTomorrowTabDate = () => {},
}) => {
  const [appDate] = useAppDate();

  // update Current Date state when today changes
  useEffect(() => {
    setCurrentDate(appDate);
    setTomorrowTabDate(
      moment(appDate, dateFormat)?.add(1, "day")?.format(dateFormat)
    );
  }, [appDate, setCurrentDate, setTomorrowTabDate]);
};

export const useIsAfter4PM = () => {
  const [isAfter4PM, setIsAfter4PM] = useState(false);
  const [appDate] = useAppDate();
  const [today4PM, setToday4PM] = useState(
    moment()?.set({ hour: "16", minute: 0 })
  );

  useEffect(() => {
    setToday4PM(moment(appDate, dateFormat)?.set({ hour: "16", minute: 0 }));
  }, [appDate]);

  useEffect(() => {
    const check4PMid = setInterval(() => {
      setIsAfter4PM(moment()?.isAfter(today4PM));
    }, 1000);

    return () => {
      clearInterval(check4PMid);
    };
  }, [setIsAfter4PM, today4PM]);

  return [isAfter4PM];
};

export const useNavigationFromHome = ({ openParam }) => {
  const history = useHistory();

  useEffect(() => {
    if (openParam === "settings") {
      history.push("/settings");
    } else if (openParam === "sidecar") {
      history.push("/sidecar");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openParam]);
};

export const useUpdateMenuTodos = () => {
  const queryClient = useQueryClient();
  const isElectron = getIsElectron();

  const ipcRendererWrapperToUpdateMenuTodos = isElectron
    ? window?.electronDW?.ipcRendererWrapperToUpdateMenuTodos
    : () => {};

  const ipcRendererWrapperToUpdateMenu = isElectron
    ? window?.electronDW?.ipcRendererWrapperToUpdateMenu
    : () => {};

  useEffect(() => {
    ipcRendererWrapperToUpdateMenuTodos((e, args) => {
      if (args?.refreshInit) {
        queryClient.invalidateQueries("appInit");
      }
      queryClient.invalidateQueries("getTodos");
      queryClient.invalidateQueries("getDayView");
    });

    ipcRendererWrapperToUpdateMenu(() => {
      console.log("inside");
      queryClient.invalidateQueries();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useMenuTitleUpdater = ({
  isLoading = false,
  selectedEvent = {},
  todosCount = {},
  shouldShowTodoCount = true,
  currentDate,
  dayViewData = {},
}) => {
  const [settings] = useUserSettings();

  const timeZone = settings?.timezone;
  const selectedEventId = selectedEvent?.id;
  const selectedEventToTime = selectedEvent?.to_time_utc;

  const doneTodosCount = todosCount?.doneTodosCount || 0;
  const pendingTodosTodayCount = todosCount?.pendingTodosTodayCount || 0;
  const totalTodosCount = pendingTodosTodayCount + doneTodosCount || 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const futureEvents = getAllFutureTimeSlots(
        getAllTimeSlots(currentDate, dayViewData?.calendar_events || [])
      );
      const closestEvent = futureEvents[0];

      const closestEventStartTimeDiff = moment(
        closestEvent?.record?.from_time_utc,
        dateTimeFormatZ
      )?.diff(moment(), "minutes");

      let closestEventTitle = closestEvent?.record?.title;
      closestEventTitle =
        closestEventTitle?.length > 15
          ? `${closestEventTitle?.slice(0, 15)}...`
          : closestEventTitle || "";

      if (!isLoading) {
        if (closestEventStartTimeDiff <= 5 && closestEventStartTimeDiff >= 0) {
          sendIPCUpdateEvent(updateMenuBarTitle, {
            title: `${closestEventTitle} in ${closestEventStartTimeDiff} m`,
          });
        } else if (selectedEventId && selectedEvent?.is_active) {
          const toTimeMoment = moment(
            selectedEvent?.to_time_utc,
            dateTimeFormatZ
          )?.tz(timeZone);

          const isChromeExtension =
            process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
          if (isChromeExtension) {
            // eslint-disable-next-line no-undef
            chrome.runtime.sendMessage({
              toTime: toTimeMoment?.format(dateTimeOnly),
            });
          }

          if (moment()?.isAfter(toTimeMoment)) {
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: "Session Overshot",
              isDWing: true,
            });
          } else if (
            moment()?.isBefore(toTimeMoment) &&
            toTimeMoment?.diff(moment(), "minutes") === 0
          ) {
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: "less than a min left",
              isDWing: true,
            });
          } else if (
            moment()?.isBefore(toTimeMoment) &&
            toTimeMoment?.diff(moment(), "minutes") <= 5 &&
            toTimeMoment?.diff(moment(), "minutes") !== 0
          ) {
            const minsLeft = toTimeMoment?.diff(moment(), "minutes");
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: `${minsLeft} m to end session`,
              isDWing: true,
            });
          } else {
            const minsLeft = toTimeMoment?.diff(moment(), "minutes");

            let selectedEventTitle = selectedEvent?.title;
            if (selectedEventTitle?.length > 12) {
              selectedEventTitle = `${selectedEventTitle?.slice(0, 12)}...`;
            }
            selectedEventTitle = `${selectedEventTitle} ${minsLeft}m left`;
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: selectedEventTitle,
              isDWing: true,
            });
          }
        } else if (shouldShowTodoCount) {
          if (totalTodosCount === 0) {
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: "Plan your Todos",
            });
          } else {
            sendIPCUpdateEvent(updateMenuBarTitle, {
              title: `${pendingTodosTodayCount} ${
                pendingTodosTodayCount === 1 ? "Todo" : "Todos"
              }`,
            });
          }
        }
      } else {
        sendIPCUpdateEvent(updateMenuBarTitle, {
          title: `Loading...`,
        });
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedEventToTime,
    selectedEventId,
    totalTodosCount,
    pendingTodosTodayCount,
    isLoading,
  ]);
};
