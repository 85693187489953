import React from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip } from "antd";

import DisplayTextWithLink from "./DisplayTextWithLink";
import { getIsElectron } from "app/appUtils";
import { httpUrlRegex, wwwUrlRegex } from "app/appConstants";

import { ReactComponent as OpenSlackLinkIcon } from "assets/slack_integration_todo_icon.svg";
import { ReactComponent as OpenJIRALinkIcon } from "assets/jira_integration_todo_icon.svg";
const { Text } = Typography;

const BaseText = ({
  strong,
  className,
  baseTextClassName,
  isMoreThanCharLimit,
  iconType,
  actualCharLimit,
  characterLimit,
  shouldDisplayLink,
  text,
  integrationLink,
  handleOpenLink,
  onClick,
}) => {
  return (
    <Text
      strong={strong}
      lang="en"
      className={`${className} ${baseTextClassName}`}
      onClick={onClick}
    >
      {integrationLink && (
        <>
          {iconType === "slack" && (
            <OpenSlackLinkIcon
              style={{
                height: "16px",
                width: "16px",
                marginRight: "4px",
                cursor: "pointer",
                marginBottom: "-4px",
              }}
              onClick={handleOpenLink}
            />
          )}
          {iconType === "jira" && (
            <OpenJIRALinkIcon
              style={{
                height: "16px",
                width: "16px",
                marginRight: "4px",
                cursor: "pointer",
                marginBottom: "-4px",
              }}
              onClick={handleOpenLink}
            />
          )}
        </>
      )}

      {isMoreThanCharLimit ? (
        shouldDisplayLink ? (
          <>
            <DisplayTextWithLink text={text?.slice(0, actualCharLimit)} />
          </>
        ) : (
          `${text?.slice(0, characterLimit)}...`
        )
      ) : shouldDisplayLink ? (
        <DisplayTextWithLink text={text} />
      ) : (
        text
      )}
    </Text>
  );
};

const EllipseText = ({
  text = "",
  integrationLink = "",
  className = "",
  baseTextClassName = "",
  shouldConsiderRegEx = true,
  characterLimit = 25,
  tooltipOnMore = false,
  onClick = () => {},
  strong = false,
  shouldDisplayTooltip = true,
  shouldDisplayLink = false,
}) => {
  const getNearestLinkEndingIndex = () => {
    if (!text) return 0;
    const allMatches = [
      ...text.matchAll(httpUrlRegex),
      ...text.matchAll(wwwUrlRegex),
    ];
    if (allMatches.length > 0) {
      let linkEnd = characterLimit;
      for (let arrIndex = 0; arrIndex < allMatches.length; arrIndex++) {
        const link = allMatches[arrIndex][0];
        const currentLinkEnd = text.indexOf(link) + link.length;
        if (linkEnd < currentLinkEnd) {
          linkEnd = currentLinkEnd;
          break;
        }
      }
      return linkEnd;
    }
    return characterLimit;
  };

  const handleOpenLink = () => {
    const isElectron = getIsElectron();

    if (isElectron) {
      if (window?.electronDW?.shell?.openExternal) {
        window.electronDW.shell.openExternal(integrationLink);
      }
    } else {
      window.open(integrationLink, "_blank").focus();
    }
  };

  const actualCharLimit = shouldConsiderRegEx
    ? getNearestLinkEndingIndex()
    : characterLimit;
  const isMoreThanCharLimit = text?.length > actualCharLimit;

  let iconType = "";
  if (integrationLink?.includes("slack")) {
    iconType = integrationLink?.includes("slack") ? "slack" : "";
  }
  if (integrationLink?.includes("jira")) {
    iconType = integrationLink?.includes("jira") ? "jira" : "";
  }

  if (tooltipOnMore) {
    return (
      <span className={className}>
        <BaseText
          strong={strong}
          baseTextClassName={baseTextClassName}
          isMoreThanCharLimit={isMoreThanCharLimit}
          iconType={iconType}
          actualCharLimit={actualCharLimit}
          characterLimit={characterLimit}
          shouldDisplayLink={shouldDisplayLink}
          text={text}
          integrationLink={integrationLink}
          handleOpenLink={handleOpenLink}
          onClick={onClick}
        />
        {isMoreThanCharLimit && shouldDisplayTooltip && (
          <Tooltip
            title={text}
            // trigger={["click"]}
          >
            <Text type="secondary">...more</Text>
          </Tooltip>
        )}
      </span>
    );
  }

  return (
    <Tooltip title={isMoreThanCharLimit && shouldDisplayTooltip ? text : null}>
      <BaseText
        strong={strong}
        className={className}
        baseTextClassName={baseTextClassName}
        isMoreThanCharLimit={isMoreThanCharLimit}
        iconType={iconType}
        actualCharLimit={actualCharLimit}
        characterLimit={characterLimit}
        shouldDisplayLink={shouldDisplayLink}
        text={text}
        integrationLink={integrationLink}
        handleOpenLink={handleOpenLink}
        onClick={onClick}
      />
    </Tooltip>
  );
};

EllipseText.propTypes = {
  text: PropTypes.string,
  characterLimit: PropTypes.number,
};

export default EllipseText;
