import React, { useState } from "react";
import { Checkbox, Row } from "antd";
import PropTypes from "prop-types";

import { EllipseText } from "common/components";
import { TodosContext } from "app/home/context";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { useDoneTodoMessage } from "store";
import { getDoneTodoMsg } from "app/appUtils";

const LinkedTodo = ({
  disabled = false,
  todo = {},
  checked = false,
  isSavingTodo = false,
  saveTodo = () => {},
  onCheck = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  if (isEdit) {
    return (
      <TodosContext.Provider
        value={{
          categoriesWithTodos,
          setCategoriesWithTodos,
        }}
      >
        <TodoInput
          saveTodo={saveTodo}
          isSavingTodo={isSavingTodo}
          isForEdit={true}
          setIsEdit={setIsEdit}
          todo={todo}
        />
      </TodosContext.Provider>
    );
  }

  return (
    <Row>
      <Checkbox disabled={disabled} checked={checked} onChange={onCheck}>
        Mark
        <EllipseText
          className="mx-8"
          strong={true}
          text={todo?.title}
          characterLimit={20}
        />{" "}
        as done
      </Checkbox>
    </Row>
  );
};

const LinkedTodos = ({
  isSavingTodo = false,
  selectedEvent = {},
  todoIdsDone = [],
  saveTodo = () => {},
  setTodoIdsDone = () => {},
}) => {
  const [, setDoneTodoMsg] = useDoneTodoMessage();

  const todos = selectedEvent?.todos || [];

  const handleTodoCheck = (e, todoId) => {
    const checked = e?.target?.checked;
    setTodoIdsDone((ids) => {
      if (checked) {
        return [...ids, todoId];
      } else {
        return ids?.filter((id) => id !== todoId);
      }
    });
    setDoneTodoMsg(getDoneTodoMsg());
    setTimeout(() => {
      setDoneTodoMsg("");
    }, 7000);
  };

  return (
    <div>
      {todos?.length > 0 && (
        <>
          {todos?.map((todo) => (
            <LinkedTodo
              disabled={selectedEvent?.isTempSession}
              checked={todoIdsDone?.filter((id) => id === todo?.id)?.length > 0}
              isSavingTodo={isSavingTodo}
              todo={todo}
              saveTodo={saveTodo}
              onCheck={(e) => {
                handleTodoCheck(e, todo?.id);
              }}
            />
          ))}
        </>
      )}
    </div>
  );
};

LinkedTodos.propTypes = {
  selectedEvent: PropTypes.object,
  handleUpdateTimeSlot: PropTypes.func,
};

export default LinkedTodos;
