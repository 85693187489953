import React, { useState, useContext } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { useQueryClient, useMutation } from "react-query";
import { Typography, Button, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { BoxLoader, InlineSpinner } from "common/components";
import CategoriesList from "./categories-list/CategoriesList";
import { useAppDate, useOnboardedState, useSelectedEventState } from "store";
import { useGetTodos, useSaveTodo } from "common/hooks";
import { TodosContext } from "app/home/context";
import { bulkMoveTodosDueDateApi } from "app/home/todos/todosApis";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import { clearDoneTodosApi } from "app/home/todos/todosApis";

import { dateTimeFormatZ, dateTimeOnly } from "app/appConstants";
import { getIsTodoWorkedUponToday } from "./todoUtils";
import {
  clearDoneTodosOnSuccess,
  createDWNowOnSuccess,
  saveTodoOnSuccess,
  handleGetTodosSuccess,
  bulkMoveTodosDueDateOnSuccess,
} from "./TodosSuccessHandlers";
import { errorNotification } from "app/appUtils";
import {
  dateFormat,
  dateTimeFormat,
  shortDateFormatWithWeekDay,
} from "app/appConstants";

const { Text, Link } = Typography;

const Todos = ({
  isHomeLoading = false,
  dueDateFilter = "today",
  tomorrowTabDate = null,
  pendingTodosTodayCount = 0,
  searchTodoStr = "",
  dailyCategorySummary = {},
  setTodosCount = () => {},
  setCategories = () => {},
  setSelectedTab = () => {},
}) => {
  //Global State
  const queryClient = useQueryClient();
  const [appDate] = useAppDate();
  const [onboarded] = useOnboardedState();
  const [, setSelectedEvent] = useSelectedEventState();

  // Context
  const { categoriesWithTodos, setCategoriesWithTodos } =
    useContext(TodosContext);

  // Component State
  const [allTodos, setAllTodos] = useState([]);

  // Queries
  const {
    isLoading: isLoadingTodos,
    isFetching,
    refetch: refetchTodos,
  } = useGetTodos({
    onSuccess: (data) => {
      handleGetTodosSuccess({
        appDate,
        data,
        tomorrowTabDate,
        setAllTodos,
        setTodosCount,
        setCategories,
        setCategoriesWithTodos,
      });
    },
  });

  //Mutations
  const { mutate: bulkMoveTodosDueDate, isLoading: isBulkMovingTodosDueDates } =
    useMutation(bulkMoveTodosDueDateApi, {
      onSuccess: () => {
        bulkMoveTodosDueDateOnSuccess({ queryClient });
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      saveTodoOnSuccess({
        queryClient,
        data,
        variables,
        handleCreateDWNow,
        refetchTodos,
      });
    },
    onError: (err) => {
      refetchTodos();
      errorNotification(err);
    },
  });

  const { mutate: createDWNowTimeSlot } = useMutation(saveTimeSlotApi, {
    onSuccess: (data) => {
      createDWNowOnSuccess({
        onboarded,
        data,
        queryClient,
      });
    },
    onError: (err) => {},
  });

  const { mutate: clearDoneTodos, isLoading: isClearingDoneTodos } =
    useMutation(clearDoneTodosApi, {
      onSuccess: () => {
        clearDoneTodosOnSuccess({ queryClient });
      },
      onError: (err) => {
        queryClient.invalidateQueries("getTodos");
        errorNotification(err);
      },
    });

  //Event handlers
  const handleBulkMoveDueDates = () => {
    const todoIds = allTodos
      ?.filter(
        (todo) =>
          moment(todo?.due_date, dateTimeFormat)?.isSameOrBefore(
            moment()?.endOf("day")
          ) && !Boolean(todo?.completed_at)
      )
      ?.map((todo) => todo?.id);
    bulkMoveTodosDueDate({
      todoIds,
      dueDate: tomorrowTabDate,
    });
  };

  const handleCreateDWNow = (event, extraParams) => {
    if (event?.detail > 1) {
      return;
    }

    const todoTitle = extraParams?.todoTitle || "";
    const todoDesc = extraParams?.todoDesc || "";
    const todoId = extraParams?.todoId || "";
    const todoDueDate = extraParams?.todoDueDate || "";

    setSelectedEvent({
      from_time_utc: moment.utc(),
      to_time_utc: moment()?.add(30, "minutes").utc(),
      isTempSession: true,
      is_active: true,
      title: todoTitle,
      todo_ids: [todoId],
      todos: [
        {
          todo_id: todoId,
          title: todoTitle,
          description: todoDesc,
          due_date: todoDueDate,
        },
      ],
    });
    setSelectedTab("execute");

    createDWNowTimeSlot({
      time_slot: {
        title: todoTitle,
        quick_plan: todoDesc,
        from_time: moment()?.utc()?.format(dateTimeFormatZ),
        to_time: moment()?.add(30, "minutes")?.utc()?.format(dateTimeFormatZ),
        todo_id: todoId,
        todo_ids: [todoId],
        is_active: true,
      },
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: moment()?.add(30, "minutes").format(dateTimeOnly),
      });
    }
  };

  const handleClearCompletedTodos = () => {
    clearDoneTodos();
  };

  //Component Logic
  const formattedDate = moment(tomorrowTabDate, dateFormat)?.format(
    shortDateFormatWithWeekDay
  );

  const isDateTomorrow = moment(tomorrowTabDate, dateFormat)?.isSame(
    moment(appDate, dateFormat)?.add(1, "day")
  );

  const goals =
    categoriesWithTodos?.filter((category) => category?.type === "Goal") || [];

  const filteredCategoriesWithTodos =
    categoriesWithTodos?.filter(
      (category) => category?.type === "TodoCategory"
    ) || [];

  const orderedCategoriesWithTodos = filteredCategoriesWithTodos?.sort(
    (a, b) => a?.order - b?.order
  );

  const completedTodayTodosCount =
    allTodos?.filter(
      (todo) =>
        (moment(todo?.due_date, dateTimeFormat)?.isSameOrBefore(
          moment()?.endOf("day")
        ) ||
          getIsTodoWorkedUponToday(todo)) &&
        Boolean(todo?.completed_at)
    )?.length || 0;

  const categories = categoriesWithTodos?.map((category) => {
    const categoryWithoutTodos = { ...category };
    delete categoryWithoutTodos?.active_todos;
    delete categoryWithoutTodos?.todos;

    return categoryWithoutTodos;
  });

  const loader = isLoadingTodos || isFetching;

  return (
    <div>
      {pendingTodosTodayCount > 0 && dueDateFilter === "tomorrow" && (
        <div
          className="flex-display flex-direction-column align-items-center justify-content-center"
          style={{
            backgroundColor: "#EEEEEE",
            padding: "8px",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        >
          <Text className="block-display" italic={true}>
            <InfoCircleOutlined className="mr-4 text-primary" />
            {`You have ${pendingTodosTodayCount} Todos that are due`}
          </Text>
          <Button size="small" type="primary" onClick={handleBulkMoveDueDates}>
            {isBulkMovingTodosDueDates ? (
              <InlineSpinner className="ml-8" fontSize={24} />
            ) : (
              `Move them to ${isDateTomorrow ? "Tomorrow" : formattedDate}`
            )}
          </Button>
        </div>
      )}

      <div className="flex-display flex-direction-column justify-content-space-between">
        <div className="background-color-white pb-16">
          {isLoadingTodos && <BoxLoader />}
          <CategoriesList
            loader={loader}
            isSavingTodo={isSavingTodo}
            dueDateFilter={dueDateFilter}
            searchStr={searchTodoStr}
            tomorrowTabDate={tomorrowTabDate}
            appDate={appDate}
            isDateTomorrow={isDateTomorrow}
            goals={goals}
            dailyCategorySummary={dailyCategorySummary}
            categories={categories}
            categoriesWithTodos={orderedCategoriesWithTodos}
            refetchTodos={refetchTodos}
            handleCreateDWNow={handleCreateDWNow}
            saveTodo={saveTodo}
          />

          {completedTodayTodosCount > 0 && (
            <Row justify="center">
              <Link type="link" onClick={handleClearCompletedTodos}>
                Clear Done Todos
              </Link>
              {isClearingDoneTodos && (
                <InlineSpinner className="ml-8" fontSize={16} />
              )}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
Todos.propTypes = {};

export default Todos;
