import { useMutation } from "react-query";

import { saveTodoApi } from "app/home/todos/todosApis";

const useSaveTodo = ({ onSuccess = () => {}, onError = () => {} }) => {
  const {
    mutate: saveTodo,
    status,
    isLoading: isSavingTodo,
  } = useMutation(saveTodoApi, {
    onSuccess: (data, variables) => {
      onSuccess(data, variables);
    },
    onError: (err) => {
      onError(err);
    },
  });

  return { mutate: saveTodo, status, isLoading: isSavingTodo };
};

export default useSaveTodo;
