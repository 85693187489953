import React from "react";
// import PropTypes from 'prop-types';
import { Typography } from "antd";

import { ReactComponent as NewLearningsIcon } from "assets/new_learnings.svg";

const { Text } = Typography;

const NewLearningsText = (props) => {
  return (
    <div className="flex-display align-items-center">
      <NewLearningsIcon className="mr-4 mt--4" />
      <Text type="secondary" className="font-size-12">
        New Learning
      </Text>
    </div>
  );
};

NewLearningsText.propTypes = {};

export default NewLearningsText;
