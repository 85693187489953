import React from "react";
import PropTypes from "prop-types";
import { useQueryClient, useMutation } from "react-query";

import ExecutionHeader from "./components/ExecutionHeader";
import ExecutionTimer from "./components/ExecutionTimer";
import NotesInput from "./components/NotesInput";
import {
  useSuccessMessageStateState,
  useIsAppPinnedState,
  useIsAppExpandedState,
} from "store";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import { saveTimeSlotOnSuccess } from "./ExcuteDeepWorkSuccessHandler";
import {
  errorNotification,
  getIsElectron,
  getMainContainerStyling,
} from "app/appUtils";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const ExecuteDeepWork = ({
  selectedEvent = {},
  setSelectedTab = () => {},
  setWasHomeSelectedWhileFocusing = () => {},
  handleSelectedEvent = () => {},
}) => {
  const isElectron = getIsElectron();
  const isWebPage = !isChromeExtension && !isElectron;
  const queryClient = useQueryClient();

  // Component State
  const [isPinned, setIsPinned] = useIsAppPinnedState();
  const [isAppExpanded] = useIsAppExpandedState();
  const [, setSuccessMessage] = useSuccessMessageStateState();

  // Mutations
  const { mutate: saveTimeSlot } = useMutation(saveTimeSlotApi, {
    onSuccess: (data, variables) => {
      saveTimeSlotOnSuccess({
        data,
        variables,
        queryClient,
        setSuccessMessage,
        onEndDeepWork,
      });
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  // Handlers
  const handleUpdateTimeSlot = ({ time_slot, extraParams = {} }) => {
    saveTimeSlot({
      time_slot,
      extraParams,
    });
  };

  const onEndDeepWork = () => {
    setSelectedTab("todos");
    handleSelectedEvent(null);
  };

  // Component Logic
  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  const linkedTodos = selectedEvent?.todos || [];
  const linkedTodo = linkedTodos[0] || {};

  return (
    <div
      id="main-menu-content"
      className={`flex-display justify-content-center 
    ${isWebPage && "web-main-content-styles"} 
    
    `}
    >
      <div style={mainContainerStyles}>
        <ExecutionHeader
          isPinned={isPinned}
          selectedEvent={selectedEvent}
          setIsPinned={setIsPinned}
        />
        <div className="width-100-percent">
          <div className="mx-16">
            <div>
              <NotesInput
                className="pr-8 py-8"
                event={selectedEvent}
                linkedTodo={linkedTodo}
                handleSelectedEvent={handleSelectedEvent}
              />
            </div>

            <ExecutionTimer
              isPinned={isPinned}
              endDWBtnClassName="mb-16"
              isFromMenuBar={true}
              selectedEvent={{ ...selectedEvent }}
              handleUpdateTimeSlot={handleUpdateTimeSlot}
              setSelectedTab={setSelectedTab}
              setWasHomeSelectedWhileFocusing={setWasHomeSelectedWhileFocusing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ExecuteDeepWork.propTypes = {
  event: PropTypes.object,
};

export default ExecuteDeepWork;
