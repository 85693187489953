import React from "react";
import { Layout, Card, Typography } from "antd";

import logo from "assets/logo.svg";

const { Link } = Typography;

const RedirectLayout = ({ children }) => {
  return (
    <Layout className="full-height">
      <Card className="info-page-card">
        <div className="info-page-div">
          <Link href="https://deepwork.easy.xyz/">
            <img className="block-display mb-16" src={logo} alt="logo" />
          </Link>

          {children}
        </div>
      </Card>
    </Layout>
  );
};

RedirectLayout.propTypes = {};

export default RedirectLayout;
