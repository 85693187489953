import React from "react";
import { Row, Col, Typography } from "antd";

import { getHabitsDays } from "../habitsUtils";

const { Text } = Typography;

const AtomicHabitHeader = ({ data = {} }) => {
  const days = getHabitsDays(data?.daily_summary);

  return (
    <Row align="middle">
      <Col span={8}>
        <Text type="secondary" style={{ fontSize: "12px" }}>
          ATOMIC HABITS
        </Text>
      </Col>
      {days?.map((day, idx) => {
        const isOffDay = day?.day_type === "DO" || day?.day_type === "NWD";

        return (
          <Col
            span={2}
            style={{ backgroundColor: isOffDay ? "#e3e3e3" : undefined }}
          >
            <div className="flex-display flex-direction-column align-items-center">
              <Text
                type="secondary"
                className="block-display"
                strong
                style={{ fontSize: "10px" }}
              >
                {day?.day}
              </Text>
              <Text className="block-display" style={{ fontSize: "10px" }}>
                {day?.date}
              </Text>
            </div>
          </Col>
        );
      })}
      <Col span={2}>
        <div className="flex-display justify-content-center">
          <Text className="block-display" strong style={{ fontSize: "13px" }}>
            NET
          </Text>
        </div>
      </Col>
    </Row>
  );
};

AtomicHabitHeader.propTypes = {};

export default AtomicHabitHeader;
