import JIRARedirect from "./redirects/JIRARedirect";
import SlackRedirect from "./redirects/SlackRedirect";
import GoogleRedirect from "./redirects/GoogleRedirect";
import OpenIntegrationsFromElectron from "./open-integrations-from-electron/OpenIntegrationsFromElectron";
import SuccessfullyIntegratedRedirectToElectron from "./successfully-integrated-redirect-to-electron/SuccessfullyIntegratedRedirectToElectron";

const routes = [
  {
    exact: true,
    path: "/integrations/jiracloud/redirect",
    component: JIRARedirect,
  },
  {
    exact: true,
    path: "/integrations/google",
    component: GoogleRedirect,
  },
  {
    exact: true,
    path: "/integrations/slack/redirect",
    component: SlackRedirect,
  },
  {
    exact: true,
    path: "/integrations/open-from-electron",
    component: OpenIntegrationsFromElectron,
  },
  {
    exact: true,
    path: "/integrations/successfully-integrated-redirect-to-electron",
    component: SuccessfullyIntegratedRedirectToElectron,
  },
];

export default routes;
