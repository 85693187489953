import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import moment from "moment";
import { Row, Typography, Button, DatePicker, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { EllipseText, ButtonWithSpinner } from "common/components";
import { useSaveTodo } from "common/hooks";
import { TodosContext } from "app/home/context";
import ResolvedView from "./ResolvedView";
import AssistHeader from "./AssistHeader";
import DoneState from "./DoneState";
import DWSessionTrackerModal from "app/home/todos/categories-list/todo/dw-sessions-tracker-modal/DWSessionTrackerModal";
import TodoInput from "app/home/todos/categories-list/todo/todo-input/TodoInput";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import { resolveAssistsApi } from "app/assists/assistsApi";
import { deleteTodoApi } from "app/home/todos/todosApis";
import { errorNotification } from "app/appUtils";
import { getTimeSpentToday } from "app/home/todos/todoUtils";
import { clearAssistApi } from "app/assists/assistsApi";
import { dateFormat, dateTimeOnly, dateTimeFormatZ } from "app/appConstants";

import { ReactComponent as StaleTodoIcon } from "assets/stale_todo_icon.svg";

import styles from "app/assists//Assists.module.css";

const { Text } = Typography;
const { confirm } = Modal;

const getTodo = (todos = [], todoId = null) => {
  const filteredTodos = todos?.filter((todo) => todo?.id === todoId) || [];
  if (filteredTodos?.length === 0) {
    return null;
  }
  return filteredTodos[0];
};

const StaleAssist = ({
  key = null,
  categories = [],
  defaultExpand = false,
  assist = {},
  allTodos = [],
  refetch = () => {},
  refetchTodos = () => {},
}) => {
  const [isStateExpanded, setIsStateExpanded] = useState(defaultExpand);
  const [isEditTodo, setIsEditTodo] = useState(false);
  const [isDWSessionTrackerVisible, setIsDWSessionTrackerVisible] =
    useState(false);
  const [rescheduleDate, setRescheduleDate] = useState();
  const [isAssistDone, setIsAssistDone] = useState(false);
  const [categoriesWithTodos, setCategoriesWithTodos] = useState([]);

  const queryClient = useQueryClient();

  const {
    mutate: createDWNowTimeSlot,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(saveTimeSlotApi, {
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries("getDayView");
      }, 500);
    },
    onError: (err) => {},
  });

  const {
    mutate: resolveAssists,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(resolveAssistsApi, {
    onSuccess: () => {
      refetch();
      setIsAssistDone(true);
      clearAssist({ assistId: assist?.id });
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const { mutate: clearAssist, isLoading: isClearingAssist } = useMutation(
    clearAssistApi,
    {
      onSuccess: () => {
        setTimeout(() => {
          refetch();
          setIsAssistDone(false);
        }, 3000);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const { mutate: saveTodo, isLoading: isSavingTodo } = useSaveTodo({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries("getTodos");
      queryClient.invalidateQueries("getDayView");
      queryClient.invalidateQueries("getDayInsights");
      queryClient.invalidateQueries([
        "getDailyStats",
        moment().format(dateFormat),
      ]);
      // queryClient.invalidateQueries("getDayView");
      if (variables?.extraParams?.resetValues) {
        variables.extraParams.resetValues();
      }
      if (variables?.extraParams?.createDWNow) {
        handleCreateDWNow(null, {
          todoTitle: data?.title,
          todoDesc: data?.description,
          todoId: data?.id,
        });
      }
    },
    onError: (err) => {
      refetchTodos();
      errorNotification(err);
    },
  });

  const { mutate: deleteTodo, isLoading: isDeletingTodo } = useMutation(
    deleteTodoApi,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getDayView",
          moment().format(dateFormat),
        ]);
        // queryClient.invalidateQueries("getDayView");
        refetchTodos();
        refetch();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleCreateDWNow = (event, extraParams) => {
    if (event?.detail > 1) {
      return;
    }

    const todoTitle = extraParams?.todoTitle || "";
    const todoDesc = extraParams?.todoDesc || "";
    const todoId = extraParams?.todoId || "";

    createDWNowTimeSlot({
      time_slot: {
        title: todoTitle,
        quick_plan: todoDesc,
        from_time: moment()?.utc()?.format(dateTimeFormatZ),
        to_time: moment()?.add(30, "minutes")?.utc()?.format(dateTimeFormatZ),
        todo_id: todoId,
        todo_ids: [todoId],
        is_active: true,
      },
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: moment()?.add(30, "minutes").format(dateTimeOnly),
      });
    }
  };

  const handleDeleteTodo = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this todo/idea (${todo?.title || ""}).`,
      onOk() {
        deleteTodo({ todoId: todo?.id });
      },
      okText: "Delete",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleExpand = () => {
    setIsStateExpanded(!isStateExpanded);
  };

  const handleEditTodo = () => {
    setIsEditTodo(!isEditTodo);
  };

  const handleDone = () => {
    resolveAssists({
      assistId: assist?.id,
      cta: "mark_done",
      todo_id: info?.todo_id,
    });
  };

  const handleRescheduleTodo = () => {
    saveTodo({
      todo: { ...todo, due_date: rescheduleDate?.format(dateFormat) },
    });
  };

  const handleIsDWSessionTrackerVisible = () => {
    setIsDWSessionTrackerVisible(!isDWSessionTrackerVisible);
  };

  const info = JSON.parse(assist?.info);

  const todo = getTodo(allTodos, info?.todo_id);

  const timeSpentToday = getTimeSpentToday(todo?.day_tracks);

  const isResolved = Boolean(assist?.resolved_at);

  const disabled = isSavingTodo;

  return (
    <div key={key} className={`${styles.assist} cursor-pointer`}>
      {isDWSessionTrackerVisible && (
        <DWSessionTrackerModal
          todoId={todo?.id}
          visible={isDWSessionTrackerVisible}
          dayTracks={todo?.day_tracks || []}
          onCancel={handleIsDWSessionTrackerVisible}
          refetchTodos={refetchTodos}
        />
      )}

      <AssistHeader
        isResolved={isResolved}
        isExpanded={isStateExpanded}
        isClearingAssist={isClearingAssist}
        shouldShowInfo={!isAssistDone}
        data={assist}
        icon={<StaleTodoIcon />}
        text={<EllipseText text={info?.title} characterLimit={35} />}
        onClick={handleExpand}
        refetch={refetch}
        clearAssist={clearAssist}
      />

      {isStateExpanded && (
        <>
          {isAssistDone ? (
            <DoneState />
          ) : isResolved ? (
            <ResolvedView />
          ) : (
            <div>
              <Button
                size="small"
                className="mt-8 block-display"
                type="primary"
                ghost={true}
                onClick={handleDone}
              >
                Mark as Done
              </Button>

              <ButtonWithSpinner
                isSpinning={isDeletingTodo}
                size="small"
                className="mt-8 block-display"
                type="primary"
                ghost={true}
                onClick={handleDeleteTodo}
              >
                Delete Todo
              </ButtonWithSpinner>
              <Row className="mt-8" align="middle">
                <Text>Reschedule to </Text>
                <DatePicker
                  className="ml-4"
                  size="small"
                  val={rescheduleDate}
                  onChange={setRescheduleDate}
                ></DatePicker>
                <Button
                  className="ml-4"
                  type="primary"
                  ghost={true}
                  size="small"
                  onClick={handleRescheduleTodo}
                >
                  Reschedule
                </Button>
              </Row>

              {isEditTodo ? (
                <div className={styles.todoInputContainer}>
                  <TodosContext.Provider
                    value={{
                      categoriesWithTodos,
                      setCategoriesWithTodos,
                    }}
                  >
                    <TodoInput
                      loader={disabled}
                      timeSpentToday={timeSpentToday}
                      categories={categories}
                      saveTodo={saveTodo}
                      isSavingTodo={isSavingTodo}
                      from="edit"
                      setIsEdit={setIsEditTodo}
                      todo={todo}
                      handleCreateDWNow={() => {
                        handleCreateDWNow(null, {
                          todoId: todo?.id,
                          todoTitle: todo?.title,
                          todoDesc: todo?.description,
                        });
                      }}
                      handleIsDWSessionTrackerVisible={
                        handleIsDWSessionTrackerVisible
                      }
                      refetchTodos={refetchTodos}
                    />
                  </TodosContext.Provider>
                </div>
              ) : (
                <Button
                  size="small"
                  className="my-8 block-display"
                  type="primary"
                  ghost={true}
                  onClick={handleEditTodo}
                >
                  Edit Todo
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ReviewStaleAssists = ({
  data = [],
  categories = [],
  firstTodoCategory = "",
  allTodos = [],
  refetch = () => {},
  refetchTodos = () => {},
}) => {
  const shouldExpandFirstStaleTodo = firstTodoCategory === "staleTodos";
  return (
    <div>
      {data?.map((assist, index) => {
        return (
          <StaleAssist
            key={assist?.id}
            categories={categories}
            defaultExpand={shouldExpandFirstStaleTodo && index === 0}
            assist={assist}
            allTodos={allTodos}
            refetch={refetch}
            refetchTodos={refetchTodos}
          />
        );
      })}
    </div>
  );
};

ReviewStaleAssists.propTypes = {};

export default ReviewStaleAssists;
