import React from "react";
// import PropTypes from "prop-types";
import { Typography } from "antd";

const { Text } = Typography;

const CategoryLabel = ({ backgroundColor = "black", label = "Black" }) => {
  return (
    <Text className="flex-display align-items-center">
      <span
        className="mr-4"
        style={{
          height: "12px",
          width: "12px",
          backgroundColor,
          display: "inline-block",
          borderRadius: "50%",
        }}
      />
      {label}
    </Text>
  );
};

CategoryLabel.propTypes = {};

export default CategoryLabel;
