import React, { useState } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";

import { useAppDate } from "store";
import GoalHeader from "./GoalHeader";
import { dateFormat, dateTimeFormatZ, dateTimeOnly } from "app/appConstants";
import { saveTimeSlotApi } from "app/home/dayPlanApis";
import Todo from "app/home/todos/categories-list/todo/Todo";

const Goal = ({
  dueDateFilter = "",
  tomorrowTabDate = null,
  isFromMenu = false,
  goal = {},
  showAddTodo = true,
  refetch = () => {},
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [appDate] = useAppDate();

  const [showCompletedTodos, setShowCompletedTodos] = useState(false);

  const { mutate: createDWNowTimeSlot, isLoading: isCreatingDWNowTimeBlock } =
    useMutation(saveTimeSlotApi, {
      onSuccess: (data) => {
        setTimeout(() => {
          queryClient.invalidateQueries("getDayView");
        }, 500);
        history.push("/");
      },
      onError: (err) => {},
    });

  const handleCreateDWNow = (event, extraParams) => {
    if (event?.detail > 1) {
      return;
    }

    const todoTitle = extraParams?.todoTitle || "";
    const todoDesc = extraParams?.todoDesc || "";
    const todoId = extraParams?.todoId || "";

    createDWNowTimeSlot({
      time_slot: {
        title: todoTitle,
        quick_plan: todoDesc,
        from_time: moment()?.utc()?.format(dateTimeFormatZ),
        to_time: moment()?.add(30, "minutes")?.utc()?.format(dateTimeFormatZ),
        todo_id: todoId,
        todo_ids: [todoId],
        is_active: true,
      },
    });

    const isChromeExtension =
      process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
    if (isChromeExtension) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        toTime: moment()?.add(30, "minutes").format(dateTimeOnly),
      });
    }
  };

  const displayTodos = isFromMenu
    ? dueDateFilter === "today"
      ? goal?.active_todos?.filter((todo) =>
          moment(todo?.due_date, dateFormat)?.isSameOrBefore(
            moment(appDate, dateFormat)
          )
        ) || []
      : dueDateFilter === "tomorrow"
      ? goal?.active_todos?.filter((todo) =>
          moment(todo?.due_date, dateFormat)?.isSameOrBefore(
            moment(tomorrowTabDate, dateFormat)
          )
        ) || []
      : goal?.active_todos || []
    : goal?.todos?.filter((todo) => {
        if (showCompletedTodos) {
          return true;
        } else {
          return !Boolean(todo?.completed_at);
        }
      }) || [];

  const sortedDisplayTodos = displayTodos.sort((a, b) => {
    if (isFromMenu) {
      if (Boolean(a?.priority) && !Boolean(b?.priority)) {
        return -1;
      }

      if (!Boolean(a?.priority) && Boolean(b?.priority)) {
        return 1;
      }

      return 0;
    }

    if (!showCompletedTodos) {
      return 0;
    }

    if (Boolean(a?.completed_at) && Boolean(b?.completed_at)) {
      return 0;
    }

    if (!Boolean(a?.completed_at) && !Boolean(b?.completed_at)) {
      return 0;
    }

    if (Boolean(a?.completed_at) && !Boolean(b?.completed_at)) {
      return 1;
    }

    if (!Boolean(a?.completed_at) && Boolean(b?.completed_at)) {
      return -1;
    }

    return 0;
  });

  return (
    <div className="mt-16 mb-8">
      <GoalHeader
        showDetails={!isFromMenu}
        showAddTodo={showAddTodo}
        showCompletedTodos={showCompletedTodos}
        setShowCompletedTodos={setShowCompletedTodos}
        goal={goal}
        refetch={refetch}
      />
      <div>
        {sortedDisplayTodos?.map((todo, index) => {
          return (
            <Todo
              // loader={loader}
              dueDateFilter={dueDateFilter}
              shouldShowTime={true}
              showDeleteIcon={true}
              isCreatingDWNowTimeBlock={isCreatingDWNowTimeBlock}
              todo={todo}
              goalId={goal?.id}
              isGoal={true}
              index={index}
              key={todo?.id}
              refetchTodos={refetch}
              handleCreateDWNow={handleCreateDWNow}
            />
          );
        })}
      </div>
    </div>
  );
};

Goal.propTypes = {};

export default Goal;
