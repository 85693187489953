import React from "react";
import { Typography, Collapse, Row } from "antd";

import { useQuery } from "react-query";

import { BoxLoader } from "common/components";
import {
  useAppDate,
  useSelectedEventState,
  useIsAppExpandedState,
} from "store";
import { useMenuTitleUpdater } from "app/home/homeHooks";
import OrganisationHabit from "./components/OrganisationHabit";
import ExecutionHabit from "./components/ExecutionHabit";
import BettermentHabit from "./components/BettermentHabit";
import VennDiagram from "./components/VennDiagram";
import TopNav from "app/top-nav/TopNav";
import { getDayViewApi } from "app/home/todos/todosApis";
import { getUserHabitsApi } from "app/habits/habitsApi";
import { getMainContainerStyling } from "app/appUtils";

import { getIsElectron, errorNotification } from "app/appUtils";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const { Text } = Typography;

const getGrowthHabitData = (data = [], habit = "") => {
  const orgHabitData = data?.filter((item) => item?.name === habit);

  if (orgHabitData?.length > 0) {
    return orgHabitData[0];
  }

  return {};
};

const Habits = (props) => {
  const [appDate] = useAppDate();
  const [isAppExpanded] = useIsAppExpandedState();

  const [selectedEvent] = useSelectedEventState();

  const {
    data,
    isLoading,
    // isFetching: isFetchingInsights,
  } = useQuery(["getUserHabits", appDate], getUserHabitsApi, {
    onError: (error) => {
      errorNotification(error);
    },
  });

  const { data: dayViewData } = useQuery(
    ["getDayView", appDate],
    getDayViewApi
  );

  useMenuTitleUpdater({
    isLoading,
    selectedEvent,
    shouldShowTodoCount: false,
    currentDate: appDate,
    dayViewData,
  });

  const isElectron = getIsElectron();

  const isWebPage = !isChromeExtension && !isElectron;

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  const growthHabits = data?.growth_habits || [];

  return (
    <div
      id="main-menu-content"
      className={`flex-display ${isWebPage && "web-main-content-styles"} `}
    >
      {isLoading ? (
        <Row justify="center" className="height-200-px width-100-percent mt-8">
          <BoxLoader />
        </Row>
      ) : (
        <div className="ml-8 mr-8 mb-8 pl-16 pr-16" style={mainContainerStyles}>
          <TopNav from="habits" />

          <Row className="mb-16" justify="center">
            <VennDiagram data={data?.growth_trend} />
          </Row>

          <Text>Click on any habit to learn more</Text>

          <Collapse className="mt-8 habits-collapse">
            <OrganisationHabit
              key="organisation"
              data={getGrowthHabitData(growthHabits, "OrganizationHabit")}
            />
            <ExecutionHabit
              key="execution"
              data={getGrowthHabitData(growthHabits, "ExecutionHabit")}
            />
            {/* <GoalsHabit
            key="goals"
            data={getGrowthHabitData(data, "GoalsHabit")}
          /> */}
            <BettermentHabit
              key="betterment"
              data={getGrowthHabitData(growthHabits, "BettermentHabit")}
            />
          </Collapse>
        </div>
      )}
    </div>
  );
};

Habits.propTypes = {};

export default Habits;
