export const benefits = {
  jira: [
    "See JIRA tickets assigned to you in your Todos",
    "Completing Todos automatically updates the JIRA tickets",
    "Plan and Prioritize your JIRA tickets along with other work",
  ],
  google: [
    "See your calendar events in your time planner so that you can plan your Focus sessions accordingly.",
    "Block your calendar for your Focus sessions so that you don’t get double booked.",
  ],
  "google calendar": [
    "See your calendar events in your time planner so that you can plan your Focus sessions accordingly.",
    "Block your calendar for your Focus sessions so that you don’t get double booked.",
  ],
  slack: [
    "Automatically sets up Do Not Disturb (Dnd) status when Focusing",
    "Make your team aware that you are in Focus mode when Focusing so that distractions are reduced.",
    "See saved messages in your Todos",
    "Completing Todos automatically un-saves the message in Slack",
  ],
};

export const typeMap = {
  JiraCloudIntegration: "JIRA",
  GoogleIntegration: "Google Calendar",
  SlackIntegration: "Slack",
};
