import React, { useState } from "react";
// import PropTypes from 'prop-types';
import moment from "moment";
import { useQueryClient, useMutation } from "react-query";
import { Typography, Row, Col, Modal, Form, DatePicker, Empty } from "antd";

import MinutesInput from "./MinutesInput";
import SessionRow from "./SessionRow";
import { upsertDayTrackApi } from "app/home/todos/todosApis";
import { dateFormat } from "app/appConstants";
import { getTotalTimeSpentHMStr, errorNotification } from "app/appUtils";

const { Text, Title } = Typography;

const DWSessionTrackerModal = ({
  visible = false,
  isFromExecution = false,
  todoId = null,
  dayTracks = [],
  onCancel = () => {},
  refetchTodos = () => {},
}) => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const [isAddEntry, setIsAddEntry] = useState(false);

  const { mutate: upsertDayTrack } = useMutation(upsertDayTrackApi, {
    onSuccess: (data) => {
      refetchTodos();
      queryClient.invalidateQueries([
        "getDailyStats",
        moment().format(dateFormat),
      ]);
      queryClient.invalidateQueries("getDayView");
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  let totalTimeInSec = 0;
  let minTimeRange = 0;
  let maxTimeRange = 0;
  const dwTracksDataStore = dayTracks?.map((track) => {
    totalTimeInSec += track?.seconds >= 60 ? track?.seconds : 0;
    if (track?.seconds < minTimeRange) {
      minTimeRange = track?.seconds;
    }
    if (track?.seconds > maxTimeRange) {
      maxTimeRange = track?.seconds;
    }
    return {
      date: track?.date,
      seconds: track?.seconds,
    };
  });

  const handleAddEntry = () => {
    setIsAddEntry(!isAddEntry);
  };

  const handleAddTrack = (values) => {
    let timeInMins = values?.time_in_mins || 0;
    if (values?.time_in_mins?.includes("m")) {
      timeInMins = parseInt(values?.time_in_mins.match(/\d+/)[0]);
    }

    upsertDayTrack({
      todoId,
      date: values?.date?.format(dateFormat),
      seconds: timeInMins * 60,
    });
    form.resetFields();
    handleAddEntry();
  };

  minTimeRange = minTimeRange === 0 ? minTimeRange : minTimeRange - 1;
  maxTimeRange = maxTimeRange === 0 ? maxTimeRange : maxTimeRange + 1;

  return (
    <Modal
      centered={true}
      footer={null}
      width={"95%"}
      title={<Title level={5}>Time Breakdown</Title>}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
    >
      <div>
        {totalTimeInSec > 0 ? (
          <Row>
            <Col span={8}>
              <Text className="text-primary" strong={true}>
                Total
              </Text>
            </Col>
            <Col
              span={15}
              style={{
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              <Text className="text-primary" strong={true}>
                {getTotalTimeSpentHMStr(totalTimeInSec)}
              </Text>
            </Col>
          </Row>
        ) : (
          <Empty description="You don’t have any time tracked for this todo" />
        )}
        {dwTracksDataStore?.map((item) => {
          const percentage =
            (item?.seconds - minTimeRange) / (maxTimeRange - minTimeRange);
          return (
            <SessionRow
              isFromExecution={isFromExecution}
              todoId={todoId}
              item={item}
              percentage={percentage}
              upsertDayTrack={upsertDayTrack}
            />
          );
        })}
      </div>

      <div>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ date: moment() }}
          onFinish={handleAddTrack}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row className="mt-24">
            <Col span={8}>
              <Form.Item
                className="mb-0"
                name="date"
                rules={[{ required: true, message: "Please input date" }]}
                required={false}
              >
                <DatePicker
                  placeholder="Date"
                  size="small"
                  className="time-breakdown-modal-date-picker"
                  disabledDate={(currentDate) =>
                    currentDate && currentDate?.isAfter(moment())
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className="mb-0 ml-8"
                name="time_in_mins"
                rules={[
                  {
                    required: true,
                    message: "Please input time you spent in minutes!",
                  },
                ]}
                required={false}
              >
                <MinutesInput submitTxt="Track Time" shouldShowCancel={false} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

DWSessionTrackerModal.propTypes = {};

export default DWSessionTrackerModal;
