import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useMutation } from "react-query";
import moment from "moment";
import { Modal, Typography, Input, Row, Button } from "antd";

import { dateFormat } from "app/appConstants";
import { upsertDayTrackApi } from "app/home/todos/todosApis";

const { Title } = Typography;

const UpdateTodoModal = ({
  visible = false,
  todo = {},
  timeSpentToday = 0,
  refetchTodos = () => {},
  onCancel = () => {},
}) => {
  const [spentTime, setSpentTime] = useState(timeSpentToday);
  const { mutate: upsertDayTrack } = useMutation(upsertDayTrackApi, {
    onSuccess: (data) => {
      refetchTodos();
    },
  });

  const handleUpdateTime = () => {
    upsertDayTrack({
      todoId: todo?.id,
      date: moment()?.format(dateFormat),
      seconds: spentTime * 60,
    });
    onCancel();
  };

  const handleSpentTimeChange = (e) => {
    setSpentTime(e.target.value);
  };

  return (
    <Modal
      centered={true}
      width="80%"
      closable={false}
      title={null}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Row className="mt-16" justify="center" align="middle">
        <Title level={4}>Update the time you spent on this Todo Today</Title>
      </Row>
      <Row className="mt-16" justify="center" align="middle">
        <Input
          className="width-40-percent"
          value={spentTime}
          onChange={handleSpentTimeChange}
        />
        <Title className="inline-flex ml-8" level={3}>
          mins
        </Title>
      </Row>
      <Row className="mt-32" justify="center">
        <Button type="primary" onClick={handleUpdateTime}>
          Update Time
        </Button>
      </Row>
    </Modal>
  );
};

UpdateTodoModal.propTypes = {};

export default UpdateTodoModal;
