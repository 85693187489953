const colorCodes = {
  GREAT: "green",
  GOOD: "green",
  INCONSISTENT: "red",
};

const customPanelStyle = {
  background: "#ebebeb",
  marginBottom: 16,
  border: 0,
  overflow: "hidden",
};

export { colorCodes, customPanelStyle };
