const habitsInfo = {
  TodosPrePlannedAh: {
    title: "Pre-Planning",
    description:
      "Observes if you have pre-planned your Todos for the next day. You get a GREAT signal if you have at least #x# Todos pre-planned for a given day.",
    link: "",
    min: 0,
    max: 10,
    state: "todos_count",
    isSliderRange: false,
    href: "https://www.google.com/",
  },
  TodosPendingAh: {
    title: "Practical Targeting",
    description:
      "Observes how many Todos you have at the beginning of your day. You get a GREAT signal if you have less than #x# Todos.",
    link: "",
    min: 0,
    max: 10,
    state: "todos_count",
    isSliderRange: false,
    href: "https://www.google.com/",
  },
  FocusTimeAh: {
    title: "Deep Working",
    description:
      "Observes your focus time duration. You get a GOOD signal if you have atleast #x# hour of focus time and a GREAT signal if you have atleast #y# hours of focus time in a day.",
    link: "",
    min: 0,
    max: 10,
    state: ["poor_secs", "good_secs"],
    valueConversion: 3600,
    isSliderRange: true,
    href: "https://www.google.com/",
  },
  FocusSessionsAh: {
    title: "At Will Focussing",
    description:
      "Observes your ability to focus at will. You get a GOOD signal if you have atleast #x# focus sessions and a GREAT signal if you have atleast #y# focus sessions in a day.",
    link: "",
    min: 0,
    max: 10,
    state: ["poor_count", "good_count"],
    isSliderRange: true,
    href: "https://www.google.com/",
  },
  EverydayLearningAh: {
    title: "Everyday Learning",
    description:
      "Observes your focus time duration. You get a GOOD signal if you have atleast #x# hour of focus time and a GREAT signal if you have atleast #y# hours of focus time in a day.",
    link: "",
    min: 0,
    max: 10,
    state: ["poor_secs", "good_secs"],
    valueConversion: 3600,
    isSliderRange: true,
    href: "https://www.google.com/",
  },
};

const getCurrentConfig = (habit) => {
  let currentConfig = habit?.config;

  currentConfig = JSON.parse(currentConfig);

  return currentConfig;
};

const getDescription = (habitInfo, value) => {
  const description = habitInfo?.description || "";

  const isRange = habitInfo?.isSliderRange;

  if (isRange) {
    let newDesc = description?.replace("#x#", value[0]);
    return newDesc?.replace("#y#", value[1]);
  } else {
    return description?.replace("#x#", value);
  }
};

const getValue = ({ currentConfig, state, isSliderRange, valueConversion }) => {
  if (isSliderRange) {
    if (valueConversion) {
      return [
        currentConfig[state[0]] / valueConversion,
        currentConfig[state[1]] / valueConversion,
      ];
    }
    return [currentConfig[state[0]], currentConfig[state[1]]];
  }

  const currentState = currentConfig[state];
  return currentState;
};

const getConfig = (value, habitInfo) => {
  const config = {};
  const state = habitInfo?.state;
  const isSliderRange = habitInfo?.isSliderRange;
  const valueConversion = habitInfo?.valueConversion;

  if (isSliderRange) {
    if (valueConversion) {
      config[state[0]] = value[0] * valueConversion;
      config[state[1]] = value[1] * valueConversion;
    } else {
      config[state[0]] = value[0];
      config[state[1]] = value[1];
    }
  } else {
    config[state] = value;
  }

  return config;
};

const getMarks = (type = "", value, maxRange = 0) => {
  let marks = {};

  if (
    type === "FocusTimeAh" ||
    type === "FocusSessionsAh" ||
    type === "EverydayLearningAh"
  ) {
    const currentMin = value[0] || 0;
    const currentMax = value[1] || 0;
    for (let i = 0; i <= maxRange; i++) {
      marks[i] = { label: `${i}` };
      if (i <= currentMin) {
        marks[i].style = { color: "#e3e3e3" };
      } else if (i <= currentMax) {
        marks[i].style = { color: "#00ff72" };
      } else if (i <= maxRange) {
        marks[i].style = { color: "#00A75D" };
      }
    }
  } else {
    for (let i = 0; i <= maxRange; i++) {
      marks[i] = { label: `${i}` };
      if (i <= value) {
        marks[i].style = { color: "#e3e3e3" };
      } else {
        marks[i].style = { color: "#00A75D" };
      }
    }
  }

  return marks;
};

export {
  getConfig,
  getCurrentConfig,
  getDescription,
  getMarks,
  getValue,
  habitsInfo,
};
