import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Typography } from "antd";

import { InlineSpinner } from "common/components";
import RedirectLayout from "../common/RedirectLayout";
import ElectronIntegrationsRedirectButton from "../common/ElectronIntegrationsRedirectButton";
import { useSelectedIntegrationState } from "store";
import { errorNotificationWithString } from "app/appUtils";

const { Text, Title } = Typography;

const Redirect = ({ name = "" }) => {
  const history = useHistory();

  const [, setSelectedIntegration] = useSelectedIntegrationState(null);

  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const error = query.get("error");

  const isFromElectron = JSON.parse(localStorage.getItem("isFromElectron"));

  useEffect(() => {
    if (code && !isFromElectron) {
      setSelectedIntegration({
        name,
        code,
      });
      history.push("/settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    if (error === "access_denied") {
      errorNotificationWithString(
        "Access denied. Insufficient access. You need to allow all requested scopes to proceed."
      );
    } else {
      errorNotificationWithString(error);
    }
    history.push("/");
  }

  return (
    <RedirectLayout>
      <Title level={4} className="block-display mb-16">
        Integration successful
      </Title>
      {isFromElectron ? (
        <ElectronIntegrationsRedirectButton
          redirectCode={code}
          redirectType={name}
        />
      ) : (
        <>
          <Text>Redirecting</Text>
          <InlineSpinner />
        </>
      )}
    </RedirectLayout>
  );
};

Redirect.propTypes = {};

export default Redirect;
