import axios from "axios";

const getDayInsightsApi = async ({ queryKey: [_, date] }) => {
  const { data } = await axios.get(`/api/v2/insights/day/${date}`);
  return data;
};

const getPeriodInsightsApi = async ({ queryKey: [_, fromDate, toDate] }) => {
  const { data } = await axios.get(
    `api/v2/insights/period?from_date=${fromDate}&to_date=${toDate}`
  );
  return data;
};

export { getDayInsightsApi, getPeriodInsightsApi };
