import moment from "moment";
import { getIsGoalDone } from "app/appUtils";
import { dateFormat, dateTimeFormat } from "app/appConstants";

const getCharLimitBasedOnWidth = (width) => {
  return 55;
};

const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result =
    result.map((todo, index) => ({
      ...todo,
      order: index + 1,
    })) || [];

  return result;
};

const getReorderedMap = (current = []) => {
  const reOrderedMap = {};
  current.forEach((todo) => {
    reOrderedMap[todo?.id] = todo?.order;
  });
  return reOrderedMap;
};

const reorderTodos = ({
  todos,
  source,
  destination,
  reOrderTodosHandler = () => {},
}) => {
  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(
      todos.filter(
        (todo) => todo?.todo_category_id === parseInt(destination.droppableId)
      ),
      source.index,
      destination.index
    );
    reOrderTodosHandler({ todoOrderMap: getReorderedMap(reordered) });
    const otherCategoryTodos = todos.filter(
      (todo) => todo?.todo_category_id !== parseInt(destination.droppableId)
    );
    return reordered.concat(otherCategoryTodos);
  }
};

const reorderTodoCategories = ({
  todoCategories,
  source,
  destination,
  reOrderTodoCategoriesHandler = () => {},
}) => {
  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(todoCategories, source.index, destination.index);
    reOrderTodoCategoriesHandler({
      todoCategoryOrderMap: getReorderedMap(reordered),
    });
    return reordered;
  }
};

const orderTodos = (todos) => {
  const orderedTodos = todos?.sort((a, b) => a?.order - b?.order);
  return orderedTodos;
};

const orderTodoCategories = (todo_categories) => {
  return todo_categories.sort((a, b) => a?.order - b?.order);
};

const getTimeSpentToday = (dayTracks = []) => {
  const todayDayTrack = dayTracks?.filter(
    (track) => track?.date === moment()?.format(dateFormat)
  )[0];
  return Math.floor((todayDayTrack?.seconds || 0) / 60);
};

const getIsTodoWorkedUponToday = (todo) => {
  const dayTracks = todo?.day_tracks || [];
  let isToday = false;
  for (let i = 0; i < dayTracks?.length; i++) {
    const track = dayTracks[i];
    isToday =
      track?.date === moment()?.format(dateFormat) && track?.seconds > 0;
    if (isToday) {
      break;
    }
  }
  return isToday;
};

const getRemainingTodosCount = ({
  category,
  dueDateFilter,
  searchStr,
  appDate,
  unfinished,
  tomorrowTabDate,
}) => {
  return getFilteredCategoryTodos({
    category,
    dueDateFilter,
    appDate,
    searchStr,
    unfinished,
    tomorrowTabDate,
  })?.length;
};

const getTotalRemainingTodos = (
  categoriesWithTodos,
  dueDateFilter,
  searchStr,
  appDate,
  tomorrowTabDate
) => {
  let totalTodos = 0;

  categoriesWithTodos.forEach((category) => {
    totalTodos += getRemainingTodosCount({
      category,
      dueDateFilter,
      searchStr,
      appDate,
      tomorrowTabDate,
    });
  });

  return totalTodos;
};

const getTotalTimeSpentInCategory = (todos = []) => {
  let totalTimeSpentToday = 0;
  todos.forEach((todo) => {
    totalTimeSpentToday += getTimeSpentToday(todo?.day_tracks);
  });
  return totalTimeSpentToday;
};

const getFilteredCategoryTodos = ({
  category,
  searchStr = "",
  dueDateFilter,
  appDate,
  tomorrowTabDate,
  unfinished = false,
}) => {
  const categoryTodos = category?.active_todos?.filter((todo) => {
    if (dueDateFilter === "today") {
      if (unfinished) {
        return (
          moment(todo?.due_date, dateTimeFormat)?.isSameOrBefore(
            moment(appDate, dateFormat)?.endOf("day")
          ) && !Boolean(todo?.completed_at)
        );
      }

      return moment(todo?.due_date, dateTimeFormat)?.isSameOrBefore(
        moment(appDate, dateFormat)?.endOf("day")
      );
    } else if (dueDateFilter === "tomorrow") {
      if (unfinished) {
        return (
          moment(todo?.due_date, dateTimeFormat)?.isSame(
            moment(tomorrowTabDate, dateFormat)
          ) && !Boolean(todo?.completed_at)
        );
      }

      return moment(todo?.due_date, dateTimeFormat)?.isSame(
        moment(tomorrowTabDate, dateFormat)
      );
    } else {
      if (searchStr) {
        return (
          !Boolean(todo?.completed_at) &&
          todo?.title?.toLowerCase()?.includes(searchStr?.toLowerCase())
        );
      }

      return !Boolean(todo?.completed_at);
    }
  });

  const sortedCategoryTodos = categoryTodos?.sort((a, b) => {
    if (a?.priority && !b?.priority) {
      return -1;
    }

    if (!a?.priority && b?.priority) {
      return 1;
    }

    return 0;
  });

  return sortedCategoryTodos;
};

const getPendingGoals = (goals = [], searchStr, appDate) => {
  const pendingGoals = goals?.filter((goal) => !getIsGoalDone(goal));

  let filteredPendingGoals = pendingGoals?.filter((goal) => {
    let isGoalTodoInPast = false;

    if (goal && goal.todos) {
      goal.active_todos.forEach((todo) => {
        if (
          moment(todo?.due_date, dateFormat)?.isSameOrBefore(
            moment(appDate, dateFormat)
          )
        ) {
          isGoalTodoInPast = true;
        }
      });
    }

    return isGoalTodoInPast;
  });

  if (searchStr) {
    filteredPendingGoals = filteredPendingGoals?.filter((goal) => {
      let isGoalSearched = false;

      if (goal?.name?.toLowerCase()?.includes(searchStr)) {
        isGoalSearched = true;
      }

      if (goal && goal.todos) {
        goal.todos.forEach((todo) => {
          if (todo?.title?.includes(searchStr)) {
            isGoalSearched = true;
          }
        });
      }

      return isGoalSearched;
    });
  }

  return filteredPendingGoals;
};

const getTodoFields = ({
  from = "",
  dueDateFilter = "",
  isGoal = false,
  goalId = null,
  appDate = "",
  currentTodoCategoryId = null,
  todo = {},
}) => {
  const isFromHome = from === "home";
  const isFromQuickAdd = from === "quick-add";

  return {
    title: todo?.title || "",
    description: todo?.description || "",
    due_date:
      isFromHome || isFromQuickAdd || isGoal
        ? dueDateFilter === "tomorrow"
          ? moment()?.add(1, "day")?.format(dateFormat)
          : appDate
        : todo?.due_date || "",
    time_spent: todo?.time_spent || null,
    todo_category_id: isGoal
      ? goalId
      : isFromQuickAdd
      ? currentTodoCategoryId
      : todo?.todo_category_id,
    priority: todo?.priority,
    new_learnings: todo?.new_learnings,
  };
};

const getRecurringFields = (todo = {}) => {
  return {
    recurring: Boolean(todo?.recurring),
    periodicity: todo?.recurring?.periodicity,
    selectedDays: todo?.recurring?.config?.weekdays,
    monthlyDays: todo?.recurring?.config?.month_days?.join(", ") || "",
    ends: Boolean(todo?.recurring?.ends_on) ? "on" : "never" || "never",
    onMonth:
      todo?.recurring?.ends_on && todo?.recurring?.ends_on !== null
        ? moment(todo?.recurring?.ends_on, "YYYY-MM-DD")
        : null,
  };
};

const getCategoryStyle = (todoCategory) => ({
  height: "12px",
  width: "12px",
  backgroundColor: todoCategory?.color_formula,
  display: "inline-block",
  borderRadius: "50%",
});

export {
  reorderTodos,
  getCharLimitBasedOnWidth,
  orderTodos,
  orderTodoCategories,
  reorderTodoCategories,
  getTimeSpentToday,
  getIsTodoWorkedUponToday,
  getRemainingTodosCount,
  getTotalRemainingTodos,
  getTotalTimeSpentInCategory,
  getFilteredCategoryTodos,
  getPendingGoals,
  getTodoFields,
  getRecurringFields,
  getCategoryStyle,
};
