import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { Row, Button, Layout, Typography } from "antd";
import Icon from "@ant-design/icons";

import firebaseApp, {
  googleAuthProvider,
  microsoftProvider,
} from "firebaseApp";
import {
  getAuth,
  signInWithPopup,
  signInWithCustomToken,
  signInWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";
import { useCurrentUser } from "store";
import { InlineSpinner } from "common/components";
import { createCustomTokenApi } from "common/apis/appApi";
import { updateMenuBarTitle } from "app/appConstants";
import {
  getIsElectron,
  errorNotification,
  errorNotificationWithString,
  sendIPCUpdateEvent,
} from "app/appUtils";

import logo from "assets/logo.svg";
import { ReactComponent as GoogleImage } from "assets/auth/googleImage.svg";
import microsoftImage from "assets/auth/microsoftImage.png";

import styles from "./Auth.module.css";

const { Text, Title, Link } = Typography;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const AuthLayout = ({ isElectron, className = "", ...props }) => {
  return isElectron ? (
    <div id={props?.id}>{props.children}</div>
  ) : (
    <Layout className={className}>{props.children}</Layout>
  );
};

const AuthCard = ({ isElectron, className = "", ...props }) => {
  return isElectron ? (
    <div id={props?.id}>{props.children}</div>
  ) : (
    <div className={className}>{props.children}</div>
  );
};

const Auth = () => {
  const auth = getAuth(firebaseApp);

  const location = useLocation();
  const isElectron = getIsElectron();
  const ipcRendererWrapperForLogin = isElectron
    ? window?.electronDW?.ipcRendererWrapperForLogin
    : () => {};

  const [currentUser] = useCurrentUser();

  const [idToken, setIdToken] = useState(null);
  const [customToken, setCustomToken] = useState(null);
  const [isElectronAuthenticating, setIsElectronAuthenticating] =
    useState(false);

  const authenticateWithCustomToken = async (customTokenParam) => {
    setIsElectronAuthenticating(true);
    await signInWithCustomToken(auth, customTokenParam).then(() => {
      setIsElectronAuthenticating(false);
    });
  };

  const { mutate: createCustomToken, isLoading: isCreatingCustomToken } =
    useMutation(createCustomTokenApi, {
      onSuccess: (data) => {
        setCustomToken(data?.custom_token);
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  useEffect(() => {
    sendIPCUpdateEvent(updateMenuBarTitle, {
      title: "Log In",
    });
  }, []);

  useEffect(() => {
    if (idToken) {
      createCustomToken({ idToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  useEffect(() => {
    if (customToken) {
      authenticateWithCustomToken(customToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customToken]);

  ipcRendererWrapperForLogin(async (event, url) => {
    //TODO: uncomment when create token api is ready
    const replaceUrl = "dw-app://login/";
    const token = url?.link?.replace(replaceUrl, "")?.trim();
    setIdToken(token);
    // await firebaseApp
    //   .auth()
    //   .signInWithCustomToken(
    //     "eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1tbGFrMkBjdWx0dXJlLWVhc3ktYzMxODkuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay1tbGFrMkBjdWx0dXJlLWVhc3ktYzMxODkuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTYzOTQ3NjM2OCwiZXhwIjoxNjM5NDc5OTY4LCJ1aWQiOiJvNW5jMGZoTWxhZFc2RkJqcGhXS2M1RGNGMWQyIn0.uULbo2lgEmLZ9dZPulE8tjKOALJ-2wmTNo5yCb53Phes41_ltzTfQIH8KAANdr3MlUtkOERlxwSfkJdP7Nve1rft9Qk2qvmsxquiXyU3ZKx3RsJjPxGY12VY_3MAwFP_5baZAZxp3y8ZGxh3RjTWSb_2Bkr7rHPKED4KP2buqMWYfOHACdIvp0vox7TZ8-ViBJaQVeUjert4hwZq23HNyderYdVrUyCAU9yFpH6w16-HPEtOiI4x5QcDW4nmGUFTxn1aY8msgNxmrHTBwSQLjJjtXfXriG-HCbS-tTqDWsWKQu_n3WMTqVBSA-U3gZTkddWcDPJw0T401f_tFmYThA"
    //   );
  });

  if (isChromeExtension) {
    // eslint-disable-next-line no-undef
    chrome.runtime.onMessage.addListener(async (request) => {
      if (request?.msg === "login_token" && request?.token) {
        console.log(request?.token);
        let credential = GoogleAuthProvider.credential(null, request?.token);
        console.log(credential);
        const result = await signInWithCredential(auth, credential);

        console.log(result);
      }
    });
  }

  const handleOpenBrowser = () => {
    if (
      window?.electronDW &&
      window?.electronDW?.shell &&
      window?.electronDW?.shell?.openExternal
    ) {
      // eslint-disable-next-line no-unused-expressions
      window?.electronDW?.shell?.openExternal(
        `${process.env.REACT_APP_ELECTRON_AUTH_URL}/electron-auth`
      );
    } else {
      errorNotificationWithString("Couldn't open browser for authentication");
    }
  };

  const signInWithGoogle = async () => {
    try {
      if (isChromeExtension) {
        // eslint-disable-next-line no-undef
        chrome.runtime.sendMessage({ fromLogin: true });
      } else {
        await signInWithPopup(auth, googleAuthProvider);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithMicrosoft = async () => {
    try {
      if (isChromeExtension) {
        // eslint-disable-next-line no-undef
        chrome.runtime.sendMessage({ fromLogin: true });
      } else {
        await signInWithPopup(auth, microsoftProvider);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("the current user is", currentUser);
  if (currentUser) {
    const search = location?.search || "";
    if (search?.slice(0, 11) === "?from_page=") {
      const toPage = search?.slice(12) || "/";
      return <Redirect to={toPage} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  const loader = isElectronAuthenticating || isCreatingCustomToken;

  return (
    <AuthLayout
      isElectron={isElectron}
      // className={isElectron ? "" : "full-height"}
    >
      <AuthCard
        id="auth-card-id"
        isElectron={isElectron}
        // className={isElectron ? "" : "info-page-card"}
      >
        <div
          className={
            // isElectron ?
            "flex-display flex-direction-column justify-content-center align-items-center"
            // : "info-page-div"
          }
        >
          <Link href="https://deepwork.easy.xyz/">
            <img className="mt-24 block-display" src={logo} alt="logo" />
          </Link>

          <Title className="mt-16" level={4}>
            Please authenticate to continue
          </Title>

          {isElectron ? (
            <>
              <Button
                className="mt-32 mb-24 font-weight-300 width-300-px"
                onClick={handleOpenBrowser}
              >
                Open Browser
              </Button>
              <div>
                {loader && (
                  <Row align="middle">
                    <Text>Authentication application</Text>
                    <InlineSpinner className="ml-8" fontSize={24} />
                  </Row>
                )}
              </div>
            </>
          ) : (
            <>
              <Button
                className="mt-32 mb-24 font-weight-300 width-300-px"
                onClick={signInWithGoogle}
              >
                <Icon component={GoogleImage} />
                Continue with Google
              </Button>

              {!isChromeExtension && (
                <Button
                  className="mt-8 mb-24 font-weight-300 width-300-px"
                  onClick={signInWithMicrosoft}
                >
                  <img
                    className={`mr-8 mt--3 ${styles.msftLogo}`}
                    src={microsoftImage}
                    alt="msft"
                  />
                  Continue with Microsoft
                </Button>
              )}
            </>
          )}
          <Text className={styles.footerText} type="secondary">
            By clicking Continue with Google, I accept the easy.xyz{" "}
            <span className="text-decoration-underline">Terms Of Service</span>{" "}
            and have read the{" "}
            <span className="text-decoration-underline">Privacy Policy</span>
          </Text>
        </div>
      </AuthCard>
    </AuthLayout>
  );
};

export default Auth;
