import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Layout, Menu, Typography } from "antd";
import {
  SettingOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  HomeOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import Integrations from "./integrations/Integrations";

import { useSelectedIntegrationState } from "store";
import Categories from "./categories/Categories";
import EnableDisable from "./enable-disable/EnableDisable";
import TimeZone from "./time-zone/TimeZone";
import Billing from "./billing/Billing";
import HabitsCustomization from "./habits-customzation/HabitsCustomization";
import { getIsElectron } from "app/appUtils";

import GoogleBenefit1 from "assets/google_benefit_1.svg";
import GoogleBenefit2 from "assets/google_benefit_2.svg";
import SlackBenefit1 from "assets/slack_benefit_1.svg";
import SlackBenefit2 from "assets/slack_benefit_2.svg";
import SlackBenefit3 from "assets/slack_benefit_3.svg";
import JiraBenefit1 from "assets/jira_benefit_1.svg";
import JiraBenefit2 from "assets/jira_benefit_2.svg";
import { ReactComponent as OpenSlackLinkIcon } from "assets/slack_integration_todo_icon.svg";
// import { ReactComponent as OpenJIRALinkIcon } from "assets/jira_integration_todo_icon.svg";

import growthIcon from "assets/growth.png";

import styles from "./Settings.module.css";

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const Settings = ({ defaultActiveKey = "shutdown_ritual" }) => {
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState("categories");

  const [selectedIntegration, setSelectedIntegration] =
    useSelectedIntegrationState(null);

  const isElectron = getIsElectron();
  const ipcRendererWrapperToOpenIntegrations = isElectron
    ? window?.electronDW?.ipcRendererWrapperToOpenIntegrations
    : () => {};

  useEffect(() => {
    if (isElectron) {
      ipcRendererWrapperToOpenIntegrations((event, url) => {
        const replaceUrl = "dw-app://integrations/open-from-electron?";
        const urlSearchParams = new URLSearchParams(
          url?.link?.replace(replaceUrl, "")?.trim()
        );

        const redirectCode = urlSearchParams?.get("redirectCode");
        const redirectType = urlSearchParams?.get("redirectType");

        setSelectedKey(
          redirectType === "GoogleIntegration"
            ? "calendars"
            : redirectType === "JiraCloudIntegration"
            ? "jira"
            : "slack"
        );
        setSelectedIntegration({
          name: redirectType,
          code: redirectCode,
        });
      });
    } else {
      const type = selectedIntegration?.name || "";
      if (type) {
        setSelectedKey(
          type === "GoogleIntegration"
            ? "calendars"
            : type === "JiraCloudIntegration"
            ? "jira"
            : "slack"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = ({ key }) => {
    if (key === "back-home") {
      history.push("/");
    } else {
      setSelectedKey(key);
    }
  };

  const isWebPage = !isElectron && !isChromeExtension;

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        collapsible={false}
        collapsed={false}
        width={200}
        style={{ backgroundColor: "#e3e3e3" }}
      >
        <Title level={4} className="ml-24 mt-16">
          Settings
        </Title>
        <Menu
          className="settings-menu mt-16"
          mode="inline"
          selectedKeys={[selectedKey]}
          onSelect={handleSelect}
          style={{ backgroundColor: "#e3e3e3" }}
        >
          <Menu.Item key="categories" icon={<UnorderedListOutlined />}>
            Categories
          </Menu.Item>

          <Menu.Item key="timezone" icon={<ClockCircleOutlined />}>
            Timezone
          </Menu.Item>

          <Menu.Item key="enable-disable" icon={<SettingOutlined />}>
            Enable/Disable Features
          </Menu.Item>
          <Menu.Item key="billing" icon={<DollarOutlined />}>
            Billing
          </Menu.Item>
          <Menu.Item
            key="habits-customization"
            icon={
              <img
                className="cursor-pointer "
                height={20}
                src={growthIcon}
                alt="habits"
              />
            }
          >
            Habits
          </Menu.Item>

          <Menu.ItemGroup
            className="mt-16 mb-16"
            key="integrations"
            title={<Text>INTEGRATIONS</Text>}
          >
            <Menu.Item
              className={styles.integrationMenuItem}
              key="calendars"
              icon={<CalendarOutlined />}
            >
              Calendars
            </Menu.Item>
            {/* <Menu.Item
              className={styles.integrationMenuItem}
              key="jira"
              icon={<OpenJIRALinkIcon />}
            >
              JIRA
            </Menu.Item> */}
            <Menu.Item
              className={styles.integrationMenuItem}
              key="slack"
              icon={<OpenSlackLinkIcon />}
            >
              Slack
            </Menu.Item>
          </Menu.ItemGroup>

          {isWebPage && (
            <Menu.Item key="back-home" icon={<HomeOutlined />}>
              Home
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: "16px 16px 0" }}>
          {selectedKey === "calendars" && (
            <Integrations
              title={"Add Calendars"}
              description={
                "You can add multiple accounts for the same calendar"
              }
              allIntegrations={[
                {
                  name: "Google",
                  benefits: [
                    {
                      text: "See and join your meetings from the menubar",
                      src: GoogleBenefit1,
                      srcProps: { height: "92px", width: "229px" },
                    },
                    {
                      text: "Block your calendar fo your Focus sessions so that you don't get double booked.",
                      src: GoogleBenefit2,
                      srcProps: { height: "132px", width: "221px" },
                    },
                  ],
                },
              ]}
              connectedIntegrationTypes={["GoogleIntegration"]}
            />
          )}
          {selectedKey === "jira" && (
            <Integrations
              allIntegrations={[
                {
                  name: "JIRA",
                  benefits: [
                    {
                      text: "See JIRA tickets assigned to you in your Todos",
                      src: JiraBenefit1,
                      srcProps: { height: "81px", width: "215px" },
                    },
                    {
                      text: "Completing Todos automatically updates the JIRA tickets",
                      src: JiraBenefit2,
                      srcProps: { height: "227px", width: "212px" },
                    },
                  ],
                },
              ]}
              connectedIntegrationTypes={["JiraCloudIntegration"]}
            />
          )}
          {selectedKey === "slack" && (
            <Integrations
              allIntegrations={[
                {
                  name: "Slack",
                  benefits: [
                    {
                      text: "Automatically set up Do Not Disturb(DnD) status when Focusing",
                      src: SlackBenefit1,
                      srcProps: { height: "96px", width: "139px" },
                    },
                    {
                      text: "Make your team aware that you are in Focus mode when Focusing so that distractions are reduced.",
                      src: SlackBenefit2,
                      srcProps: { height: "109px", width: "224px" },
                    },
                    {
                      text: "See and manage your Saved messages in your Todos",
                      src: SlackBenefit3,
                      srcProps: { height: "81px", width: "215px" },
                    },
                  ],
                },
              ]}
              connectedIntegrationTypes={["SlackIntegration"]}
            />
          )}
          {selectedKey === "enable-disable" && <EnableDisable />}
          {selectedKey === "categories" && <Categories />}
          {selectedKey === "timezone" && <TimeZone />}
          {selectedKey === "billing" && <Billing />}
          {selectedKey === "habits-customization" && <HabitsCustomization />}
        </Content>
      </Layout>
    </Layout>
  );
};

Settings.propTypes = {
  visible: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
};

export default Settings;
