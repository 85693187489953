import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Router } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useQuery, useMutation, useQueryClient } from "react-query";
import firebaseApp from "firebaseApp";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { createBrowserHistory, createHashHistory } from "history";
import moment from "moment";

import {
  useCurrentUser,
  useIsFetchingUser,
  useLastSyncedTimeState,
  useAssistsState,
  useIsUserRegisteredState,
} from "store";
import { dateTimeFormat } from "app/appConstants";
import { getIsElectron, errorNotification } from "app/appUtils";
import { getAssistsApi } from "app/assists/assistsApi";
import logo from "assets/logo.svg";
import "./App.less";
import "./App.css";
import Routes from "./Routes";
import { syncIntegrationsApi } from "app/settings/integrations/integrationsApi";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

axios.defaults.baseURL = process.env.REACT_APP_REST_API_LOCATION
  ? process.env.REACT_APP_REST_API_LOCATION
  : isChromeExtension
  ? "https://app.deepwork.easy.xyz/"
  : "http://localhost:8000";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "just now",
    ss: "%d seconds ago",
    m: "a minute ago",
    mm: "%d mins ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a day ago",
    dd: "%d days ago",
    w: "a week ago",
    ww: "%d weeks ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

axios.interceptors.request.use(async (config) => {
  try {
    if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
      const auth = getAuth(firebaseApp);
      const tokenResult = await auth.currentUser?.getIdTokenResult(false);

      if (tokenResult) {
        config.headers["Authorization"] = `Bearer ${tokenResult?.token}`;
      }
    }
    return config;
  } catch (e) {
    console.error(e);
  }
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403 || error.response.status === 307) {
      window.location = "/access-denied";
    }
    if (error.response.status === 409) {
      window.location = "/feature-disabled";
    }
    return Promise.reject(error);
  }
);

const history =
  getIsElectron() || isChromeExtension
    ? createHashHistory()
    : createBrowserHistory();

const idleTimeout = 1000 * 60 * 3; // Call every 3 minutes
// const idleTimeout = 1000 * 10; // Call every 3 minutes

function App() {
  // const screens = useBreakpoint();
  const queryClient = useQueryClient();
  const auth = getAuth(firebaseApp);

  const [isUserRegistered] = useIsUserRegisteredState();
  const [isFetchingUser, setIsFetchingUser] = useIsFetchingUser();
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [, setLastSyncedTime] = useLastSyncedTimeState();
  const [isIdle, setIsIdle] = useState(false);
  const [, setAssists] = useAssistsState();

  useEffect(() => {
    const isElectron = getIsElectron();
    const handleSize = isElectron ? window?.electronDW?.handleSize : () => {};

    if (handleSize && typeof handleSize === "function") {
      handleSize();
    }
  }, []);

  const { mutate: syncIntegrations } = useMutation(syncIntegrationsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries("getTodos");
      queryClient.invalidateQueries("getDayView");
      setLastSyncedTime(moment()?.format(dateTimeFormat));
    },
    onError: (err) => {
      if (err?.response?.status >= 500) {
        errorNotification(err);
      }
    },
  });

  useQuery("getAssists", getAssistsApi, {
    enabled: isUserRegistered,
    onSuccess: (data) => {
      const unclearedAssists = data?.filter(
        (assist) => !Boolean(assist?.cleared)
      );
      setAssists(unclearedAssists);
    },
  });

  let idleIntervalId = useRef();
  useEffect(() => {
    if (isIdle) {
      idleIntervalId.current = setInterval(() => {
        const isOnline = navigator.onLine ? true : false;

        if (isOnline) {
          syncIntegrations({});
          queryClient.invalidateQueries("getAssists");
        }
      }, idleTimeout);
    }

    return () => {
      if (idleIntervalId.current) {
        clearInterval(idleIntervalId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, syncIntegrations]);

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: () => {},
    debounce: 500,
  });

  useEffect(() => {
    if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
      onAuthStateChanged(auth, async (user) => {
        try {
          setCurrentUser(user);
          window.globalCurrentUserEmail = user?.email;
          setIsFetchingUser(false);
          if (user?.email !== "culture-demo@easy.xyz" && user?.email) {
            // handleAddLinkedInConversionPixel();
            if (process.env.REACT_APP_ENVIRONMENT === "production") {
              // setCookie("t_he", user?.email, 365);
            }
          }
        } catch (error) {
          console.log(error);
          setIsFetchingUser(false);
        }
      });
    } else {
      setCurrentUser({
        displayName: "Mock user",
        email: "mock.email@easy.xyz",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUserEmail = currentUser?.email;
  useEffect(() => {
    if (currentUserEmail) {
      Sentry.setContext("character", {
        email: currentUserEmail,
      });
    }
    // eslint-disable-next-line
  }, [currentUserEmail]);

  if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
    onIdTokenChanged(auth, async (user) => {
      const newToken = (await user?.getIdToken(false)) || "";
      const currentToken = (await currentUser?.getIdToken(false)) || "";
      if (user && newToken !== currentToken) {
        setCurrentUser(user);
      }
    });
  }

  if (isFetchingUser) {
    return (
      <div className={"spinner-container"}>
        <img src={logo} alt="logo" className={"large-logo"} />
      </div>
    );
  }

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
