import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";

import { makeServer } from "./mock-apis/server";

window.process = {};

if (process.env.REACT_APP_MOCK_ENV === "mirage-start") {
  makeServer({ environment: "development" });
}

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://b3b7d5d944594c1cb9461d5259708f2a@o464798.ingest.sentry.io/5868052",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.4,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "this[i] is not a function",
    ],
  });
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  Sentry.init({
    dsn: "https://69cd7202da7b4bd48741deeb690c2adf@o464798.ingest.sentry.io/5868051",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.4,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "this[i] is not a function",
    ],
  });
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } },
});

ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
