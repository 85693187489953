import React from "react";
// import PropTypes from "prop-types";
import { Button } from "antd";

import { days } from "app/appConstants";

const WeekButtons = ({ disabled = false, value = [], onChange = () => {} }) => {
  const handleClick = (day) => {
    const index = value?.indexOf(day);
    if (index > -1) {
      value.splice(index, 1);
      onChange([...value]);
    } else {
      onChange([...value, day]);
    }
  };

  return (
    <div>
      {days?.map((day) => (
        <Button
          size="small"
          disabled={disabled}
          className={"mr-8"}
          type={value?.includes(day?.value) ? "primary" : "default"}
          // ghost={value?.includes(day?.value)}
          onClick={() => {
            handleClick(day?.value);
          }}
        >
          {day?.display}
        </Button>
      ))}
    </div>
  );
};
WeekButtons.propTypes = {};

export default WeekButtons;
