import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const Billing = (props) => {
  return (
    <div>
      <Title level={3}>Billing</Title>
    </div>
  );
};

Billing.propTypes = {};

export default Billing;
