import React, { useState, useEffect } from "react";
import firebaseApp, { googleAuthProvider } from "firebaseApp";
import { getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { Button, Layout, Card, Typography } from "antd";

import logo from "assets/logo.svg";
import { useCurrentUser } from "store";

const { Title, Text, Link } = Typography;

const ElectronAuth = (props) => {
  const auth = getAuth(firebaseApp);
  const [currentUser] = useCurrentUser();

  const [userToken, setUserToken] = useState(null);
  const [firebaseError, setFirebaseError] = useState(null);

  const setTokenFromCurrentUser = async () => {
    const tokenResult = await currentUser?.getIdTokenResult(false);
    setUserToken(tokenResult?.token);
  };

  useEffect(() => {
    if (currentUser) {
      setTokenFromCurrentUser();
    } else {
      getRedirectResult(auth)
        .then((result) => {
          if (result?.credential) {
            const credential = result?.credential || "";
            const accessToken = credential?.accessToken || "";
            setUserToken(accessToken);
            // firebaseApp.auth().signInWithCustomToken(accessToken);
          }
          // const user = result?.user || "";
          // The signed-in user info.
          if (!firebaseError && !currentUser) {
            signInWithRedirect(auth, googleAuthProvider);
          }
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // const email = error.email;
          // const credential = error.credential;
          setFirebaseError(
            error.code === "auth/operation-not-allowed" &&
              (error?.message ||
                "The identity provider configuration is not found.")
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userToken) {
    return (
      <Layout className="full-height">
        <Card className="info-page-card">
          <div className="info-page-div">
            <Link href="https://deepwork.easy.xyz/">
              <img className="block-display mb-16" src={logo} alt="logo" />
            </Link>

            <Title level={4} className="block-display mb-16">
              Successfully Logged in
            </Title>
            <Text className="block-display mb-16">
              Please click on the button below to open focus.easy app
            </Text>
            <Button
              type="primary"
              ghost={true}
              href={`dw-app://login/${userToken}`}
            >
              Open deepwork app
            </Button>
          </div>
        </Card>
      </Layout>
    );
  }

  return <div>Electron authentication</div>;
};

ElectronAuth.propTypes = {};

export default ElectronAuth;
