import React from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import firebaseApp from "firebaseApp";
import { getAuth, signOut } from "firebase/auth";
import { Dropdown, Typography, Divider, Menu as AntMenu } from "antd";

import { InlineSpinner } from "common/components";
import {
  useCurrentUser,
  useIsUserSigningOut,
  useLastSyncedTimeState,
  useUserIntegrationInfo,
} from "store";
import UserAvatar from "./UserAvatar";
import { syncIntegrationsApi } from "app/settings/integrations/integrationsApi";
import { quitApp, openSettingsWindow, dateTimeFormat } from "app/appConstants";
import { ReactComponent as FeedbackIcon } from "assets/feedback_icon.svg";
import {
  errorNotification,
  sendIPCUpdateEvent,
  getIsElectron,
} from "app/appUtils";

const { Text } = Typography;

const AvatarDropDown = ({ setClearingDoneTodos = () => {} }) => {
  const isElectron = getIsElectron();
  const isChromeExtension =
    process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
  const history = useHistory();
  const [currentUser] = useCurrentUser();
  const queryClient = useQueryClient();
  const [, setIsUserSigningOut] = useIsUserSigningOut();
  const [lastSyncedTime, setLastSyncedTime] = useLastSyncedTimeState();
  const [userIntegrationInfo] = useUserIntegrationInfo();

  const { mutate: syncIntegrations, isLoading: isSyncingIntegrations } =
    useMutation(syncIntegrationsApi, {
      onSuccess: () => {
        // successNotification("Successfully synced integration");
        queryClient.invalidateQueries();
        setLastSyncedTime(moment()?.format(dateTimeFormat));
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const handleSettingsModal = () => {
    if (isElectron) {
      sendIPCUpdateEvent(openSettingsWindow);
    } else if (isChromeExtension) {
      window.open("https://app.deepwork.easy.xyz/settings", "_blank");
    } else {
      history.push("/settings");
    }
  };

  const handleQuitApp = () => {
    sendIPCUpdateEvent(quitApp);
  };

  const handleRefreshData = () => {
    syncIntegrations({});
  };

  const handleArchive = () => {
    history.push("/archive");
  };

  const handleSignOut = async () => {
    setIsUserSigningOut(true);
    const auth = getAuth(firebaseApp);
    await signOut(auth).then(() => {
      setIsUserSigningOut(false);
      history.push("/auth");
    });
  };

  const handleExpandWidth = (type = "feedback") => {
    history.push("/feedback");
  };

  const getAppVersion = () => {
    return window?.electronDW?.getAppVersion();
  };

  return (
    <Dropdown
      overlay={
        <div
          className="mr-16 width-200-px"
          style={{ backgroundColor: "white", border: "1px solid #e3e3e3" }}
        >
          <AntMenu style={{ border: "0px" }}>
            {userIntegrationInfo?.count > 0 && (
              <AntMenu.Item
                key="refresh-data"
                className={`avatar-dropdown-menu-item ${
                  lastSyncedTime ? "sync-integration-item" : ""
                }`}
                onClick={({ domEvent }) => {
                  if (domEvent && domEvent.stopPropagation) {
                    domEvent.stopPropagation();
                  }
                  handleRefreshData();
                }}
              >
                <div>
                  <div className="flex-display align-items-center">
                    <Text style={{ height: "20px" }}>Refresh</Text>
                    {isSyncingIntegrations && (
                      <InlineSpinner
                        className="ml-8"
                        fontSize={16}
                        color="black !important"
                        style={{ color: "black !important" }}
                      />
                    )}
                  </div>
                  {lastSyncedTime && (
                    <Text type="secondary" className="font-size-12">
                      Last Refreshed :{" "}
                      {moment(lastSyncedTime, dateTimeFormat)?.fromNow()}
                    </Text>
                  )}
                </div>
              </AntMenu.Item>
            )}

            <Divider className="my-4" />

            <AntMenu.Item
              key="archive"
              className="avatar-dropdown-menu-item"
              onClick={handleArchive}
            >
              Done Todos
            </AntMenu.Item>

            <Divider className="my-4" />

            <AntMenu.Item key="settings" className="avatar-dropdown-menu-item">
              <div onClick={handleSettingsModal}>
                <div>Settings</div>
              </div>
            </AntMenu.Item>

            {isElectron && (
              <AntMenu.Item
                key="quit-app"
                className="avatar-dropdown-menu-item"
                onClick={handleQuitApp}
              >
                Quit App
              </AntMenu.Item>
            )}

            <AntMenu.Item
              key="feedback-requests"
              className="avatar-dropdown-menu-item"
              onClick={() => handleExpandWidth("feedback")}
            >
              <div className="flex-display">
                <FeedbackIcon className="cursor-pointer mr-8 align-items-center" />
                <Text className="line-height-1">Feedback & Requests</Text>
              </div>
            </AntMenu.Item>

            <AntMenu.Item
              key="sign-out"
              className="avatar-dropdown-menu-item"
              onClick={handleSignOut}
            >
              Sign Out
            </AntMenu.Item>

            {isElectron && (
              <Text className="ml-8" type="secondary">
                App Version : {getAppVersion()}
              </Text>
            )}
          </AntMenu>
        </div>
      }
      placement="bottomCenter"
      trigger={["click"]}
    >
      <UserAvatar
        className={"ml-8 mr-4"}
        size={32}
        height="32px"
        width="32px"
        user={{
          ...currentUser,
          user_profile_pic_url: currentUser?.photoURL,
          name: currentUser?.displayName,
        }}
        emptyText="No User"
      />
    </Dropdown>
  );
};

AvatarDropDown.propTypes = {};

export default AvatarDropDown;
