import * as Sentry from "@sentry/react";
import moment from "moment";
import { capitalize } from "humanize-plus";
import { notification } from "antd";

import {
  displayDateFormatString,
  displayShortDateFormatString,
  dateTimeFormat,
  dateTimeFormatZ,
  updateWindowSize,
  updateSidecarWindowSize,
} from "./appConstants";

export const getIsElectron = () => {
  const userAgent = navigator?.userAgent?.toLowerCase();
  return userAgent?.indexOf(" electron/") !== -1;
};

// Notifications

export const errorNotification = (error = {}) => {
  if (error?.response?.status >= 500) {
    notification["error"]({
      message: "Something unexpected happened!",
      description: null,
      duration: 5, // Setting duration to null will never close it
    });

    return;
  }
  if (error?.response?.status === 401) {
    notification["error"]({
      message: "Authorization failed, retrying",
      description: null,
      duration: 2, // Setting duration to null will never close it
    });
    return;
  }
  const errorMessage = error?.response?.data?.message;
  if (errorMessage) {
    notification["error"]({
      message: errorMessage,
      description: null,
      duration: null, // Setting duration to null will never close it
    });
    Sentry.captureMessage(
      `${window?.globalCurrentUserEmail} - BE error msg - ${error?.response?.status} ${errorMessage}`,
      {
        extra: error,
      }
    );
  } else {
    Sentry.captureMessage(
      `${window?.globalCurrentUserEmail} - status: ${error?.response?.status} - API: ${error?.config?.method} ${error?.config?.url} responded without message`,
      { extra: error }
    );
    notification["error"]({
      message: "Error, please try again later!",
      description: null,
      duration: 1 * 60, // Setting duration to null will never close it
    });
  }
};

export const errorNotificationWithString = (errorString = "") => {
  notification["error"]({
    message: "Error",
    description: errorString || "Error, please try again later!",
    duration: null, // Setting duration to null will never close it
  });
};

export const infoNotification = (message) => {
  notification["info"]({
    message: "Info",
    description: message || "Info text",
  });
};

export const warningNotification = (message) => {
  notification["warning"]({
    message: "Warning",
    description: message || "Warning text",
  });
};

export const successNotification = (message) => {
  notification["success"]({
    message: message || "Success!",
    description: null,
  });
};

// Date
export const getIsDateActive = (date) => {
  return moment(date, "YYYY-MM-DD").isSameOrAfter(moment().startOf("day"));
};

export const formattedDate = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  return moment(dateString, "YYYY-MM-DD").format(displayDateFormatString);
};

export const formattedTime = (dateTimeString = "", timeZone = "") => {
  if (!dateTimeString) {
    return "-";
  }

  return moment(dateTimeString, dateTimeFormatZ)
    ?.tz(timeZone)
    ?.format("hh:mm A");
};

export const formattedShortDate = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  return moment(dateString, "YYYY-MM-DD").format(displayShortDateFormatString);
};

export const formattedDateShortYear = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  return moment(dateString, "YYYY-MM-DD").format("MMM D");
};

export const formattedDateTime = (dateTimeString = "") => {
  if (!dateTimeString) {
    return "-";
  }
  const utcDateTime = moment
    .utc(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .toDate();
  return moment(utcDateTime).local().format("MMM Do, YYYY HH:mm");
};

export const formattedMonthYear = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  const utcDateTime = moment
    .utc(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .toDate();
  return moment(utcDateTime).local().format("MMMM, YYYY");
};

export const isCurrentDateToday = (currentDate) => {
  let currentCalendarDateObj = new Date(currentDate);
  let todayDateObj = new Date();
  return currentCalendarDateObj.toDateString() === todayDateObj.toDateString();
};

// Hours and Minutes
export const getHoursMinutesString = (metric) => {
  const rounded = Math.round(metric * 100) / 100;

  const hours = Math.floor(rounded);
  let min = rounded - hours;
  min = min * 60;
  min = Math.round(min);

  if (min !== 0) {
    if (hours !== 0) {
      return `${hours} ${"h"} ${min} ${"m"}`;
    }
    return `${min} ${"m"}`;
  }
  return `${hours} ${"h"}`;
};

export const getHoursMinutes = (metric) => {
  const rounded = Math.round(metric * 100) / 100;

  const hours = Math.floor(rounded);
  let min = rounded - hours;
  min = min * 60;
  min = Math.round(min);

  return hours + min / 100;
};

export const getHrsMinsString = (hours = 0, min = 0) => {
  if (min !== 0) {
    if (hours !== 0) {
      return `${hours}${"h"} ${min}${"m"}`;
    }
    return `${min}${"m"}`;
  }
  return `${hours}${"h"}`;
};

export const getHoursFromMins = (totalMinutes = 0) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return getHrsMinsString(hours, minutes);
};

export const getHoursFromMinutes = (mins) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  // if (mins >= 24 * 60 || mins < 0) {
  //   return [0, 0];
  // }
  const h = (mins / 60) | 0;
  const m = mins % 60 | 0;
  return [h, m];
};

export const getMinutesFromSeconds = (seconds) => {
  return Math.floor(seconds / 60);
};

export const getNumberOfHoursMinutesString = (mins = 0) => {
  const [hours, minutes] = getHoursFromMinutes(mins);

  const h = Math.round(hours);
  const m = Math.round(minutes);

  switch (true) {
    case h === 0 && m === 0:
      return "0h";
    case h === 0 && m > 0:
      if (m === 1) return `${m}m`;
      return `${m}m`;
    case h > 0 && m === 0:
      if (h === 1) return `${h}h`;
      return `${h}h`;
    case h > 0 && m > 0:
      if (h === 1) {
        if (m === 1) return `${h}h ${m}m`;
        else return `${h}h ${m}m`;
      } else {
        if (m === 1) return `${h}h ${m}m`;
        else return `${h}h ${m}m`;
      }
    // return `${h} Hours`;
    default:
      return "0 h";
  }
};

export const getTotalTimeSpentStr = (totalTimeSpent = 0) => {
  if (totalTimeSpent === 0) {
    return "loading...";
  }

  let totalTimeSpentStr = getNumberOfHoursMinutesString(
    getMinutesFromSeconds(totalTimeSpent) || 0
  );
  totalTimeSpentStr =
    totalTimeSpentStr === "0hrs" ? "0 mins" : totalTimeSpentStr;
  const secs = totalTimeSpent % 60;
  totalTimeSpentStr =
    totalTimeSpentStr === "0 mins"
      ? `${secs} secs`
      : `${totalTimeSpentStr} ${secs} secs`;

  return totalTimeSpentStr;
};

export const getMinMaxHours = (allHours = []) => {
  const hours = allHours?.map((val) => getHoursFromMinutes(val)[0]);
  return [Math.min(...hours), Math.max(...hours)];
};

export const getSpentTime = (fromTime) => {
  return moment()?.diff(moment(fromTime, dateTimeFormat), "seconds");
};

export const getTotalTimeSpentHMStr = (totalTimeSpent = 0) => {
  if (totalTimeSpent === 0) {
    return "loading...";
  }

  let totalTimeSpentStr = getNumberOfHoursMinutesString(
    getMinutesFromSeconds(totalTimeSpent) || 0
  );
  totalTimeSpentStr = totalTimeSpentStr === "0h" ? "0 m" : totalTimeSpentStr;
  const secs = totalTimeSpent % 60;
  totalTimeSpentStr =
    totalTimeSpentStr === "0 m" ? `${secs} s` : totalTimeSpentStr;

  return totalTimeSpentStr;
};

// User
export const getUserName = (user = {}) => {
  if (user?.name?.trim()) {
    return user?.name?.trim();
  } else if (user?.first_name?.trim() || user?.last_name?.trim()) {
    return `${user?.first_name} ${user?.last_name}`;
  } else if (user?.email?.trim()) {
    return user?.email;
  }
  return "";
};

export const getUserInitials = (name = "") => {
  if (name?.includes("@")) {
    const emailName = getNameFromEmail(name)?.slice(0, 2);
    return emailName?.toUpperCase();
  }
  return capitalize(name)
    ?.split(" ")
    .map((nameEl, i, arr) =>
      i === 0 || i + 1 === arr.length ? capitalize(nameEl)[0] : null
    )
    .join("");
};

export const getNameFromEmail = (name = "") => {
  const nameFromEmail = name.split("@")[0];
  return nameFromEmail || "";
};

export const getNameFromUserObj = (user) => {
  let name = getUserName(user);
  if (name?.includes("@")) {
    name = getNameFromEmail(name);
  }
  return name;
};

export const getAuthorNameFromComment = (comment) => {
  let authorName =
    comment?.name || comment?.user_first_name || comment?.author_id || "";
  if (authorName.includes("@")) {
    authorName = getNameFromEmail(authorName);
  }

  return authorName;
};

export const getCurrentUserName = (currentUser = {}) => {
  if (currentUser?.displayName) {
    return currentUser?.displayName;
  }
  return getNameFromEmail(currentUser?.email);
};

// Filter options
export const filterOptions = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const filterOptionsByLabel = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const getEllipseText = (text = "", characterLimit = 25) => {
  const isMoreThanCharLimit = text?.length > characterLimit;
  return isMoreThanCharLimit ? `${text?.slice(0, characterLimit)}...` : text;
};

export const getMax = (arr = []) => {
  if (arr?.length === 0) {
    return 0;
  }
  return Math.max(...arr) || 0;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// ipc Event
export const sendIPCUpdateEvent = (event = "", data = {}) => {
  const isElectron = getIsElectron();
  const ipcRendererSend = isElectron
    ? window?.electronDW?.ipcRendererSend
    : () => {};

  if (isElectron) {
    ipcRendererSend(event, data);
  }
};

// Window Height
export const getHeightOfDiv = (id = "main-menu-content") => {
  const contentDiv = document.getElementById(id);
  return contentDiv?.clientHeight || 200;
};

export const handleWindowResizeHeightAndWidth = (
  height,
  width,
  bounds = false
) => {
  const isElectron = getIsElectron();

  if (isElectron) {
    sendIPCUpdateEvent(updateWindowSize, { width, height, bounds });
  }
};

export const handleSidecarWindowResize = ({ width }) => {
  sendIPCUpdateEvent(updateSidecarWindowSize, { width });
};

export const getHasAccessibilityPermission = () => {
  const isElectron = getIsElectron();
  const getAuthStatus = window?.electronDW?.getAuthStatus;
  if (isElectron && getAuthStatus) {
    return getAuthStatus("accessibility") === "authorized";
  }
  return false;
};

export const getDoneTodoMsg = () => {
  const messages = [
    "👍 Nicely done, champ",
    "😊 That felt good, rite?",
    "🥊 What a knockout!!",
    "👍 Good job, Chief",
    "🙌 Smooth execution",
    "🦸 You deserve a cape",
    "🖥️ I’m proud of you",
    "✨ Look at those skills",
    "👌 Beautiful!!",
    "👍 Champion in the making",
    "👍 Super Duper!!",
    "🚀 Mission Accomplished",
    "❤️ Impressive",
    "👍 You make it look easy",
    "👍 Right On",
    "❤️ Neat",
    "👏 Awesome Sauce",
    "🔥 Unstoppable",
  ];

  return messages[Math.floor(Math.random() * messages.length)];
};

export const handleOpenLink = (integrationLink) => {
  const isElectron = getIsElectron();

  if (isElectron) {
    if (window?.electronDW?.shell?.openExternal) {
      window.electronDW.shell.openExternal(integrationLink);
    }
  } else {
    window.open(integrationLink, "_blank").focus();
  }
};

export const getIsGoalDone = (goal) => {
  const doneTodos = goal?.todos?.filter((todo) => Boolean(todo?.completed_at));

  return doneTodos?.length === goal?.todos?.length && goal?.todos?.length > 0;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSelectedTodoCategory = (todoCategories, selectedCategoryId) => {
  if (todoCategories?.length === 0) {
    return "Loading...";
  }

  const selectedCategory = todoCategories?.filter(
    (category) => category?.id === selectedCategoryId
  )[0];

  return selectedCategory;
};

export const getMainContainerStyling = (isAppExpanded) => {
  const isElectron = getIsElectron();
  const isChromeExtension =
    process.env.REACT_APP_IS_CHROME_EXTENSION === "true";
  const isWebPage = !isChromeExtension && !isElectron;

  return {
    width: isElectron && isAppExpanded ? "100%" : "360px",
    border: isWebPage ? "1px solid grey" : undefined,
    backgroundColor: isWebPage ? "white" : undefined,
    maxHeight: isWebPage ? "90vh" : undefined,
    overflowY: isWebPage ? "auto" : undefined,
  };
};

export const getDayPlanEventFromSlot = (slot) => {
  return {
    ...slot,
    start: moment(slot?.from_time_utc, dateTimeFormat)?.toDate(),
    end: moment(slot?.to_time_utc, dateTimeFormat)?.toDate(),
    resourceId: 1,
  };
};
