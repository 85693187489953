import React, { useState, useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";

import { ColumnChart, BoxLoader } from "common/components";
import { useAppDate } from "store";
import InsightsHeader from "./InsightsHeader";
import InsightsTimeBreakdown from "./InsightsTimeBreakdown";
import InsightsHorizontalChart from "./InsightsHorizontalChart";
import InsightsDetails from "./InsightsDetails";
import { getPeriodInsightsApi } from "app/insights/insightsApi";
import { dateFormat } from "app/appConstants";
import { errorNotification } from "app/appUtils";

const PeriodInsights = ({ setView = () => {} }) => {
  const [appDate] = useAppDate();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [xCategories, setXCategories] = useState([]);
  const [series, setSeries] = useState([
    { name: "Focus", data: [] },
    { name: "Meeetings", data: [] },
  ]);
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    setFromDate(
      moment(appDate, dateFormat)?.subtract(6, "days")?.format(dateFormat)
    );
    setToDate(moment(appDate, dateFormat)?.format(dateFormat));
  }, [appDate]);

  const {
    data: periodInsights = {},
    isLoading: isPeriodInsightsLoading,
    // isFetching: isFetchingInsights,
  } = useQuery(["getPeriodInsights", fromDate, toDate], getPeriodInsightsApi, {
    enabled: Boolean(fromDate && toDate),
    onSuccess: (data) => {
      const perDayTimes = data?.per_day_times || [];
      let tempDate = fromDate;
      const seriesData = [
        { name: "Focus", data: [] },
        { name: "Meetings", data: [] },
      ];

      const tempXCategories = [];

      while (
        moment(tempDate, dateFormat)?.isSameOrBefore(moment(toDate, dateFormat))
      ) {
        const timeValues = perDayTimes[tempDate] || [0, 0, false];
        seriesData[0].data.push(
          timeValues[0] > 60 ? Math.round(timeValues[0] / 60) : 0
        );
        seriesData[1].data.push(
          timeValues[1] > 60 ? Math.round(timeValues[1] / 60) : 0
        );
        tempXCategories.push(
          moment(tempDate, dateFormat)?.format("dddd").substring(0, 3)
        );
        tempDate = moment(tempDate, dateFormat)
          ?.add(1, "day")
          ?.format(dateFormat);
      }
      setSeries(seriesData);
      setXCategories(tempXCategories);

      const tempColorMap = {};
      data.category_info.forEach((category) => {
        tempColorMap[category?.name] = category?.color_formula;
      });
      setColorMap(tempColorMap);
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  return (
    <div>
      <InsightsHeader
        view={1}
        fromDate={fromDate}
        toDate={toDate}
        setView={setView}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />

      {isPeriodInsightsLoading ? (
        <BoxLoader />
      ) : (
        <div>
          <InsightsTimeBreakdown data={periodInsights} />

          <InsightsHorizontalChart
            insights={periodInsights}
            colorMap={colorMap}
          />

          <ColumnChart series={series} xCategories={xCategories} />

          <InsightsDetails insights={periodInsights} colorMap={colorMap} />
        </div>
      )}
    </div>
  );
};

PeriodInsights.propTypes = {};

export default PeriodInsights;
