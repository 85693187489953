import React, { useState } from "react";
import moment from "moment";
import { useMutation } from "react-query";
import { Row, Col, Button, Typography, Popover, Tooltip, Select } from "antd";
import {
  UserOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  AimOutlined,
} from "@ant-design/icons";

import { EllipseText } from "common/components";
import TodoTypeCheckbox from "app/home/todos/categories-list/todo/todo-input/todo-type-checkbox/TodoTypeCheckbox";
import { updateCalendarEventApi } from "app/home/dayPlanApis";
import { dateTimeFormatZ } from "app/appConstants";
import {
  successNotification,
  getIsElectron,
  getSelectedTodoCategory,
  errorNotification,
} from "app/appUtils";
import { ReactComponent as NewLearningsIcon } from "assets/new_learnings.svg";

import styles from "app/home/Home.module.css";

const { Text, Title } = Typography;
const { Option } = Select;

const MeetingDetails = ({ timeSlot = {} }) => {
  const descriptionLines = timeSlot?.description?.split("\n");

  return (
    <>
      <Row align="middle">
        <UserOutlined className="mt--3" />
        <Text className="ml-8">
          {timeSlot?.attendees?.length > 0
            ? `${timeSlot?.attendees?.length} guests`
            : "No guests"}
        </Text>
      </Row>
      <div className={styles.meetingPopoverEmailList}>
        {timeSlot?.attendees?.map((attendee) => (
          <div className="flex-display align-items-center">
            <>
              {attendee?.responseStatus === "accepted" && (
                <CheckCircleOutlined style={{ color: "#52c41a" }} />
              )}
              {attendee?.responseStatus === "declined" && (
                <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
              )}
              {attendee?.responseStatus === "needsAction" && (
                <QuestionCircleOutlined />
              )}
            </>
            <EllipseText
              className="ml-8"
              characterLimit={30}
              shouldConsiderRegEx={false}
              text={attendee?.email}
            />
          </div>
        ))}
      </div>
      {timeSlot?.description && (
        <div className="mt-16 flex-display align-items-center">
          <InfoCircleOutlined />
          <div>
            {descriptionLines?.map((description) => {
              return <Text className="ml-8 block-display">{description}</Text>;
            })}
          </div>
        </div>
      )}
    </>
  );
};

const Meeting = ({ categories = [], timeSlot = {} }) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    timeSlot?.record?.category_id
  );

  const [isPriority, setIsPriority] = useState(timeSlot?.record?.priority);
  const [newLearnings, setNewLearnings] = useState(
    timeSlot?.record?.new_learnings
  );

  const isElectron = getIsElectron();

  const {
    mutate: updateCalendarEvent,
    // isLoading: isCreatingDWNowTimeBlock
  } = useMutation(updateCalendarEventApi, {
    onSuccess: () => {},
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleClick = (e) => {
    e.stopPropagation();
    if (isElectron) {
      if (
        window?.electronDW &&
        window?.electronDW?.shell &&
        window?.electronDW?.shell?.openExternal
      ) {
        handleClose();
        // eslint-disable-next-line no-unused-expressions
        window?.electronDW?.shell?.openExternal(timeSlot?.record?.meetlink);
      }
    } else {
      window.open(timeSlot?.record?.meetlink, "_blank").focus();
    }
  };

  const handleDetailsVisible = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  const handleChangeSelectedCategory = (value) => {
    if (!value) {
      const unCategorized = categories?.filter(
        (category) => category?.name?.toLowerCase() === "uncategorized"
      );

      setSelectedCategoryId(unCategorized[0]?.id);
    } else {
      setSelectedCategoryId(value);
    }

    updateCalendarEvent({
      calendarEvent: {
        id: timeSlot?.record?.eventId,
        category_id: value,
      },
    });
  };

  const isLinkAvailable = timeSlot?.record?.meetlink || false;

  const isMeetingInThePast = moment
    .utc()
    ?.diff(moment(timeSlot?.record?.to_time_utc, dateTimeFormatZ));

  const handleCopyLink = () => {
    if (isLinkAvailable) {
      navigator.clipboard.writeText(timeSlot?.record?.meet_link);
      successNotification("Copied link");
    }
  };

  const isMeetingCurrent =
    moment
      .utc()
      ?.isAfter(moment(timeSlot?.record?.from_time_utc, dateTimeFormatZ)) &&
    moment
      .utc()
      ?.isBefore(moment(timeSlot?.record?.to_time_utc, dateTimeFormatZ));

  const handleClose = () => {
    setIsDetailsVisible(false);
  };

  const handlePriority = (e) => {
    setIsPriority(e?.target?.checked);

    updateCalendarEvent({
      calendarEvent: {
        id: timeSlot?.record?.eventId,
        priority: e?.target?.checked,
      },
    });
  };

  const handleNewLearnings = (e) => {
    setNewLearnings(e?.target?.checked);

    updateCalendarEvent({
      calendarEvent: {
        id: timeSlot?.record?.eventId,
        new_learnings: e?.target?.checked,
      },
    });
  };

  const selectedCategory = getSelectedTodoCategory(
    categories,
    selectedCategoryId
  );
  const isUnCategorized =
    selectedCategory?.name?.toLowerCase() === "uncategorized";

  const filteredCategories = categories
    ?.filter((category) => category?.name?.toLowerCase() !== "uncategorized")
    ?.sort((a, b) => a?.order - b?.order);

  return (
    <Popover
      align={{
        points: ["bl", "tl"],
        offset: [0, 10],
      }}
      overlayClassName={styles.meetingPopover}
      visible={isDetailsVisible}
      onVisibleChange={handleDetailsVisible}
      title={
        <div>
          <Row justify="space-between" align="middle">
            <div className="flex-display">
              <Title
                level={5}
                className={`font-size-12 block-display  ${
                  isMeetingInThePast > 0 && "text-secondary"
                }`}
              >{`${timeSlot.startTime} - ${timeSlot.endTime}`}</Title>
              <EllipseText
                className={`font-size-18 ml-8 ${
                  isMeetingInThePast > 0 && "text-secondary"
                }`}
                text={timeSlot?.title}
                characterLimit={isLinkAvailable ? 20 : 30}
              />
            </div>
            <div className="flex-display align-items-center">
              {isLinkAvailable && (
                <Tooltip title="Copy Meeting Link">
                  <CopyOutlined
                    className="cursor-pointer"
                    onClick={handleCopyLink}
                  />
                </Tooltip>
              )}
              {isLinkAvailable && (
                <Button
                  className="ml-8"
                  size="small"
                  type="primary"
                  style={{ height: "18px", fontSize: "12px" }}
                  onClick={handleClick}
                >
                  Join
                </Button>
              )}
              <CloseCircleOutlined
                className="cursor-pointer ml-4"
                onClick={handleClose}
              />
            </div>
          </Row>
          <Select
            allowClear={true}
            className="todo-categories-select width-150-px"
            size="small"
            value={isUnCategorized ? null : selectedCategory?.id}
            onChange={handleChangeSelectedCategory}
          >
            {filteredCategories?.map((todoCategory) => {
              return (
                <Option key={todoCategory?.id} value={todoCategory?.id}>
                  {todoCategory?.type === "TodoCategory" ? (
                    <span
                      className="mr-4"
                      style={{
                        height: "12px",
                        width: "12px",
                        backgroundColor: todoCategory?.color_formula,
                        display: "inline-block",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <AimOutlined
                      style={{
                        fontSize: "20px",
                        color: "#26A26B",
                      }}
                    />
                  )}

                  <Text>{todoCategory?.name}</Text>
                </Option>
              );
            })}
          </Select>
          <TodoTypeCheckbox
            isPriority={isPriority}
            newLearnings={newLearnings}
            handlePriority={handlePriority}
            handleNewLearnings={handleNewLearnings}
          />
        </div>
      }
      content={<MeetingDetails timeSlot={timeSlot?.record} />}
      trigger="click"
      getPopupContainer={() => document.getElementById("meeting-popover")}
    >
      <Row
        align="middle"
        className={`${styles.menuEventRow} ${
          isDetailsVisible ? styles.menuEventRowActive : ""
        }`}
      >
        <Col span={6}>
          <Text
            className={`font-size-12  ${
              isMeetingInThePast > 0 && "text-secondary"
            }`}
            strong={isMeetingCurrent}
          >{`${timeSlot.startTime} - ${timeSlot.endTime}`}</Text>
        </Col>
        <Col
          span={18}
          className="meeting-content flex-display justify-content-space-between align-items-center"
        >
          {isPriority && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "4px",
                height: "8px",
                width: "8px",
                borderRadius: "50%",
                backgroundColor: "#ff4d4f",
              }}
            />
          )}
          {newLearnings && (
            <NewLearningsIcon className={isPriority ? "ml-4" : ""} />
          )}
          <EllipseText
            strong={isMeetingCurrent}
            className={`font-size-13 flex-grow-2 cursor-pointer ml-4 ${
              isMeetingInThePast > 0 && "text-secondary"
            }`}
            text={timeSlot?.title}
            characterLimit={isLinkAvailable ? 30 : 35}
          />

          <span className="meeting-link">
            {isLinkAvailable && (
              <Button
                size="small"
                type="primary"
                style={{ height: "18px", fontSize: "12px" }}
                onClick={handleClick}
              >
                Join
              </Button>
            )}
          </span>
        </Col>
      </Row>
    </Popover>
  );
};

export default Meeting;
