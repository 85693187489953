import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { BoxLoader } from "common/components";
import { useIsAppExpandedState } from "store";
import InsightsHeader from "./components/InsightsHeader";
import InsightsTimeBreakdown from "./components/InsightsTimeBreakdown";
import InsightsDetails from "./components/InsightsDetails";
import InsightsHorizontalChart from "./components/InsightsHorizontalChart";
import PeriodInsights from "./components/PeriodInsights";
import TopNav from "app/top-nav/TopNav";
import { useAppDate } from "store";
import { getDayInsightsApi } from "app/insights/insightsApi";
import {
  errorNotification,
  getIsElectron,
  getMainContainerStyling,
} from "app/appUtils";

const isChromeExtension = process.env.REACT_APP_IS_CHROME_EXTENSION === "true";

const Insights = (props) => {
  const [appDate] = useAppDate();
  const [isAppExpanded] = useIsAppExpandedState();

  const [currentDate, setCurrentDate] = useState(appDate);
  const [view, setView] = useState(0);
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    setCurrentDate(appDate);
  }, [appDate]);

  const isElectron = getIsElectron();

  const {
    data: insights = {},
    isLoading: isInsightsLoading,
    // isFetching: isFetchingInsights,
  } = useQuery(["getDayInsights", currentDate], getDayInsightsApi, {
    onSuccess: (data) => {
      const tempColorMap = {};
      data.category_info.forEach((category) => {
        tempColorMap[category?.name] = category?.color_formula;
      });
      setColorMap(tempColorMap);
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const isWebPage = !isChromeExtension && !isElectron;

  const isDayView = view === 0; // 0 -> Day, 1 -> Week

  const mainContainerStyles = getMainContainerStyling(isAppExpanded);

  return (
    <div className={`flex-display ${isWebPage && "web-main-content-styles"} `}>
      <div
        className="ml-8 mr-8 mb-8 pl-16 pr-16"
        id="insights-content"
        style={mainContainerStyles}
      >
        <TopNav from="insights" />

        {isInsightsLoading ? (
          <BoxLoader />
        ) : (
          <div>
            {isDayView ? (
              <div>
                <InsightsHeader
                  view={0}
                  currentDate={currentDate}
                  setView={setView}
                  setCurrentDate={setCurrentDate}
                />

                <InsightsTimeBreakdown data={insights} />

                <InsightsHorizontalChart
                  insights={insights}
                  colorMap={colorMap}
                />

                <InsightsDetails insights={insights} colorMap={colorMap} />
              </div>
            ) : (
              <PeriodInsights setView={setView} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Insights.propTypes = {};

export default Insights;
